import _ from "lodash";
import { SocialProvider } from "../../../types";
import {
  FACEBOOK_WHITELIST,
  SNAPCHAT_WHITELIST,
  TIK_TOK_BLACKLIST,
  YOUTUBE_BLACKLIST,
} from "../components/ToolbarList/ToolbarList";
import { METRIC_FIELDS } from "../utils/variables";
import { hasMetricData } from "./hasMetricData";

export const getGeneratorGridElements = (props) => {
  const {
    data,
    filteredMetadata,
    hiddenFields,
    metricsData,
    savedTemplate,
    selectedTemplate,
    templateApplied,
    //
    setGridElements,
    setHiddenFields,
    setHiddenGridElements,
    setShouldRerenderLayout,
  } = props;

  const newGridElements = [];
  const newHiddenGridElements = [];

  // creator metadata
  if (filteredMetadata.length) {
    filteredMetadata.forEach((field) => {
      const label = `metadata-${field.name
        .replaceAll(" ", "-")
        ?.replaceAll(".", "")
        .toLowerCase()}-${field.uuid}`;

      const element = {
        ...field,
        label,
        gridBoxType: "metadata",
        visible: field.isVisible,
      };

      // applying saved template
      if (savedTemplate?.length && !templateApplied) {
        if (
          (selectedTemplate.value.autogenerateMetadata && field.value) ||
          savedTemplate.find((item) => item.i === element.label)
        ) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      } else if (!selectedTemplate.value.autogenerateMetadata) {
        if (
          selectedTemplate.value.layout.find((item) => item.i === element.label)
        ) {
          newGridElements.push(element);
        } else {
          newHiddenGridElements.push(element);
        }
      }
      // applying default template
      else if (field.isVisible && field.value && !_.isEqual(field.value, [])) {
        newGridElements.push(element);
      } else {
        newHiddenGridElements.push(element);
      }
    });
  }
  // metrics data
  if (metricsData.length) {
    for (let i = 0; i < metricsData.length; i++) {
      const metric = metricsData[i];

      const filteredMetricFields = METRIC_FIELDS.filter((item) => {
        switch (metric.label) {
          case SocialProvider.TikTok:
            return !TIK_TOK_BLACKLIST.includes(item.value);
          case SocialProvider.Youtube:
            return !YOUTUBE_BLACKLIST.includes(item.value);
          case SocialProvider.Snapchat:
            return SNAPCHAT_WHITELIST.includes(item.value);
          case SocialProvider.Facebook:
            return FACEBOOK_WHITELIST.includes(item.value);
          default:
            return item.value !== "avg-views";
        }
      });

      for (let j = 0; j < filteredMetricFields.length; j++) {
        const item = filteredMetricFields[j];

        const metricData = data.socialProfilesData[metric.label];
        const element = {
          label: `${metric.label}-${item.value}`,
          provider: metric.label,
          type: item.value,
          gridBoxType: metric.label,
          visible: true,
        };

        const hasData = hasMetricData(element, metricData);
        // applying saved template
        if (savedTemplate?.length && !templateApplied) {
          if (savedTemplate.find((item) => item.i === element.label)) {
            newGridElements.push(element);
          } else {
            newHiddenGridElements.push(element);
          }
        } else if (
          item.initialHidden ||
          hiddenFields.find((el) => el.value === item.value) ||
          !hasData
        ) {
          newHiddenGridElements.push(element);
          setHiddenFields(hiddenFields.filter((el) => el.value !== item.value));
        }

        // applying default template
        else {
          newGridElements.push(element);
        }
      }
    }
  }

  setShouldRerenderLayout(true);
  setGridElements(newGridElements);
  setHiddenGridElements(newHiddenGridElements);
};
