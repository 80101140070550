import React from "react";

import OuterLink from "src/app/components/OuterLink/OuterLink";
import Pictogram, {
  PictogramVariants,
} from "src/app/components/Pictogram/Pictogram";
import { ReactComponent as OpenNewCardIcon } from "src/images/share-blue.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as PlusAltIcon } from "src/images/plus-alt.svg";
import { Button } from "src/app/components/Button/Button";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import SocialProfileIconsWrapper from "src/app/components/IconsWithStatus/SocialProfileIcons/SocialProfileIconsWrapper";
import { PublicationStatus } from "src/types/publication";
import PublicationLink from "src/app/components/IconsWithStatus/PublicationIcons/PublicationLink";
import PublicationStatusIcon from "src/app/components/IconsWithStatus/PublicationIcons/PublicationStatusIcon";
import {
  getTooltipByPublicationStatus,
  publicationStatusesWithTooltip,
  publicationStatusesWithWarning,
} from "src/app/components/IconsWithStatus/PublicationIcons/utils";
import { TableTaskData } from "../types";

interface TaskInfoPublicationControlsProps {
  taskItem: TableTaskData;
  openUpdatePublicationLinkModal: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export default function TaskInfoPublicationControls({
  taskItem,
  openUpdatePublicationLinkModal,
}: TaskInfoPublicationControlsProps) {
  const renderContent = () => {
    if (taskItem.publicationCopy?.url && taskItem.publicationCopy?.platform) {
      return (
        <>
          <PublicationLink
            status={taskItem.publicationCopy.status}
            url={taskItem.publicationCopy.url}
          >
            {publicationStatusesWithTooltip.includes(
              taskItem.publicationCopy.status,
            ) ? (
              <TooltipPortal
                contentClassName="suggestion-tooltip-content"
                content={getTooltipByPublicationStatus(
                  taskItem.publicationCopy.status,
                )}
                autoSize
              >
                <Pictogram
                  identifier={`SocialProviders/${taskItem.publicationCopy.platform}`}
                  variant={PictogramVariants.Original}
                />
              </TooltipPortal>
            ) : (
              <Pictogram
                identifier={`SocialProviders/${taskItem.publicationCopy.platform}`}
                variant={PictogramVariants.Original}
              />
            )}
          </PublicationLink>

          {publicationStatusesWithWarning.includes(
            taskItem.publicationCopy.status,
          ) && <PublicationStatusIcon status={PublicationStatus.NotFound} />}

          {taskItem.publicationCopy.status === PublicationStatus.Fresh &&
            taskItem.publicationCopy.url && (
              <PublicationStatusIcon status={PublicationStatus.Fresh} />
            )}
        </>
      );
    }
    if (taskItem.publicationCopy?.url && !taskItem.publicationCopy?.platform) {
      return (
        <OuterLink
          href={taskItem.publicationCopy.url}
          className="ws-table__task-social-provider"
        >
          <OpenNewCardIcon />
        </OuterLink>
      );
    }
    return (
      <Button variant="transparent" onClick={openUpdatePublicationLinkModal}>
        <PlusAltIcon />
        <IDHFormattedMessage id="ws_add_link" defaultMessage="Add link" />
      </Button>
    );
  };

  return (
    <SocialProfileIconsWrapper>{renderContent()}</SocialProfileIconsWrapper>
  );
}
