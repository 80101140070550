import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { TaskType } from "src/types";
import { TableContext } from "./types";

export const ACTION_TYPES = [
  MetaFieldType.Creator,
  MetaFieldType.Content,
  MetaFieldType.PublicationRelated,
];

export const tableContextSpecificSettings: Record<
  TableContext,
  { metaFieldUuidAccessorKey: keyof Metadata; metaFieldNameAccessorKey: string }
> = {
  workspaceTasksList: {
    metaFieldUuidAccessorKey: "wsGlobalTaskMetaFieldUuid",
    metaFieldNameAccessorKey: "name",
  },
};

export const mapMetaFieldTypeToTaskType: Record<
  | MetaFieldType.Creator
  | MetaFieldType.Content
  | MetaFieldType.PublicationRelated,
  TaskType
> = {
  [MetaFieldType.Creator]: TaskType.Creator,
  [MetaFieldType.Content]: TaskType.Content,
  [MetaFieldType.PublicationRelated]: TaskType.Publication,
};
