import { API_URLS } from "src/utils/API_URLS";
import { EmvValues } from "src/app/modals/ManageFieldModal/types";
import { DictionaryElementsFilter } from "src/app/Task/Subtask/Subtask";
import {
  CREATE_NEW_PROJECT_GLOBAL_FIELD,
  CREATE_NEW_TASK_GLOBAL_FIELD,
  GET_SETTINGS_GLOBAL_FIELDS,
  SET_RELOAD_TASK_FIELDS,
  UPDATE_GLOBAL_PROJECT_FIELD,
  UPDATE_GLOBAL_PROJECT_FIELD_RANK,
  UPDATE_GLOBAL_TASK_FIELD,
  UPDATE_GLOBAL_TASK_FIELD_RANK,
  UPDATE_PROJECT_GLOBAL_SELECT_FIELD,
  UPDATE_TASK_GLOBAL_SELECT_FIELD,
  CLEAR_SETTINGS_GLOBAL_FIELDS,
} from "./settingsTypes";

export function getSettingsGlobalFields(workspaceUuid: string) {
  const url = API_URLS.getSettingsGlobalFieldsList.replace(
    ":workspaceUuid:",
    workspaceUuid,
  );
  return {
    type: GET_SETTINGS_GLOBAL_FIELDS,
    payload: {
      request: {
        url,
      },
    },
  };
}

export function clearSettingsGlobalFields() {
  return {
    type: CLEAR_SETTINGS_GLOBAL_FIELDS,
  };
}

interface CreateNewTaskGlobalFieldProps {
  wsWorkspaceUuid: string;
  metaFieldName: string;
  metaFieldUuid: string;
  metaFieldDescription: string;
  metaFieldType: string;
  metaFieldTaskType: string;
  metaFieldValueSource: string | null;
  metaFieldCurrency: string | null;
  arithmeticFieldOptions: any;
  wsDictionaryUuid: string | null;
  wsTeamUuids: string[] | null;
  showInCreatorDatabase: boolean;
  wsDictionarySubFieldUuid: string | null;
  relatedMetaFieldUuid?: string | null;
  relatedMetaFieldAggregationType?: string | null;
  relatedDictionaryMetaFieldUuid?: string | null;
  emvValues: EmvValues | null;
  wsDictionaryElementsFilter: Partial<DictionaryElementsFilter> | null;
}
export function createNewTaskGlobalField(data: CreateNewTaskGlobalFieldProps) {
  const url = API_URLS.createNewTaskMetaField;

  return {
    type: CREATE_NEW_TASK_GLOBAL_FIELD,
    payload: {
      request: {
        method: "post",
        url,
        data,
      },
    },
  };
}

interface CreateNewProjectGlobalFieldProps {
  wsWorkspaceUuid: string;
  metaFieldName: string;
  metaFieldUuid: string;
  metaFieldDescription: string;
  metaFieldType: string;
  metaFieldTaskType: string;
  metaFieldCurrency?: string | null;
  arithmeticFieldOptions: any;
  summaryFieldOptions: any;
  wsDictionaryUuid?: string;
  wsTeamUuids?: string[] | null;
  wsDictionarySubFieldUuid?: string | null;
  metaFieldIcon: { name: string; color: string } | null;
  metaFieldValueSource: string | null;
}

export function createNewProjectGlobalField(
  data: CreateNewProjectGlobalFieldProps,
) {
  const url = API_URLS.createNewProjectMetaField;

  return {
    type: CREATE_NEW_PROJECT_GLOBAL_FIELD,
    payload: {
      request: {
        method: "post",
        url,
        data,
      },
    },
  };
}

export function setReloadTaskFields(shouldReloadGlobalTaskFields: boolean) {
  return {
    type: SET_RELOAD_TASK_FIELDS,
    payload: { shouldReloadGlobalTaskFields },
  };
}

export function updateGlobalTaskField(data: {
  wsWorkspaceUuid: string;
  metaFieldName: string;
  metaFieldUuid: string;
  metaFieldDescription: string;
  metaFieldCurrency: string | null | undefined;
  arithmeticFieldOptions: any;
  showInCreatorDatabase: boolean;
  metaFieldValueSource: string | undefined;
  wsDictionarySubFieldUuid: string | null;
  wsTeamUuids: string[] | null;
  wsDictionaryUuid: string | null;
  emvValues: EmvValues | null;
}) {
  return {
    type: UPDATE_GLOBAL_TASK_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.updateTaskGlobalField,
        data,
      },
    },
  };
}

interface UpdateGlobalProjectFieldProps {
  wsWorkspaceUuid: string;
  metaFieldName: string;
  metaFieldUuid: string;
  metaFieldDescription: string;
  metaFieldCurrency: string | null | undefined;
  arithmeticFieldOptions: any;
  summaryFieldOptions: any;
  metaFieldIcon: { name: string; color: string } | null;
  wsDictionarySubFieldUuid: string | null;
  metaFieldValueSource: string | null;
  wsTeamUuids: string[] | null;
  wsDictionaryUuid: string | null;
}

export function updateGlobalProjectField(data: UpdateGlobalProjectFieldProps) {
  return {
    type: UPDATE_GLOBAL_PROJECT_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.updateProjectGlobalField,
        data,
      },
    },
  };
}

export function updateTaskGlobalSelectField(
  metaFieldUuid: string,
  wsWorkspaceUuid: string,
  singleSelectOptions: any,
) {
  return {
    type: UPDATE_TASK_GLOBAL_SELECT_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.updateTaskGlobalSelectField,
        data: {
          metaFieldUuid,
          wsWorkspaceUuid,
          singleSelectOptions,
        },
      },
      metaFieldUuid,
      singleSelectOptions,
    },
  };
}

export function updateProjectGlobalSelectField(
  metaFieldUuid: string,
  wsWorkspaceUuid: string,
  singleSelectOptions: any,
) {
  return {
    type: UPDATE_PROJECT_GLOBAL_SELECT_FIELD,
    payload: {
      request: {
        method: "post",
        url: API_URLS.updateProjectGlobalSelectField,
        data: {
          metaFieldUuid,
          wsWorkspaceUuid,
          singleSelectOptions,
        },
      },
      metaFieldUuid,
      singleSelectOptions,
    },
  };
}

export function updateGlobalTaskFieldRank(
  wsWorkspaceUuid: string,
  metaFieldUuid: string,
  metaFieldNewRank: string,
) {
  return {
    type: UPDATE_GLOBAL_TASK_FIELD_RANK,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateGlobalTaskMetaFieldRank,
        data: {
          wsWorkspaceUuid,
          metaFieldUuid,
          metaFieldNewRank,
        },
      },
      uuid: metaFieldUuid,
      rank: metaFieldNewRank,
    },
  };
}

export function updateGlobalProjectFieldRank(
  wsWorkspaceUuid: string,
  metaFieldUuid: string,
  metaFieldNewRank: string,
) {
  return {
    type: UPDATE_GLOBAL_PROJECT_FIELD_RANK,
    payload: {
      request: {
        method: "put",
        url: API_URLS.updateGlobalProjectMetaFieldRank,
        data: {
          wsWorkspaceUuid,
          metaFieldUuid,
          metaFieldNewRank,
        },
      },
      uuid: metaFieldUuid,
      rank: metaFieldNewRank,
    },
  };
}
