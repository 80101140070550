import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";

import { DeleteTaskConfirmationModal } from "src/app/modals/DeleteTaskConfirmationModal/DeleteTaskConfirmationModal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { openGlobalTaskDetails } from "src/app/components/GlobalTaskDetails/methods";
import { addExistingTasksToDatabase } from "src/redux/creator-database/creatorDatabaseActions";
import { IIdentity } from "src/app/Navbar/Navbar";
import { ExtensionTypeEnum, SocialProfileFlags, TaskType } from "src/types";
import { ReactComponent as CreatorAltIcon } from "src/images/avatar.svg";
import { ReactComponent as TickIcon } from "src/images/tick-star.svg";
import { ReactComponent as LinkIcon } from "src/images/link.svg";
import { ReactComponent as DotsIcon } from "../../../images/settings.svg";
import { ReactComponent as RenameIcon } from "../../../images/rename.svg";
import { ReactComponent as DuplicateIcon } from "../../../images/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../../images/trash-can.svg";
import { ReactComponent as OpenIcon } from "../../../images/cursor-loading.svg";
import { ReactComponent as ClientOrangeIcon } from "../../../images/client-orange.svg";
import { ReactComponent as ClientGreyIcon } from "../../../images/client-grey.svg";
import { ReactComponent as CreatorIcon } from "../../../images/creator.svg";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../components/Dropdown/Dropdown";
import { Button } from "../../components/Button/Button";
import "./ActionDropdown.scss";
import { tableDataType } from "../../components/Table/Table";
import RenameModal from "../../modals/RenameModal/RenameModal";
import { copyLink } from "../../../utils/methods";
import {
  duplicateTask,
  getTasksList,
  openShareMultipleColumnsModal,
  openStopSharingWithClientModal,
} from "../../../redux";
import {
  ProjectPermissionsEnum,
  WorkspacePermissionsEnum,
} from "../../../utils/PermissionsEnums";
import SendPaymentModal from "src/app/Task/SendPaymentModal/SendPaymentModal";
import { ReactComponent as MoneyIcon } from "src/images/money.svg";
import { AppConfig } from "../../../config/AppConfig";
import { DropdownMenuHoverItem } from "src/app/components/Dropdown/components/DropdownMenuHoverItem";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { ProjectBasicData } from "src/redux/project/types";

interface ActionDropdownInterface {
  activeWorkspaceUuid: string;
  projectBasicData: ProjectBasicData | null;
  globalTaskUuid: string | null;
  identity: IIdentity;
  taskId: string;
  taskName: string;
  taskShared: boolean;
  taskType: TaskType;
  titleFieldId: string;
  socialProfiles?: SocialProfile[];
}

export default function ActionDropdown({
  activeWorkspaceUuid,
  projectBasicData,
  globalTaskUuid,
  identity,
  taskId,
  taskName,
  taskShared,
  taskType,
  titleFieldId,
  socialProfiles,
}: ActionDropdownInterface) {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendPaymentModal, setShowSendPaymentModal] =
    useState<boolean>(false);

  const workspaceExtensionList = identity?.wsWorkspaceExtensions;

  const instagramAccountVerificationItem = useMemo(
    () =>
      workspaceExtensionList.find(
        (extension) => extension.type === ExtensionTypeEnum.ApiConnection,
      ),
    [workspaceExtensionList],
  );

  const instagramApiPaired = useMemo(() => {
    const ig = socialProfiles?.find(
      (item) => item.socialProvider === "instagram",
    );
    return ig?.flags.includes(SocialProfileFlags.ApiPaired);
  }, [socialProfiles]);

  const encodedData =
    instagramAccountVerificationItem?.type === ExtensionTypeEnum.ApiConnection
      ? instagramAccountVerificationItem.settings.encodedData
      : null;

  const showCopyVerificationButton = encodedData && !instagramApiPaired;

  const history = useHistory();
  const params = useParams<{ projectId: string }>();
  const { projectId } = params;

  const dispatch = useDispatch();

  const openTask = () => {
    history.push(
      `/workspace/${activeWorkspaceUuid}/projects/${projectId}/${taskId}`,
    );
  };

  const duplicateTaskHandler = async () => {
    await dispatch(
      duplicateTask(taskId, uuid(), `Duplicate of ${taskName}`, "n"),
    ); // ustawić dobry task rank
    dispatch(getTasksList(projectId, taskType));
  };

  const addCreatorsToDatabase = () => {
    dispatch(addExistingTasksToDatabase([taskId]));
    dispatch(getTasksList(projectId, taskType));
  };

  const hasAccessToXtrmPayments =
    identity?.permissions?.workspace?.includes(
      WorkspacePermissionsEnum.EXTENSION_XTRM_PAYMENT_TRANSFER,
    ) && identity?.wsExtensionXtrmPaymentsUuid;

  const copyVerificationUrl = () => {
    copyLink(
      `${AppConfig.getApiHost()}/fb-int/social-onboarding/workspace-onboard?data=${encodedData}`,
    );
  };

  const renderCreatorDatabaseButton = () => {
    if (taskType !== TaskType.Creator) return null;

    const hasAccessToCreatorDatabase =
      identity?.permissions?.workspace?.includes(
        WorkspacePermissionsEnum.CREATOR_DATABASE,
      );

    if (!hasAccessToCreatorDatabase || projectBasicData?.seeAsClientMode)
      return null;

    if (globalTaskUuid) {
      return (
        <DropdownMenuItem
          onClick={() => openGlobalTaskDetails(history, globalTaskUuid)}
        >
          <CreatorIcon />
          <IDHFormattedMessage
            id="ws_show_in_creator_database"
            defaultMessage="Show in Creator Database"
          />
        </DropdownMenuItem>
      );
    }

    return (
      <DropdownMenuItem onClick={addCreatorsToDatabase}>
        <CreatorIcon />
        <IDHFormattedMessage
          id="ws_add_to_creator_database"
          defaultMessage="Add to Creator Database"
        />
      </DropdownMenuItem>
    );
  };

  return (
    <>
      <Dropdown toRight className="dropdown--adjacent-to-button">
        {(isOpen, setIsOpen) => (
          <>
            <Button
              variant="light-grey"
              size="large"
              onClick={() => setIsOpen(true)}
              className="action-dropdown__button"
              data-task-name={taskName}
            >
              <DotsIcon />
            </Button>
            <DropdownMenu isOpen={isOpen}>
              <DropdownMenuItem onClick={() => openTask()}>
                <OpenIcon />
                <IDHFormattedMessage id="ws_open" defaultMessage="Open" />
              </DropdownMenuItem>

              {renderCreatorDatabaseButton()}

              {hasAccessToXtrmPayments && taskType === TaskType.Payment && (
                <DropdownMenuItem onClick={() => setShowSendPaymentModal(true)}>
                  <MoneyIcon className="action-dropdown__gray-icon" />
                  <IDHFormattedMessage
                    id="ws_send_payment"
                    defaultMessage="Send Payment"
                  />
                </DropdownMenuItem>
              )}

              <DropdownMenuItem onClick={() => setShowRenameModal(true)}>
                <RenameIcon />
                <IDHFormattedMessage id="ws_rename" defaultMessage="Rename" />
              </DropdownMenuItem>

              <DropdownMenuHoverItem
                content={
                  <>
                    <DropdownMenuItem
                      onClick={() =>
                        copyLink(
                          `${window.location.origin}/workspace/${activeWorkspaceUuid}/projects/${projectId}/${taskId}`,
                        )
                      }
                    >
                      <CreatorAltIcon />
                      <IDHFormattedMessage
                        id="ws_creator_profile"
                        defaultMessage="Creator Profile"
                      />
                    </DropdownMenuItem>

                    {showCopyVerificationButton && (
                      <DropdownMenuItem onClick={copyVerificationUrl}>
                        <TickIcon />
                        <IDHFormattedMessage
                          id="ws_instagram_account_integration"
                          defaultMessage="Instagram Account Verification"
                        />
                      </DropdownMenuItem>
                    )}
                  </>
                }
                menuPosition="left"
              >
                <LinkIcon />
                <IDHFormattedMessage
                  id="ws_copy_link"
                  defaultMessage="Copy link"
                />
              </DropdownMenuHoverItem>

              {projectBasicData?.permissions?.project.includes(
                ProjectPermissionsEnum.TASK_MANAGEMENT,
              ) && (
                <DropdownMenuItem onClick={() => duplicateTaskHandler()}>
                  <DuplicateIcon />
                  <IDHFormattedMessage
                    id="ws_duplicate"
                    defaultMessage="Duplicate"
                  />
                </DropdownMenuItem>
              )}

              {projectBasicData?.permissions?.project.includes(
                ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES,
              ) &&
                projectBasicData?.clientMode &&
                (taskShared ? (
                  <DropdownMenuItem
                    onClick={() =>
                      dispatch(openStopSharingWithClientModal(taskId))
                    }
                  >
                    <ClientGreyIcon />
                    <IDHFormattedMessage
                      id="ws_stop_sharing"
                      defaultMessage="Stop sharing"
                    />
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem
                    onClick={() =>
                      dispatch(openShareMultipleColumnsModal(taskId))
                    }
                  >
                    <ClientOrangeIcon />
                    <IDHFormattedMessage
                      id="ws_share_with_client"
                      defaultMessage="Share with Client"
                    />
                  </DropdownMenuItem>
                ))}

              {projectBasicData?.permissions?.project.includes(
                "task_management",
              ) && (
                <DropdownMenuItem
                  onClick={() => setShowDeleteModal(true)}
                  className="dropdown__menu-item--danger"
                >
                  <DeleteIcon />
                  <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
                </DropdownMenuItem>
              )}
            </DropdownMenu>
          </>
        )}
      </Dropdown>

      {showRenameModal && (
        <RenameModal
          onClose={() => setShowRenameModal(false)}
          objectId={taskId}
          taskName={taskName}
          titleFieldId={titleFieldId}
          projectId={projectId}
          dataType={tableDataType.Task}
        />
      )}

      {showDeleteModal && (
        <DeleteTaskConfirmationModal
          onClose={() => setShowDeleteModal(false)}
          wsTaskUuid={taskId}
          wsProjectUuid={projectId}
          taskName={taskName}
        />
      )}

      {showSendPaymentModal && (
        <SendPaymentModal
          onClose={() => setShowSendPaymentModal(false)}
          wsExtensionXtrmPaymentsUuid={identity.wsExtensionXtrmPaymentsUuid}
          activeWorkspaceUuid={activeWorkspaceUuid}
          taskId={taskId}
        />
      )}
    </>
  );
}
