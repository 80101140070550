import React, { ReactElement, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import PublicationPlaceholder from "src/images/publication-placeholder-grey-sm.svg";
import classNames from "classnames";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import RatingSelector from "src/app/components/RatingSelector/RatingSelector";
import { RootState } from "src/redux/reducers";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { ITask } from "src/redux/task/taskReducer";
import { TaskType } from "src/types";
import ChooseActionDropdown from "../../../../dropdowns/ChooseActionDropdown/ChooseActionDropdown";
import { ACTION_TYPES, mapMetaFieldTypeToTaskType } from "../../utils";

import { ReactComponent as ShareAltIcon } from "../../../../../images/share-alt.svg";
import { ReactComponent as PlusIcon } from "../../../../../images/plus-transparent.svg";
import { ReactComponent as CloseIcon } from "../../../../../images/cross.svg";

import AvatarPlaceholder from "../../../../../images/avatar-purple.svg";
import ContentPlaceholder from "../../../../../images/content-placeholder.svg";

import { DateObject } from "../../../Project";
import { getImageSource } from "../../../../../utils/methods";
import { TableContext } from "../../types";

interface TaskTableFieldInterface {
  fieldType: MetaFieldType;
  fieldValue: any;
  onValueChange: (
    newValue: string | DateObject | null | number | boolean,
  ) => void;
  clearFieldValue: () => void;
  renderBasicTypes: () => ReactElement;
  shouldTriggerEditWarning?: boolean | undefined;
  tableContext?: TableContext;
  projectId: string;
}

export default function TaskTableField({
  fieldType,
  fieldValue,
  onValueChange,
  clearFieldValue,
  renderBasicTypes,
  shouldTriggerEditWarning,
  tableContext,
  projectId,
}: TaskTableFieldInterface) {
  const [action, setAction] = useState<any>({});

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const projectTasksAutocomplete = useSelector(
    (state: RootState) => state.taskReducer.projectTasksAutocomplete,
  );

  const history = useHistory();

  let tasksAutocomplete: ITask[] = [];
  if (
    (fieldType === MetaFieldType.Creator ||
      fieldType === MetaFieldType.Content ||
      fieldType === MetaFieldType.PublicationRelated) &&
    projectId
  ) {
    tasksAutocomplete = (projectTasksAutocomplete[projectId] ?? []).filter(
      (item) => item.taskType === mapMetaFieldTypeToTaskType[fieldType],
    );
  }

  useEffect(() => {
    if (
      ACTION_TYPES.includes(fieldType) &&
      projectTasksAutocomplete[projectId]
    ) {
      const actionCopy = projectTasksAutocomplete[projectId].find(
        (item) => item.taskId === fieldValue,
      );

      setAction(actionCopy);
    }
  }, [fieldValue, projectTasksAutocomplete]);

  useEffect(() => {
    if (action && fieldValue !== action?.taskId) {
      onValueChange(action.taskId);
    }
  }, [action]);

  const clearAction = () => {
    setAction(null);
    clearFieldValue();
  };

  const handleTaskOpen = (targetTaskId: string) => {
    if (tableContext === "workspaceTasksList") {
      const currentLocation = history.location.pathname;
      const foundTaskType = Object.values(TaskType).find(
        (task) => currentLocation.includes(`/${task}s`), // payments, creators, publications, etc.
      );

      history.push(
        `/workspace/${activeWorkspaceUuid}/${foundTaskType}s/${projectId}/${targetTaskId}`,
      );
    } else {
      history.push(
        `/workspace/${activeWorkspaceUuid}/projects/${projectId}/${targetTaskId}`,
      );
    }
  };

  const renderTaskField = () => {
    switch (fieldType) {
      case MetaFieldType.Creator:
      case MetaFieldType.Content:
      case MetaFieldType.PublicationRelated: {
        return action?.taskId ? (
          <span className="action-field-wrapper">
            <ChooseActionDropdown
              data={tasksAutocomplete}
              chooseAction={setAction}
              placeholder={action.title}
              fieldType={fieldType}
              toRight
            >
              <span
                className={classNames("action-field", {
                  "action-field--circle": fieldType === MetaFieldType.Creator,
                })}
              >
                {fieldType === MetaFieldType.Creator ? (
                  <img
                    src={getImageSource(
                      action?.cover,
                      "tiny",
                      AvatarPlaceholder,
                    )}
                    alt="avatar"
                  />
                ) : fieldType === MetaFieldType.PublicationRelated ? (
                  <img
                    src={getImageSource(
                      action?.cover,
                      "tiny",
                      PublicationPlaceholder,
                    )}
                    alt={fieldType}
                  />
                ) : (
                  <img
                    src={getImageSource(
                      action?.cover,
                      "tiny",
                      ContentPlaceholder,
                    )}
                    alt={fieldType}
                  />
                )}
                <TooltipedEllipsis maxWidth={160} content={action.title} />
              </span>
            </ChooseActionDropdown>
            <ShareAltIcon
              className="share-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleTaskOpen(action.taskId);
              }}
            />
            {!shouldTriggerEditWarning && (
              <div onClick={clearAction} className="tasks-table__field-close">
                <CloseIcon />
              </div>
            )}
          </span>
        ) : (
          <ChooseActionDropdown
            data={tasksAutocomplete}
            chooseAction={setAction}
            fieldType={fieldType}
            toRight
          >
            <div className="tasks-table__empty-field">
              <PlusIcon />
            </div>
          </ChooseActionDropdown>
        );
      }
      case MetaFieldType.Rating:
        return (
          <RatingSelector
            onValueChange={onValueChange}
            value={parseInt(fieldValue || "0", 10)}
          />
        );
      default:
        return renderBasicTypes();
    }
  };

  return renderTaskField();
}
