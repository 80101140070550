import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import axios from "axios";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import PageHeader from "src/app/components/PageHeader/PageHeader";
import { SingleProjectSelectedTasksBar } from "src/app/project/SingleProject/components/SingleProjectSelectedTasksBar";
import { RootState } from "src/redux/reducers";
import {
  clearWorkspaceTasks,
  getProjectTasksAutocomplete,
  getWorkspaceTasks,
  setShowTask,
} from "src/redux";
import { SortDirection, TaskType } from "src/types";
import Task from "src/app/Task/Task";
import { Button } from "src/app/components/Button/Button";
import WorkspaceTasksTableWrapper from "src/app/project/SingleProject/TasksTable/WorkspaceTasksTableWrapper";
import WebsocketListener from "src/WebsocketListener";
import { ReactComponent as FilterIcon } from "src/images/filter.svg";
import { Pane } from "src/app/components/Pane/Pane";
import { TaskSortingDirection } from "src/redux/taskFilters/types";
import { ReactComponent as DownloadIcon } from "src/images/download.svg";
import { API_URLS } from "src/utils/API_URLS";
import {
  getDictionaryAutoCompletesForColumns,
  getSelectDataSetForColumns,
  showErrorToast,
} from "src/utils/methods";
import WorkspaceTasksSortDropdown from "./WorkspaceTasksSortDropdown";
import WorkspaceTasksFilters from "./WorkspaceTasksFilters";

import "./WorkspaceTasksTable.scss";

const mapEnums = {
  [TaskSortingDirection.Ascending]: SortDirection.Ascending,
  [TaskSortingDirection.Descending]: SortDirection.Descending,
};

export default function PaymentsSummary() {
  const [showFilters, setShowFilters] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const { showTask } = useSelector((state: RootState) => state.projectReducer);

  const { filters } = useSelector(
    (state: RootState) => state.workspaceTaskFiltersReducer,
  );

  const { workspaceTasks, workspaceTasksColumns } = useSelector(
    (state: RootState) => state.taskReducer,
  );

  const { activeSortingColumn, sortingDirection } = useSelector(
    (state: RootState) => state.taskFiltersReducer,
  );

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const dispatch = useDispatch();
  const params = useParams<{ taskId: string }>();

  const getXlsxReport = async () => {
    setIsDownloadingReport(true);
    const url = API_URLS.getXlsxReportFileForPayments.replace(
      ":wsWorkspaceUuid:",
      activeWorkspaceUuid,
    );
    try {
      const response = await axios.get(url, {
        params: {
          filters,
          sortBy: activeSortingColumn,
          sort: mapEnums[sortingDirection].toLowerCase(),
        },
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch =
        contentDisposition &&
        contentDisposition.match(/filename\s*=\s*"(.+)"/i);
      const filename = filenameMatch ? filenameMatch[1] : "report.xlsx";

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error(error);
      showErrorToast();
    } finally {
      setIsDownloadingReport(false);
    }
  };

  const columnsSortedByRank = useMemo(() => {
    return [...workspaceTasksColumns].sort((a, b) => {
      return a.rank?.localeCompare(b.rank);
    });
  }, [workspaceTasksColumns]);

  useEffect(() => {
    if (params.taskId) {
      dispatch(setShowTask(true));
    } else {
      dispatch(setShowTask(false));
    }
  }, [params.taskId]);

  useEffect(() => {
    dispatch(
      getWorkspaceTasks({
        wsWorkspaceUuid: activeWorkspaceUuid,
        filters,
        sortBy: activeSortingColumn,
        sort: mapEnums[sortingDirection],
        taskType: TaskType.Payment,
      }),
    );
  }, [activeWorkspaceUuid, filters, activeSortingColumn, sortingDirection]);

  const colsString = JSON.stringify(
    workspaceTasksColumns.map((column) => ({
      type: column.type,
      options: column.data,
    })),
  );

  useEffect(() => {
    const cols = JSON.parse(colsString);
    getDictionaryAutoCompletesForColumns(dispatch, cols);
    getSelectDataSetForColumns(dispatch, cols);
  }, [colsString]);

  const projectUuids = useMemo(() => {
    const uuids: string[] = [];
    [...workspaceTasks]
      .sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
      )
      .forEach((task) => {
        if (!uuids.includes(task.wsProjectUuid)) {
          uuids.push(task.wsProjectUuid);
        }
      });
    return uuids;
  }, [workspaceTasks]);

  const projectUuidsStringified = JSON.stringify(projectUuids);

  useEffect(() => {
    const getTasksAutocompleteForAllProjects = () => {
      const projectUuidsParsed: string[] = JSON.parse(projectUuidsStringified);

      if (projectUuidsParsed.length) {
        projectUuidsParsed.forEach((projectUuid) => {
          dispatch(getProjectTasksAutocomplete(projectUuid));
        });
      }
    };

    getTasksAutocompleteForAllProjects();
  }, [projectUuidsStringified]);

  useEffect(() => {
    return () => {
      dispatch(clearWorkspaceTasks());
    };
  }, []);

  return (
    <AnimatedDiv className="workspace-tasks-table scroll-fixed">
      <div className="workspace-tasks-table__topbar">
        <PageHeader
          title={
            <IDHFormattedMessage id="ws_payments" defaultMessage="Payments" />
          }
          description={
            <IDHFormattedMessage
              id="ws_payments_description"
              defaultMessage="Here you can create and manage payments."
            />
          }
        />
        <div className="workspace-tasks-table__topbar-right">
          <Button
            variant="white"
            size="small"
            textWithIcon
            onClick={() => setShowFilters((prev) => !prev)}
            data-qa="filter-button"
          >
            <FilterIcon />
            <span>
              <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
              {Object.keys(filters).length > 0 &&
                `: ${Object.keys(filters).length}`}
            </span>
          </Button>

          <WorkspaceTasksSortDropdown
            columns={workspaceTasksColumns}
            sortBy={activeSortingColumn}
          />

          <Button
            variant="white"
            size="small"
            textWithIcon
            onClick={getXlsxReport}
            disabled={isDownloadingReport}
          >
            <DownloadIcon />
            <IDHFormattedMessage
              id="ws_download_xlsx"
              defaultMessage="Download XLSX"
            />
          </Button>
        </div>
      </div>

      <WorkspaceTasksTableWrapper
        taskType={TaskType.Payment}
        tasks={workspaceTasks}
        columns={columnsSortedByRank}
      />

      <Pane
        showPane={showFilters}
        hidePane={() => setShowFilters(false)}
        width={309}
      >
        <WorkspaceTasksFilters
          setFiltersVisible={setShowFilters}
          columns={columnsSortedByRank}
        />
      </Pane>
      {showTask && <Task onCloseHandler={() => dispatch(setShowTask(false))} />}
      <SingleProjectSelectedTasksBar tableContext="workspaceTasksList" />
      <WebsocketListener />
    </AnimatedDiv>
  );
}
