import React, { useEffect, useState } from "react";
import "./ToolbarList.scss";

import classNames from "classnames";

import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SUPPORTED_SHOWCASE_SOCIAL_MEDIA } from "src/app/CreatorShowcase/CreatorShowcase";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { translateMessage } from "src/app/methods/translateMessage";
import { ReactComponent as ChevronDown } from "src/images/chevron-down.svg";
import {
  camelCaseToSeparatedWords,
  capitalizeFirstLetter,
} from "src/utils/methods";
import _ from "lodash";
import {
  setNewLayout,
  setReportGridElements,
  setReportHiddenGridElements,
  setReportLayout,
} from "../../../../redux";
import { SocialProvider } from "../../../../types";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { addElementToLayout } from "../../functions/addElementToLayout";
import { METRIC_FIELDS } from "../../utils/variables";
import { ToolbarListCreatorMetaFields } from "../ToolbarListCreatorMetafields/ToolbarListCreatorMetaFields";
import { ToolbarListItem } from "../ToolbarListItem/ToolbarListItem";
import { ToolbarListPublicationMetaFields } from "../ToolbarListPublicationMetaFields/ToolbarListPublicationMetaFields";
import { ToolbarListTableSummary } from "../ToolbarListTableSummary/ToolbarListTableSummary";
import { ToolbarListTableSummaryByProvider } from "../ToolbarListTableSummaryByProvider/ToolbarListTableSummaryByProvider";
import { ToolbarListPublicationSummaryByProvider } from "../ToolbarListPublicationSummaryByProvider/ToolbarListPublicationSummaryByProvider";

export const TIK_TOK_BLACKLIST = [
  "avg-views",
  "credibility",
  "location-by-city",
];
export const YOUTUBE_BLACKLIST = [
  "avg-views",
  "credibility",
  "location-by-city",
  "keywords",
  "creators-brand-affinity",
  "audience-interests",
  "audience-brand-affinity",
  "creator-interests",
];
export const SNAPCHAT_WHITELIST = [
  "avg-views",
  "bio",
  "followers",
  "recently-published",
  "audience-age",
  "female-per-age",
  "male-per-age",
  "geographical-reach",
];

export const FACEBOOK_WHITELIST = ["bio", "followers"];

function ToolbarList(props) {
  const {
    provider,
    format,
    open,
    toolbarListProps,
    disableExpanding,
    isReport,
    intl,
    dynamicProvider,
    noBottomBorder,
    hideCheckbox,
  } = props;

  const { gridElements, hiddenGridElements } = toolbarListProps;

  const [showList, setShowList] = useState(open);
  const [sectionVisible, setSectionVisible] = useState(false);
  const [listElements, setListElements] = useState([]);

  const {
    reportReducer: { layout, publicationsSummary },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const renderHeader = () => {
    if (dynamicProvider) return provider;

    const defaultKey = "toolbar_publication_type_not_defined";
    if (provider.includes("table-summary-")) {
      const socialProvider = provider.replace("table-summary-", "");
      return translateMessage({
        intl,
        id: `ws_${socialProvider || defaultKey}`,
        defaultMessage: `${camelCaseToSeparatedWords(socialProvider || "notDefined")}`,
      });
    }

    if (provider.includes("publication-summary-")) {
      const socialProvider = provider.replace("publication-summary-", "");
      return translateMessage({
        intl,
        id: `ws_${socialProvider || defaultKey}`,
        defaultMessage: `${camelCaseToSeparatedWords(socialProvider || "notDefined")}`,
      });
    }

    switch (provider) {
      case "metadata":
        if (isReport) {
          return translateMessage({
            intl,
            id: "ws_campaign_details",
            defaultMessage: "Campaign details",
          });
        }
        return translateMessage({
          intl,
          id: "ws_all",
          defaultMessage: "All",
        });

      case SocialProvider.Instagram:
        return "Instagram";

      case SocialProvider.Youtube:
        return "Youtube";

      case SocialProvider.TikTok:
        return "TikTok";

      case SocialProvider.Snapchat:
        return "Snapchat";

      case SocialProvider.Twitter:
        return "X";
      case SocialProvider.Facebook:
        return "Facebook";

      case "creator-insights":
        return translateMessage({
          intl,
          id: "ws_creator_insights",
          defaultMessage: "Creator Insights",
        });

      case "audience-data":
        return translateMessage({
          intl,
          id: "ws_campaign_audience_data",
          defaultMessage: "Campaign Audience Data",
        });

      case "publication":
        return translateMessage({
          intl,
          id: "ws_all_publications",
          defaultMessage: "All Publications",
        });

      case "table-summary":
        return translateMessage({
          intl,
          id: "ws_summary",
          defaultMessage: "Summary",
        });

      case "post-data":
        return translateMessage({
          intl,
          id: "ws_executive_summary",
          defaultMessage: "Executive Summary",
        });

      case "creator":
        return translateMessage({
          intl,
          id: "ws_creators_in_the_campaign",
          defaultMessage: "Creators in the Campaign",
        });

      case "comments-analysis":
        return translateMessage({
          intl,
          id: "ws_comments_analysis",
          defaultMessage: "Comments analysis",
        });
    }
  };

  const getSectionId = () => {
    if (provider === "table-summary") {
      return `#header-table-summary`;
    }

    if (provider.includes("table-summary")) {
      return `#subheader-${provider}`;
    }

    switch (provider) {
      case "metadata":
        return "#subheader-metadata";

      case "creator-insights":
        return "#subheader-creator-insights";

      case "audience-data":
        return "#subheader-audience-data";

      case "publication":
        return "#header-publication";

      case "post-data":
        return "#subheader-post-data";

      case "creator":
        return "#subheader-creator";

      case "comments-analysis":
        return "#header-comments-analysis";
    }
  };

  const getMetricGridElements = () => {
    const newGridElements =
      METRIC_FIELDS.map((item) => {
        const element = gridElements.find(
          (el) => el.type === item.value && el.provider === provider,
        );
        if (element) {
          return {
            ...element,
            value: element.label,
            label: item.label,
          };
        }
        return undefined;
      }) || [];

    const newHiddenElements =
      METRIC_FIELDS.map((item) => {
        const element = hiddenGridElements.find(
          (el) => el.type === item.value && el.provider === provider,
        );
        if (element) {
          return {
            ...element,
            value: element.label,
            label: item.label,
          };
        }
        return undefined;
      }) || [];

    return _.compact([...newGridElements, ...newHiddenElements]);
  };

  const getGridElementsByType = (type, label) => {
    const newGridElements =
      gridElements
        ?.filter((item) => {
          if (dynamicProvider || provider.includes("table-summary")) {
            return item.section === type;
          }
          return item.gridBoxType === type;
        })
        ?.map((item) => {
          return {
            label: item[label],
            value: item.label,
          };
        }) || [];

    const newHiddenElements =
      hiddenGridElements
        ?.filter((item) => item.gridBoxType === type)
        ?.map((item) => {
          return {
            label: item[label],
            value: item.label,
          };
        }) || [];

    return [...newGridElements, ...newHiddenElements];
  };

  const getFilteredFields = () => {
    if (dynamicProvider) return getGridElementsByType(dynamicProvider, "title");

    if (provider.includes("table-summary")) {
      return getGridElementsByType(provider, "label");
    }

    if (provider.includes("publication-summary")) {
      return publicationsSummary[provider.replace("publication-summary-", "")]
        .summary;
    }

    switch (provider) {
      case "metadata":
        return getGridElementsByType("metadata", "name");

      case SocialProvider.Instagram:
      case SocialProvider.Twitter:
        return getMetricGridElements().filter(
          (item) => item.value !== "avg-views",
        );

      case SocialProvider.TikTok:
        return getMetricGridElements().filter(
          (item) => !TIK_TOK_BLACKLIST.includes(item.value),
        );

      case SocialProvider.Youtube:
        return getMetricGridElements().filter(
          (item) => !YOUTUBE_BLACKLIST.includes(item.value),
        );
      case SocialProvider.Snapchat:
        return getMetricGridElements().filter((item) =>
          SNAPCHAT_WHITELIST.includes(item.type),
        );
      case SocialProvider.Facebook:
        return getMetricGridElements().filter((item) =>
          FACEBOOK_WHITELIST.includes(item.value),
        );
      case "creator-insights":
        return getGridElementsByType("creator-insights", "name");

      case "audience-data":
        return getGridElementsByType("audience-data", "name");

      case "post-data":
        return getGridElementsByType("post-data", "name");

      case "creator":
        return getGridElementsByType("creator", "title");

      case "publication":
        return getGridElementsByType("publication", "title");

      case "comments-analysis":
        const sentiments = getGridElementsByType("sentiment-analysis", "name");
        const comments = getGridElementsByType("comments", "name");
        return [...sentiments, ...comments];

      default:
        return [];
    }
  };

  const toggleSectionVisibility = () => {
    if (sectionVisible) {
      hideSection();
    } else {
      showSection();
    }
  };

  const showSection = async () => {
    const newHiddenGridElements = hiddenGridElements.filter((item) => {
      if (dynamicProvider) {
        return (
          item.section !== dynamicProvider &&
          item.label !== `header-${dynamicProvider}` &&
          item.label !== `subheader-${dynamicProvider}`
        );
      }

      if (provider.includes("table-summary")) {
        return (
          item.section !== provider &&
          item.label !== `header-${provider}` &&
          item.label !== `subheader-${provider}`
        );
      }

      return (
        item.gridBoxType !== provider &&
        item.label !== `header-${provider}` &&
        item.label !== `subheader-${provider}`
      );
    });

    const gridElementsToAdd = hiddenGridElements.filter((item) => {
      if (dynamicProvider) {
        return (
          item.section === dynamicProvider ||
          item.label === `header-${dynamicProvider}` ||
          item.label === `subheader-${dynamicProvider}`
        );
      }

      if (provider.includes("table-summary")) {
        return (
          item.section === provider ||
          item.label === `header-${provider}` ||
          item.label === `subheader-${provider}`
        );
      }

      return (
        item.gridBoxType === provider ||
        item.label === `header-${provider}` ||
        item.label === `subheader-${provider}`
      );
    });

    dispatch(setReportGridElements([...gridElements, ...gridElementsToAdd]));
    dispatch(setReportHiddenGridElements(newHiddenGridElements));

    const newLayout = [...layout];

    const setLayout = isReport ? setReportLayout : setNewLayout;

    const publicationsVisible = newLayout.find((item) =>
      item.i.includes("publication"),
    );

    if (dynamicProvider && publicationsVisible) {
      const newDynamicLayout = await addDynamicSectionToLayout({
        gridElementsToAdd,
        newLayout,
        setLayout,
      });
      await dispatch(setLayout(newDynamicLayout));
    } else {
      await addSectionToLayout({ gridElementsToAdd, newLayout, setLayout });
      await dispatch(setLayout(newLayout));
    }

    if (dynamicProvider) {
      const dynamicHeader = document.getElementById(
        `subheader-${dynamicProvider}`,
      );
      if (dynamicHeader) dynamicHeader.scrollIntoView();
      return;
    }

    if (provider === "creator") {
      const creatorHeader = document.getElementById("subheader-creator");
      creatorHeader.scrollIntoView();
      return;
    }

    if (provider === "publication-") {
      const publicationHeader = document.getElementById("header-publication");
      publicationHeader.scrollIntoView();
      return;
    }

    const generator = document.querySelector(".template-generator");
    const scrollDiff = isReport ? -3500 : 0;
    generator.scroll({
      top: generator.clientHeight + generator.scrollHeight + scrollDiff,
      left: 0,
      behavior: "smooth",
    });
  };

  const addSectionToLayout = (props) => {
    const { gridElementsToAdd, newLayout, setLayout } = props;

    for (let i = 0; i < gridElementsToAdd.length; i++) {
      addElementToLayout(
        gridElementsToAdd[i],
        newLayout,
        dispatch,
        format,
        setLayout,
        hiddenGridElements,
        isReport,
        true,
      );
    }
  };

  const addDynamicSectionToLayout = (props) => {
    const { gridElementsToAdd, newLayout, setLayout } = props;

    const filteredLayout = newLayout.filter(
      (item) =>
        item.i.slice(0, 11) === "publication" ||
        item.i.slice(0, 15) === "top-publication" ||
        item.i.slice(0, 25) === "subheader-top-publication",
    );
    const { rows } = format;

    const lastElementEndY =
      Math.max(...filteredLayout.map((item) => item.y)) + 7;
    const borderRow = lastElementEndY + (rows - (lastElementEndY % rows));

    const mappedLayout = newLayout.map((item) => {
      if (item.i.includes("separator")) return item;

      if (item.y >= borderRow) {
        return {
          ...item,
          y: item.y + rows,
        };
      }

      return item;
    });

    for (let i = 0; i < gridElementsToAdd.length; i++) {
      addElementToLayout(
        gridElementsToAdd[i],
        mappedLayout,
        dispatch,
        format,
        setLayout,
        hiddenGridElements,
        isReport,
        true,
      );
    }

    return mappedLayout;
  };

  const hideSection = () => {
    const newGridElements = gridElements.filter((item) => {
      if (dynamicProvider) {
        return (
          item.section !== dynamicProvider &&
          item.label !== `header-${dynamicProvider}` &&
          item.label !== `subheader-${dynamicProvider}`
        );
      }

      if (provider.includes("table-summary")) {
        return (
          item.section !== provider &&
          item.label !== `header-${provider}` &&
          item.label !== `subheader-${provider}`
        );
      }

      return (
        item.gridBoxType !== provider &&
        item.label !== `header-${provider}` &&
        item.label !== `subheader-${provider}`
      );
    });

    const hiddenGridElementsToAdd = gridElements.filter((item) => {
      if (dynamicProvider) {
        return (
          item.section === dynamicProvider ||
          item.label === `header-${dynamicProvider}` ||
          item.label === `subheader-${dynamicProvider}`
        );
      }

      if (provider.includes("table-summary")) {
        return (
          item.section === provider ||
          item.label === `header-${provider}` ||
          item.label === `subheader-${provider}`
        );
      }

      return (
        item.gridBoxType === provider ||
        item.label === `header-${provider}` ||
        item.label === `subheader-${provider}`
      );
    });

    dispatch(setReportGridElements(newGridElements));
    dispatch(
      setReportHiddenGridElements([
        ...hiddenGridElements,
        ...hiddenGridElementsToAdd,
      ]),
    );
  };

  useEffect(() => {
    setListElements(getFilteredFields());
  }, [gridElements, hiddenGridElements]);

  useEffect(() => {
    if (!listElements?.length) return;
    if (
      listElements.every((item) =>
        gridElements?.find((el) => {
          let label = item.value;
          if (
            SUPPORTED_SHOWCASE_SOCIAL_MEDIA.includes(provider) &&
            !label.includes(provider)
          ) {
            label = `${provider}-${label}`;
          }
          return label === el.label;
        }),
      )
    ) {
      setSectionVisible(true);
    } else {
      setSectionVisible(false);
    }
  }, [listElements, gridElements]);

  const renderListContent = () => {
    if (provider === "creator") {
      return <ToolbarListCreatorMetaFields />;
    }

    if (provider === "publication") {
      return (
        <ToolbarListPublicationMetaFields
          format={format}
          toolbarListProps={toolbarListProps}
        />
      );
    }

    if (provider.includes("publication-summary-")) {
      return (
        <ToolbarListPublicationSummaryByProvider
          provider={provider.replace("publication-summary-", "")}
        />
      );
    }

    if (provider === "table-summary") {
      return (
        <ToolbarListTableSummary
          format={format}
          toolbarListProps={toolbarListProps}
        />
      );
    }

    if (provider.includes("table-summary-")) {
      return (
        <ToolbarListTableSummaryByProvider
          provider={provider.replace("table-summary-", "")}
        />
      );
    }

    return listElements
      ?.sort((a, b) => a.value.localeCompare(b.value))
      ?.map((item) => {
        return (
          <ToolbarListItem
            key={item.value}
            item={item}
            provider={provider}
            format={format}
            toolbarListProps={toolbarListProps}
            isReport={isReport}
          />
        );
      });
  };

  const hideTooltip =
    listElements?.length ||
    dynamicProvider ||
    provider.includes("table-summary");

  return (
    <div
      className={classNames("toolbar-list", {
        "toolbar-list--closed": !showList,
        "toolbar-list--dynamic-provider": dynamicProvider,
        "toolbar-list--no-bottom-border": noBottomBorder,
      })}
    >
      <div className="toolbar-list__content">
        <div className="toolbar-list__item">
          <div className="toolbar-list__item-wrapper">
            {!hideCheckbox ? (
              <MaterialTooltip
                id={`cannot-add-${provider}-tooltip`}
                center
                content={
                  <IDHFormattedMessage
                    id={`ws_report_${provider}_cannot_be_added`}
                    defaultMessage={`Section ${renderHeader()} cannot be added.`}
                  />
                }
                contentHidden={hideTooltip}
                clickable={false}
                className="coupon-tooltip"
                contentClassName=""
              >
                <CustomCheckbox
                  id={`${provider}-section`}
                  checked={sectionVisible}
                  onChange={toggleSectionVisibility}
                  blue={!dynamicProvider}
                  disabled={!hideTooltip}
                />
              </MaterialTooltip>
            ) : (
              <div className="toolbar-list__checkbox-placeholder" />
            )}

            {isReport ? (
              <a href={getSectionId()}>{renderHeader()}</a>
            ) : (
              renderHeader()
            )}
          </div>
          {!disableExpanding && listElements.length ? (
            <span
              className={classNames("toolbar-list__item-button", {
                "toolbar-list__item-button--clicked": showList,
              })}
              onClick={() => setShowList((v) => !v)}
            >
              <ChevronDown />
            </span>
          ) : null}
        </div>

        {showList && !disableExpanding && (
          <div className="toolbar-list__list" key={listElements.length}>
            {renderListContent()}
          </div>
        )}
      </div>
    </div>
  );
}

export default injectIntl(ToolbarList);
