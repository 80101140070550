import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uuidv7 } from "uuidv7";

import {
  PaneTopbar,
  PaneTopbarLeft,
  PaneTopbarToolbar,
} from "src/app/components/Pane/PaneTopbar/PaneTopbar";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as FilterIcon } from "src/images/filter.svg";
import { Column } from "src/redux/dictionary/types";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import SingleFilter from "src/app/project/ProjectsList/partials/ProjectsTable/tableComponents/ProjectsTableFilters/components/SingleFilter/SingleFilter";
import { RootState } from "src/redux/reducers";
import {
  clearWorkspaceTaskFilters,
  setWorkspaceTaskFilters,
} from "src/redux/workspaceTaskFilters/workspaceTaskFiltersActions";

interface WorkspaceTasksFiltersProps {
  setFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>;
  columns: Column[];
}

function WorkspaceTasksFilters({
  setFiltersVisible,
  columns,
}: WorkspaceTasksFiltersProps) {
  const [renderKey, setRenderKey] = useState(uuidv7());

  const { filters } = useSelector(
    (state: RootState) => state.workspaceTaskFiltersReducer,
  );

  const dispatch = useDispatch();

  const clearFilters = () => {
    dispatch(clearWorkspaceTaskFilters());
    setRenderKey(uuidv7());
  };

  const sendFilter = (uuid: string, value: any) => {
    dispatch(setWorkspaceTaskFilters({ uuid, value }));
  };

  return (
    <>
      <div className="workspace-tasks-table-filters" key={renderKey}>
        <PaneTopbar>
          <PaneTopbarLeft>
            <FilterIcon className="filters-header__icon" />
            <span className="filters-header__title">
              <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
            </span>
          </PaneTopbarLeft>
          <PaneTopbarToolbar hidePane={() => setFiltersVisible(false)} />
        </PaneTopbar>
        <div className="pane__container filters">
          {columns.length === 0 && (
            <div className="filter-box-empty">
              <IDHFormattedMessage
                id="ws_no_data_to_display"
                defaultMessage="No data to display"
              />
            </div>
          )}
          <div className="workspace-tasks-table-filters__fields-wrap">
            <SingleFilter
              col={{
                name: "Payment name",
                type: MetaFieldType.Text,
                uuid: "taskNameOrUuid",
              }}
              sendFilter={sendFilter}
              defaultValue={filters?.taskNameOrUuid?.value}
              key={`${renderKey}-taskNameOrUuid`}
              filters={filters}
            />
            {columns.map((c) => (
              <SingleFilter
                col={c}
                sendFilter={sendFilter}
                defaultValue={filters[c.uuid]?.value}
                key={`${renderKey}-${c.uuid}`}
                filters={filters}
              />
            ))}
          </div>
        </div>
      </div>
      <Button
        variant="light"
        size="medium"
        className="workspace-tasks-table-filters-button"
        onClick={clearFilters}
        data-qa="clear-filters-button"
      >
        <IDHFormattedMessage
          id="ws_clear_filters"
          defaultMessage="Clear filters"
        />
      </Button>
    </>
  );
}

export default WorkspaceTasksFilters;
