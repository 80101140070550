import { RouterChildContext } from "react-router";

export const openDictionaryDetails = (
  history: RouterChildContext["router"]["history"],
  dictionaryElementUuid: string,
  dictionaryUuid?: string,
) => {
  const url = new URL(window.location.href);
  url.searchParams.set("displayDictionaryPreview", dictionaryElementUuid);
  if (dictionaryUuid) {
    url.searchParams.set("additionalDictionaryUuid", dictionaryUuid);
  }
  history.push(url.pathname + url.search);
};

export const closeDictionaryDetails = (
  history: RouterChildContext["router"]["history"],
) => {
  const url = new URL(window.location.href);
  url.searchParams.delete("displayDictionaryPreview");
  url.searchParams.delete("additionalDictionaryUuid");
  history.push(url.pathname + url.search);
};
