import React, { memo, useMemo } from "react";
import { taskAvatarShape } from "src/app/components/OverviewFieldsTable/OverviewFieldsTable";
import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { SortableHandle } from "react-sortable-hoc";
import { ReactComponent as DragIcon } from "src/images/grid-grey.svg";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import ActionDropdown from "../../../dropdowns/ActionDropdown/ActionDropdown";
import TasksTableField from "../../TableField/TasksTableField";
import TaskInfo from "./TaskInfo";
import { tableDataType } from "../../../components/Table/Table";
import { SkeletonText } from "../../../components/Skeleton/Skeleton";
import { TableContext } from "../../TableField/types";
import { tableContextSpecificSettings } from "../../TableField/utils";
import { TableTaskData } from "./types";

const COLUMN_ID_BLACKLIST = [
  "select",
  "drag-handle",
  "creator",
  "content",
  "publication",
  "action",
  "selection",
  " action",
  "payment",
  "rightPadding",
  "leftPadding",
];

const COLUMN_ID_ALLOW_LIST = [
  "creator",
  "content",
  "publication",
  "action",
  "payment",
];

interface CellInterface {
  cell: any;
  rowData: TableTaskData;
  rowId: string;
  rowIndex: number;
  tableContext: TableContext | undefined;
  tasksLoading: boolean;
}

const Cell = memo(
  ({
    cell,
    rowData,
    rowId,
    rowIndex,
    tableContext,
    tasksLoading,
  }: CellInterface) => {
    const activeWorkspaceUuid = useSelector(
      (state: RootState) => state.mainReducer.activeWorkspaceUuid,
    );

    const couponsList = useSelector(
      (state: RootState) => state.couponReducer.couponsList,
    );

    const dictionaryAutocompleteLoading = useSelector(
      (state: RootState) =>
        state.dictionaryReducer.dictionaryAutocompleteLoading,
    );

    const identity = useSelector(
      (state: RootState) => state.mainReducer.identity,
    );

    const membersList = useSelector(
      (state: RootState) => state.projectReducer.activeMembersList,
    );

    const projectBasicData = useSelector(
      (state: RootState) => state.projectReducer.projectBasicData,
    );

    const selectedTasks = useSelector(
      (state: RootState) => state.taskReducer.selectedTasks,
    );

    const showShareMultipleColumnsModal = useSelector(
      (state: RootState) => state.taskReducer.showShareMultipleColumnsModal,
    );

    const showStopSharingWithClientModal = useSelector(
      (state: RootState) => state.taskReducer.showStopSharingWithClientModal,
    );

    const metaFieldNameAccessor = tableContext
      ? tableContextSpecificSettings[tableContext].metaFieldNameAccessorKey
      : "metaFieldName";

    const metaFieldKeyAccessor = tableContext
      ? tableContextSpecificSettings[tableContext].metaFieldNameAccessorKey
      : "metaFieldKey";

    const metaFieldUuidAccessorKey = tableContext
      ? tableContextSpecificSettings[tableContext].metaFieldUuidAccessorKey
      : "uuid";

    const metaField: Metadata | undefined = rowData.metadata.find(
      (el: Metadata) => el[metaFieldUuidAccessorKey] === cell.column.id,
    );

    const MetaField = useMemo(() => {
      const fieldData = metaField?.overrideValue || metaField;

      const fieldValue = fieldData?.value?.date
        ? fieldData?.value?.date
        : fieldData?.value; // refactor

      if (tasksLoading) {
        return <SkeletonText width={60} height={15} />;
      }

      if (fieldData) {
        return (
          <TasksTableField
            activeWorkspaceUuid={activeWorkspaceUuid}
            columnKey={
              cell.column.columnDef.columnFromApi[metaFieldKeyAccessor]
            }
            columnName={
              cell.column.columnDef.columnFromApi[metaFieldNameAccessor]
            }
            couponsList={couponsList}
            dataType={tableDataType.Task}
            fieldData={fieldData}
            fieldValue={fieldValue === "" ? null : fieldValue}
            membersList={membersList}
            objectId={rowId}
            rowId={rowId}
            selectedTasks={selectedTasks}
            title={rowData.title}
            projectId={rowData.wsProjectUuid}
            overrideValue={Boolean(metaField?.overrideValue)}
            blockManualEdit={Boolean(metaField?.blockManualEdit)}
            tableContext={tableContext}
          />
        );
      }

      return null;
    }, [
      metaField,
      cell.column.columnFromApi,
      tasksLoading,
      dictionaryAutocompleteLoading,
      selectedTasks,
      couponsList,
      tableContext,
    ]);

    const DragHandle = SortableHandle(() => {
      if (tasksLoading) return <> </>;

      if (tableContext === "workspaceTasksList") {
        return <div />;
      }

      return <DragIcon style={{ cursor: "pointer" }} />;
    });

    const renderCheckbox = () => {
      if (tasksLoading) return null;

      return (
        <span
          className={classNames("tasks-table__td-checkbox", {
            "tasks-table__td-checkbox--checked": cell.row.getIsSelected(),
          })}
        >
          <span className="tasks-table__td-checkbox-index">{rowIndex + 1}</span>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </span>
      );
    };

    return (
      <>
        {cell.column.id === "drag-handle" && <DragHandle />}

        {cell.column.id === "select" && renderCheckbox()}

        {cell.column.id === " action" && (
          <ActionDropdown
            activeWorkspaceUuid={activeWorkspaceUuid}
            projectBasicData={projectBasicData}
            globalTaskUuid={rowData?.wsGlobalTaskUuid}
            identity={identity}
            taskId={rowId}
            taskName={rowData.title}
            taskShared={rowData.shared}
            taskType={rowData.taskType}
            titleFieldId={rowData.titleMetaFieldId}
            socialProfiles={rowData.socialProfiles}
          />
        )}

        {COLUMN_ID_ALLOW_LIST.includes(cell.column.id) && (
          <TaskInfo
            activeWorkspaceUuid={activeWorkspaceUuid}
            projectBasicData={projectBasicData}
            dataType={tableDataType.Task}
            item={rowData}
            parentLoading={false}
            showShareMultipleColumnsModal={showShareMultipleColumnsModal}
            showStopSharingWithClientModal={showStopSharingWithClientModal}
            taskAvatarShape={taskAvatarShape.Circle}
            taskType={rowData.taskType}
            tasksLoading={tasksLoading}
          />
        )}
        {!COLUMN_ID_BLACKLIST.includes(cell.column.id) && MetaField}
      </>
    );
  },
);

export default Cell;
