import {
  SET_ACTIVE_SORTING_COLUMN,
  SET_SORTING_DIRECTION,
  SET_AVAILABLE_FILTERS,
  SET_SELECTED_FILTERS,
  SET_LOCAL_SYNC_TOGGLE,
} from "./taskFiltersTypes";

export function setActiveSortingColumn(sortingColumn: string | null) {
  return {
    type: SET_ACTIVE_SORTING_COLUMN,
    payload: {
      activeSortingColumn: sortingColumn,
    },
  };
}

export function setSortingDirection(sortingDirection: string) {
  return {
    type: SET_SORTING_DIRECTION,
    payload: {
      sortingDirection,
    },
  };
}

export function setAvailableFilters(availableFilters: any) {
  return {
    type: SET_AVAILABLE_FILTERS,
    payload: {
      availableFilters,
    },
  };
}

export function setSelectedFilters(selectedFilters: any) {
  return {
    type: SET_SELECTED_FILTERS,
    payload: {
      selectedFilters,
    },
  };
}

export function setLocalSyncToggle(localSyncToggle: boolean) {
  return {
    type: SET_LOCAL_SYNC_TOGGLE,
    payload: {
      localSyncToggle,
    },
  };
}
