import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "src/utils/API_URLS";
import axios from "axios";
import { showToast } from "src/app/methods/showToast";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { getSelectDataSetForColumns } from "src/utils/methods";
import { SkeletonLoader } from "../GlobalActivityTab/utils";
import { AssignDictionaryModal } from "./components/AssignDictionaryModal/AssignDictionaryModal";
import { DictionaryTabList } from "./components/DictionaryTabList/DictionaryTabList";
import { EmptyDictionaryTab } from "./components/EmptyDictionaryTab/EmptyDictionaryTab";

export interface DictionaryItem {
  wsDictionaryElementName: string;
  wsDictionaryGlobalCreatorMetaFieldUuid: string;
  wsDictionaryName: string;
  wsDictionaryUrl: string;
  wsDictionaryIcon: string;
  wsDictionaryUuid: string;
}

export interface DictionaryTabElement {
  metadata: Metadata;
  title: string;
  titleMetaFieldUuid: string;
  uuid: string;
}

interface Props {
  activeTab: string;
}

export const DictionaryTab: React.FC<Props> = (props) => {
  const { activeTab } = props;

  const globalTaskDetails = useSelector(
    (state: RootState) => state.creatorDatabaseReducer.globalTaskDetails,
  );

  const [assignedDictionaryElements, setAssignedDictionaryElements] = useState<
    DictionaryTabElement[]
  >([]);
  const [allDictionaryElements, setAllDictionaryElements] = useState<
    DictionaryTabElement[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [showAssignDictionaryModal, setShowAssignDictionaryElementModal] =
    useState(false);

  const dispatch = useDispatch();

  const dictionaryData = useMemo(
    () =>
      globalTaskDetails?.wsDictionariesRelated.find(
        (item) => item.wsDictionaryUrl === activeTab,
      ),
    [globalTaskDetails, activeTab],
  );

  const getDictionaryList = async (
    updateFunction: Dispatch<SetStateAction<any>>,
    filters?: { [key: string]: { [key: string]: string[] } },
  ) => {
    if (!dictionaryData) return;
    try {
      const url = API_URLS.getDictionaryList.replace(
        ":dictionaryUuid:",
        dictionaryData.wsDictionaryUuid,
      );

      const {
        data: { content },
      } = await axios.get(url, {
        params: { filters },
      });

      updateFunction(content.list);
    } catch (error) {
      console.error(error);
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong"
        />,
      );
    } finally {
      setLoading(false);
    }
  };

  const getLists = () => {
    if (!dictionaryData) return;

    getDictionaryList(setAssignedDictionaryElements, {
      [dictionaryData.wsDictionaryGlobalCreatorMetaFieldUuid]: {
        value: [globalTaskDetails.uuid],
      },
    });
    getDictionaryList(setAllDictionaryElements);
  };

  useEffect(() => {
    setLoading(true);
    getLists();
  }, [dictionaryData, globalTaskDetails?.uuid]);

  useEffect(() => {
    setTimeout(() => {
      getLists();
    }, 300);
  }, [window.location.search]);

  const renderContent = () => {
    if (loading) {
      return <SkeletonLoader avatarShape="rect" />;
    }

    if (!assignedDictionaryElements?.length) {
      return (
        <EmptyDictionaryTab
          showDictionaryModal={() => setShowAssignDictionaryElementModal(true)}
          dictionaryName={dictionaryData?.wsDictionaryUrl?.toLowerCase() || ""}
        />
      );
    }

    return (
      <DictionaryTabList
        list={assignedDictionaryElements}
        dictionaryData={dictionaryData}
        showDictionaryModal={() => setShowAssignDictionaryElementModal(true)}
        loading={loading}
        dictionaryName={dictionaryData?.wsDictionaryUrl?.toLowerCase() || ""}
      />
    );
  };

  return (
    <div className="dictionary-tab">
      {renderContent()}

      {showAssignDictionaryModal && dictionaryData && (
        <AssignDictionaryModal
          onClose={() => setShowAssignDictionaryElementModal(false)}
          dictionaryData={dictionaryData}
          assignedDictionaryElements={assignedDictionaryElements}
          allDictionaryElements={allDictionaryElements}
          getLists={getLists}
          dictionaryName={dictionaryData?.wsDictionaryUrl?.toLowerCase() || ""}
        />
      )}
    </div>
  );
};
