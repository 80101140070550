import React from "react";

import classNames from "classnames";
import { splitNumbers, splitPercents } from "../../../../utils/methods";
import { tableDataType } from "../../../components/Table/Table";
import { Metadata } from "../../../Task/Subtask/Subtask";
import { renderMetaFieldIcon } from "../functions/renderMetaFieldIcon";

interface Props {
  data: Metadata;
  fieldType: string;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  value: string | number;
  prefix?: string;
  dataType?: tableDataType;
}

export function ProjectFieldPreviewSwitch(props: Props) {
  const { data, fieldType, setEditMode, value, prefix, dataType } = props;

  const isTask = dataType === tableDataType.Task;

  const renderSwitch = (fieldType: string) => {
    switch (fieldType) {
      case "number":
        return (
          <span
            className={classNames("ws-table__field-value-wrapper", {
              "ws-table__field-value-wrapper--horizontal": isTask,
            })}
          >
            {renderMetaFieldIcon(data, dataType)}
            {splitNumbers(value)}
          </span>
        );
      case "percent":
        return (
          <span
            className={classNames("ws-table__field-value-wrapper", {
              "ws-table__field-value-wrapper--horizontal": isTask,
            })}
          >
            {renderMetaFieldIcon(data, dataType)}
            {splitPercents(value)}%
          </span>
        );
      case "currency":
        return (
          <span
            className={classNames("ws-table__field-value-wrapper", {
              "ws-table__field-value-wrapper--horizontal": isTask,
            })}
          >
            {renderMetaFieldIcon(data, dataType)}
            <div>
              <span className="currency-code">{prefix}</span>
              <span className="currency-value">{splitNumbers(value)}</span>
            </div>
          </span>
        );
      default:
        return <span className="ws-table__field-value-text">{value}</span>;
    }
  };

  return (
    <div
      key={value}
      className="ws-table__field-value"
      onClick={setEditMode ? () => setEditMode(true) : undefined}
    >
      {renderSwitch(fieldType)}
    </div>
  );
}
