import React, { useEffect, useState } from "react";
import "./Board.scss";

import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import { useIntl } from "react-intl";
import _ from "lodash";
import { RootState } from "src/redux/reducers";
import { ColumnOption } from "src/types";
import AnimatedDiv from "../components/AnimatedDiv/AnimatedDiv";
import { IColumn } from "../project/Project";
import BoardColumn from "./BoardColumn/BoardColumn";
import { changeTaskMetaFieldValue, updateBoardTaskRank } from "../../redux";
import { generateRankString } from "../../utils/rankStrings";
import { BoardColumnLabel } from "./BoardColumn/BoardColumnLabel";
import { getColumnTextLabel } from "../methods/getColumnTextLabel";
import { MetaFieldType } from "../methods/getMetaFieldTypeOptions";

export interface TaskColumn {
  uuid: string;
  name: string;
  type: string;
  value: string;
}

interface Props {
  tasks: any;
  fields: IColumn[];
  column: ColumnOption | null;
  hidden?: boolean;
}

const Board: React.FC<Props> = (props) => {
  const { tasks, column, hidden } = props;

  const [placeholderProps, setPlaceholderProps] = useState({});
  const [boardColumns, setBoardColumns] = useState<any>([]);
  const [emptyColumn, setEmptyColumn] = useState<any>({});
  const [disableImageLoad, setDisableImageLoad] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();

  const params = useParams<{ projectId: string }>();

  const {
    projectReducer: { membersList, taskType },
    taskReducer: { projectTasksAutocomplete },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!column) {
      return;
    }

    const columnsArray: any = [];
    const values: any = [];

    if (membersList) {
      tasks.forEach((task: any) => {
        const columnValue = task.metadata.find((item: any) => {
          return item.name === column.label;
        })?.value;

        if (columnValue && !values.includes(columnValue)) {
          values.push(columnValue);
        }
      });

      tasks.forEach((item: any) => {
        const colData = item.metadata.find(
          (item: any) => item.name === column.label,
        );

        setEmptyColumn({
          ...colData,
          type: "text",
          value: null,
          label: "Empty",
        });

        const tasksAutocomplete =
          projectTasksAutocomplete[params.projectId] ?? [];

        if (
          colData?.value &&
          !columnsArray.find((item: TaskColumn) => item.value === colData.value)
        ) {
          const columnLabel = (
            <BoardColumnLabel
              columnType={column.type}
              colData={colData}
              values={values}
              tasksAutocomplete={tasksAutocomplete}
            />
          );

          const formattedValue = getColumnTextLabel(
            column.type,
            colData,
            values,
            membersList,
            tasksAutocomplete,
            intl,
          );

          columnsArray.push({
            ...colData,
            label: columnLabel,
            formattedValue,
          });
        }
      });

      if (column?.type === MetaFieldType.SingleSelect && tasks.length) {
        const selectColumns = tasks[0].metadata.find((metafield: any) => {
          return metafield.uuid === column.uuid;
        })?.data?.singleSelectOptions;

        if (selectColumns) {
          setBoardColumns(
            selectColumns.map((item: { name: string }) => ({
              ...item,
              type: column.type,
              uuid: column.uuid,
              label: item.name,
            })),
          );
          return;
        }
      }

      if (column?.type === MetaFieldType.BoolVal && tasks.length) {
        const boolValColumn = tasks[0].metadata.find((metafield: any) => {
          return metafield.uuid === column.uuid;
        });

        if (boolValColumn) {
          setBoardColumns([
            {
              ...boolValColumn,
              value: true,
              type: boolValColumn.type,
              uuid: boolValColumn.uuid,
              label: "Checked",
            },
            {
              ...boolValColumn,
              value: false,
              type: boolValColumn.type,
              uuid: boolValColumn.uuid,
              label: "Unchecked",
            },
          ]);
        }

        return;
      }

      if (!tasks.length) {
        setEmptyColumn({});
      }

      setBoardColumns(columnsArray);
    }
  }, [tasks, membersList, column]);

  useEffect(() => {
    setDisableImageLoad(false);
  }, [taskType]);

  const handleDragUpdate = (update: any) => {
    if (!update.destination) {
      return;
    }

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const { draggableId } = update;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as {
      clientHeight: number;
      clientWidth: number;
      parentNode: any;
    };

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          return total + curr.clientHeight + 17;
        }, 0);

    setPlaceholderProps({
      clientHeight: clientHeight + 2,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    });
  };

  const updateTaskPosition = (
    tasksData: any,
    taskId: string,
    destinationIndex: number,
    sourceIndex: number,
  ) => {
    let targetIndex = 0;

    if (destinationIndex === 0) {
      targetIndex = 0;
    } else if (destinationIndex <= sourceIndex) {
      targetIndex = destinationIndex - 1;
    } else {
      targetIndex = destinationIndex;
    }

    let newRank = null;

    if (destinationIndex === 0) {
      newRank = generateRankString(
        tasksData[targetIndex - 1]?.rank || "",
        tasksData[targetIndex]?.rank || "aaa",
      );
    } else if (destinationIndex >= tasksData.length) {
      newRank = generateRankString(
        tasksData[tasksData.length - 1]?.rank || "zzz",
        "",
      );
    } else {
      let lowerRank = null;

      const targetTaskIndex = tasks.findIndex(
        (x: any) => x.projectId === tasksData[targetIndex].projectId,
      );

      const condition = tasks[targetTaskIndex + 1]?.rank.localeCompare(
        tasksData[targetIndex + 1]?.rank,
      );
      if (condition === 1) {
        lowerRank = tasks[targetTaskIndex + 1]?.rank;
      } else {
        lowerRank = tasksData[targetIndex + 1]?.rank;
      }

      newRank = generateRankString(
        tasks[targetTaskIndex].rank,
        tasksData[targetIndex + 1]?.rank || "",
      );
    }

    if (newRank) {
      dispatch(updateBoardTaskRank(taskId, newRank));
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { destination, source, draggableId } = result;

    const taskId = draggableId.split("/")[0];

    const destinationColumnTasks = tasks.filter((item: any) =>
      item.metadata.find(
        (item: TaskColumn) => item.value === destination?.droppableId,
      ),
    );

    if (
      !(
        destination?.droppableId === source?.droppableId &&
        destination.index === source.index
      )
    ) {
      updateTaskPosition(
        destinationColumnTasks,
        taskId,
        destination?.index,
        source?.index,
      );
    }

    if (destination?.droppableId !== source?.droppableId) {
      dispatch(
        changeTaskMetaFieldValue(
          taskId,
          boardColumns[0].uuid,
          destination.droppableId === "valueToNull"
            ? null
            : destination.droppableId,
        ),
      );
    }

    setPlaceholderProps({});
  };

  const sortColumns = (a: any, b: any) => {
    switch (column?.type) {
      case MetaFieldType.SingleSelect:
      case MetaFieldType.BoolVal:
        return a.rank?.localeCompare(b.rank);
      case MetaFieldType.Creator:
      case MetaFieldType.Content:
      case MetaFieldType.Member:
        return a.formattedValue?.localeCompare(b.formattedValue);

      default:
        return a.value.localeCompare(b.value);
    }
  };

  return (
    <DragDropContext
      onDragUpdate={handleDragUpdate}
      onDragEnd={(result) => handleDragEnd(result)}
    >
      <AnimatedDiv className="board" outerHidden={hidden}>
        {emptyColumn.label && (
          <BoardColumn
            key={0}
            tasks={tasks}
            columnData={emptyColumn}
            placeholderProps={placeholderProps}
            boardColumns={boardColumns}
            disableImageLoad={disableImageLoad}
            setDisableImageLoad={setDisableImageLoad}
          />
        )}
        {boardColumns
          ?.sort(sortColumns)
          .map((columnData: TaskColumn, index: number) => (
            <BoardColumn
              key={index + 1}
              tasks={tasks}
              columnData={columnData}
              placeholderProps={placeholderProps}
              boardColumns={boardColumns}
              disableImageLoad={disableImageLoad}
              setDisableImageLoad={setDisableImageLoad}
            />
          ))}
      </AnimatedDiv>
    </DragDropContext>
  );
};

export default Board;
