import { uuidv7 as uuid } from "uuidv7";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { TaskType } from "src/types";
import { DateObject } from "src/app/project/Project";
import { CoverImage } from "src/redux/project/types";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { IMember } from "src/app/project/ProjectTypes";
import { PublicationData } from "src/types/publication";
import {
  ISubtask,
  MetaFieldOptions,
  Metadata,
} from "../../app/Task/Subtask/Subtask";
import { generateRankString } from "../../utils/rankStrings";
import {
  GET_TASKS_LIST,
  GET_TASKS_LIST_SUCCESS,
  UPDATE_TASKS_LIST_SUCCESS,
  UPDATE_BOARD_TASK_RANK,
} from "../project/projectTypes";
import { mockMyTasks, mockTasks } from "./mockTasks";
import * as actionTypes from "./taskTypes";
import { parseErrorMessages } from "../../utils/methods";
import { Column } from "../dictionary/types";
import {
  ChangeTaskMetaFieldValuePayload,
  UpdateTaskMetaFieldRankPayload,
} from "./taskActions";

export interface ITask {
  taskId: string;
  parentTaskId: string | null;
  title: string;
  titleMetaFieldId: string;
  ownerWsMemberUuid: string | null;
  rank: string;
  taskType: TaskType;
  subtasksCount: number;
  hasRelatedActionTasks: boolean;
  hasRelatedContentTasks: boolean;
  hasRelatedPublicationTasks: boolean;
  hasSubtasks: boolean;
  metadata: Metadata[];
  members: IMember[];
  cover: CoverImage | null;
  shared: boolean;
  wsGlobalTaskUuid: string | null;
  socialProfiles: SocialProfile[];
  isMocked?: boolean;
  publication?: PublicationData | null;
  wsProjectUuid: string;
  created: string;
}

export interface TasksColumn {
  metaFieldId: string;
  metaFieldName: string;
  metaFieldKey: string;
  metaFieldType: MetaFieldType;
  metaFieldVisible: boolean;
  metaFieldRank: string;
  metaFieldTaskType: TaskType;
  metaFieldIsRequired: boolean;
  metaFieldIsSystem: boolean;
  metaFieldShared: boolean;
  data: MetaFieldOptions;
  metaFieldValueSource: string | null;
  metaFieldSource: string | null;
  metaFieldGlobalTaskMetaFieldUuid: string | null;
  isMocked?: boolean;
}

export interface IMyTask {
  completed: boolean;
  completedMetaFieldUuid: string;
  dueDate: DateObject | null;
  dueDateMetaFieldUuid: string;
  hasProjectAccess: boolean;
  wsProjectCover: null;
  wsProjectName: string | null;
  wsProjectUuid: string | null;
  wsTaskCover: CoverImage | null;
  wsTaskName: string;
  wsTaskType: TaskType;
  wsTaskUuid: string;
}

interface ProjectTasksAutocomplete {
  [key: string]: ITask[];
}

export interface TasksTotalRow {
  [key: string]: Metadata;
}
export interface TaskReducerState {
  taskDetails: any;
  taskDetails_backup: any;
  taskDetailsLoading: boolean;
  tasks: ITask[];
  tasks_backup: ITask[];
  tasksColumns: TasksColumn[];
  tasksColumns_backup: TasksColumn[];
  titleMetaFieldId: string | null;
  selectedTasks: ITask[];
  taskDetailsError: boolean;
  tasksLoading: boolean;
  taskFieldAddedOrUpdated: boolean;
  showAddTaskModal: boolean;
  showAddCreatorsModal: boolean;
  showAddPublicationModal: boolean;
  gallerySources: string[];
  showStopSharingWithClientModal: string;
  showShareMultipleColumnsModal: string;
  showShareGroupMultipleColumnsModal: boolean;
  showStopSharingGroupWithClientModal: boolean;
  myTasksLoading: boolean;
  myTasks: IMyTask[];
  errorMessages: any[];
  tableInputFocused: boolean;
  lastSelectedRowID: string;
  workspaceTasksTotalRow: TasksTotalRow;
  workspaceTasks: ITask[];
  workspaceTasks_backup: ITask[];
  workspaceTasksColumns: Column[];
  areWorkspaceTasksLoading: boolean;
  projectTasksAutocomplete: ProjectTasksAutocomplete;
}

const initialState: TaskReducerState = {
  taskDetails: {
    subtasks: [],
  },
  taskDetailsLoading: false,
  taskDetails_backup: {
    subtasks: [],
  },
  tasks: [],
  tasks_backup: [],
  tasksColumns: [],
  tasksColumns_backup: [],
  titleMetaFieldId: null,
  selectedTasks: [],
  taskDetailsError: false,
  tasksLoading: false,
  taskFieldAddedOrUpdated: false,
  showAddTaskModal: false,
  showAddCreatorsModal: false,
  gallerySources: [],
  showAddPublicationModal: false,
  showStopSharingWithClientModal: "",
  showShareMultipleColumnsModal: "",
  showShareGroupMultipleColumnsModal: false,
  showStopSharingGroupWithClientModal: false,
  myTasksLoading: false,
  myTasks: [],
  errorMessages: [],
  tableInputFocused: false,
  lastSelectedRowID: "",
  workspaceTasksTotalRow: {},
  workspaceTasks: [],
  workspaceTasks_backup: [],
  workspaceTasksColumns: [],
  areWorkspaceTasksLoading: false,
  projectTasksAutocomplete: {},
};

function taskReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_TASK_DETAILS_SUCCESS:
      const response = action?.payload?.data?.content;

      return {
        ...state,
        taskDetailsLoading: false,
        taskDetails: {
          ...response,
          subtasks: response.subtasks.sort(
            (a: { rank: string }, b: { rank: any }) => {
              return a.rank.localeCompare(b.rank);
            },
          ),
        },
      };
    case actionTypes.PUBLICATION_SYNCHRONIZATION_IN_PROGRESS:
      return {
        ...state,
        taskDetailsLoading: true,
      };

    case actionTypes.PUBLICATION_SYNCHRONIZATION_DONE:
      return {
        ...state,
        taskDetailsLoading: false,
      };

    case actionTypes.GET_TASK_DETAILS_FAIL: {
      return {
        ...state,
        taskDetailsLoading: false,
        taskDetailsError: true,
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };
    }

    case actionTypes.CLEAR_TASK_DETAILS:
      return {
        ...state,
        taskDetails: {
          subtasks: [],
        },
        taskDetailsError: false,
      };

    case actionTypes.CREATE_SUBTASK: {
      const { data } = action.payload;

      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          subtasks: state.taskDetails.subtasks.map((subtask: ISubtask) => {
            if (data.taskId === subtask.id) {
              return {
                ...subtask,
                title: data.taskName,
                rank: data.taskRank,
              };
            }
            return subtask;
          }),
        },
      };
    }

    case actionTypes.CREATE_EMPTY_SUBTASK: {
      const { subtasks } = state.taskDetails;

      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          subtasks: [
            ...subtasks,
            {
              id: uuid(),
              title: "",
              rank: generateRankString(
                subtasks[subtasks.length - 1]?.rank || "",
                "",
              ),
              isNew: true,
            },
          ],
        },
      };
    }

    case actionTypes.DELETE_NEW_SUBTASK_WITHOUT_TITLE:
      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          subtasks: state.taskDetails.subtasks.slice(0, -1),
        },
      };

    case actionTypes.UPDATE_SUBTASK_RANK: {
      const { taskId, taskRank } = action.payload;

      return {
        ...state,
        taskDetails: {
          ...state.taskDetails,
          subtasks: state.taskDetails.subtasks.map((subtask: ISubtask) => {
            if (taskId === subtask.id) {
              return {
                ...subtask,
                rank: taskRank,
              };
            }

            return subtask;
          }),
        },
      };
    }

    case actionTypes.GET_MY_TASKS: {
      return {
        ...state,
        myTasksLoading: true,
        myTasks: state.myTasks.length === 0 ? mockMyTasks : state.myTasks,
      };
    }

    case actionTypes.GET_MY_TASKS_SUCCESS: {
      return {
        ...state,
        myTasks: action.payload.data.content,
        myTasksLoading: false,
      };
    }

    case actionTypes.CLEAR_MY_TASKS_LIST:
    case actionTypes.GET_MY_TASKS_ERROR:
      return {
        ...state,
        myTasks: [],
        myTasksLoading: false,
      };

    case actionTypes.GET_SINGLE_TASK_FOR_MY_TASKS_LIST_SUCCESS:
      const updatedTask = action.payload.data.content[0];

      return {
        ...state,
        myTasks: state.myTasks.map((task) =>
          task.wsTaskUuid === updatedTask.wsTaskUuid ? updatedTask : task,
        ),
      };

    // new

    case actionTypes.CREATE_GLOBAL_TASK_META_FIELD_SUCCESS:
      return {
        ...state,
        taskFieldAddedOrUpdated: true,
      };

    case actionTypes.ADD_TASK_META_FIELD: {
      const { metaFieldId, metaFieldName, metaFieldType, metaFieldRank } =
        action.payload.request.data;

      const newTaskColumn = {
        metaFieldId,
        metaFieldName,
        metaFieldType,
        metaFieldIsRequired: false,
        metaFieldVisible: true,
        metaFieldRank,
        metaFieldKey: metaFieldName,
        metaFieldValueSource: null,
        data: [],
      };

      return {
        ...state,
        tasksColumns_backup: state.tasksColumns,
        tasksColumns: [...state.tasksColumns, newTaskColumn],
        tasks: state.tasks.map((task) => ({
          ...task,
          metadata: [
            ...task.metadata,
            {
              uuid: metaFieldId,
              isRequired: false,
              isVisible: true,
              key: metaFieldName,
              manuallyEdited: false,
              data: [],
              shouldTriggerEditWarning: false,
              type: metaFieldType,
              value: null,
              valueSource: null,
            },
          ],
        })),
      };
    }

    case actionTypes.ADD_TASK_META_FIELD_SUCCESS:
      return {
        ...state,
        taskFieldAddedOrUpdated: true,
      };

    case actionTypes.ADD_TASK_META_FIELD_FAIL:
      return {
        ...state,
        tasksColumns: state.tasksColumns_backup,
        tasksColumns_backup: mockTasks.tasksColumns,
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };

    case actionTypes.UPDATE_TASK_META_FIELD:
      return {
        ...state,
        tasksColumns: state.tasksColumns.map((field) => {
          if (field.metaFieldId === action.payload.id) {
            return {
              ...field,
              metaFieldName: action.payload.title,
            };
          }
          return field;
        }),
      };

    case actionTypes.UPDATE_TASK_META_FIELD_SUCCESS:
      return {
        ...state,
        taskFieldAddedOrUpdated: true,
      };

    case actionTypes.UPDATE_TASK_META_FIELD_RANK: {
      const {
        metaFieldId,
        metaFieldRank,
        tableContext,
      }: UpdateTaskMetaFieldRankPayload = action.payload;

      if (tableContext === "workspaceTasksList") {
        return {
          ...state,
          workspaceTasksColumns: state.workspaceTasksColumns.map((column) => {
            if (column.uuid === metaFieldId) {
              return {
                ...column,
                rank: metaFieldRank,
              };
            }
            return column;
          }),
        };
      }

      return {
        ...state,
        tasksColumns: state.tasksColumns.map((column) => {
          if (column.metaFieldId !== metaFieldId) {
            return column;
          }
          return {
            ...column,
            metaFieldRank,
          };
        }),
      };
    }

    case actionTypes.UPDATE_TASK_SINGLE_SELECT_FIELD: {
      const { metaFieldId, metaFieldNameUpdated, singleSelectOptions } =
        action.payload;

      return {
        ...state,
        tasks: state.tasks.map((task) => ({
          ...task,
          metadata: task.metadata.map((field) => {
            if (field.uuid === metaFieldId) {
              return {
                ...field,
                name: metaFieldNameUpdated,
                options: singleSelectOptions,
              };
            }
            return field;
          }),
        })),
        tasksColumns: state.tasksColumns.map((column) => {
          if (column.metaFieldId === metaFieldId) {
            return {
              ...column,
              metaFieldName: metaFieldNameUpdated,
              data: singleSelectOptions,
            };
          }
          return column;
        }),
      };
    }

    case UPDATE_BOARD_TASK_RANK: {
      const { taskId, taskRank } = action.payload;
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (taskId === task.taskId) {
            return {
              ...task,
              rank: taskRank,
            };
          }
          return task;
        }),
      };
    }

    case actionTypes.CHANGE_WORKSPACE_TASK_FIELD_VALUE: {
      const { uuids, fieldId, newValue }: ChangeTaskMetaFieldValuePayload =
        action.payload;

      const updatedTaskDetails = {
        ...state.taskDetails,
        metadata: state.taskDetails?.metadata?.map((field: Metadata) => {
          if (fieldId === field.wsGlobalTaskMetaFieldUuid) {
            return {
              ...field,
              value:
                field.type === MetaFieldType.Date
                  ? {
                      date: newValue,
                    }
                  : newValue,
            };
          }
          return field;
        }),
      };

      const updateTaskMetaValue = (task: ITask) => {
        if (uuids.includes(task.taskId)) {
          const metadata = task.metadata.map((field) => {
            if (fieldId === field.wsGlobalTaskMetaFieldUuid) {
              return {
                ...field,
                value:
                  field.type === MetaFieldType.Date && newValue
                    ? {
                        date: newValue,
                      }
                    : newValue,
              };
            }
            return field;
          });

          return {
            ...task,
            metadata,
          };
        }

        return task;
      };

      return {
        ...state,
        workspaceTasks: state.workspaceTasks.map(updateTaskMetaValue),
        taskDetails: updatedTaskDetails,
      };
    }

    case actionTypes.CHANGE_WORKSPACE_TASK_FIELD_VALUE_FAIL: {
      return {
        ...state,
        workspaceTasks: state.workspaceTasks_backup,
        taskDetails: state.taskDetails_backup,
        taskDetails_backup: {
          subtasks: [],
        },
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };
    }

    case actionTypes.CHANGE_TASK_FIELD_VALUE: {
      const { uuids, fieldId, newValue }: ChangeTaskMetaFieldValuePayload =
        action.payload;

      const updatedTaskDetails = {
        ...state.taskDetails,
        metadata: state.taskDetails?.metadata?.map((field: Metadata) => {
          if (fieldId === field.uuid) {
            return {
              ...field,
              value:
                field.type === MetaFieldType.Date
                  ? {
                      date: newValue,
                    }
                  : newValue,
            };
          }
          return field;
        }),
      };

      const updateTaskMetaValue = (task: ITask) => {
        if (uuids.includes(task.taskId)) {
          const metadata = task.metadata.map((field) => {
            if (fieldId === field.uuid) {
              return {
                ...field,
                value:
                  field.type === MetaFieldType.Date && newValue
                    ? {
                        date: newValue,
                      }
                    : newValue,
              };
            }
            return field;
          });

          return {
            ...task,
            metadata,
          };
        }

        return task;
      };

      return {
        ...state,
        tasks: state.tasks.map(updateTaskMetaValue),
        taskDetails: updatedTaskDetails,
        tasks_backup: state.tasks,
        taskDetails_backup: state.taskDetails,
      };
    }

    case actionTypes.CHANGE_TASK_FIELD_VALUE_FAIL: {
      return {
        ...state,
        tasks: state.tasks_backup,
        tasks_backup: mockTasks.tasks,
        taskDetails: state.taskDetails_backup,
        taskDetails_backup: {
          subtasks: [],
        },
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };
    }

    case actionTypes.UPDATE_TASK_META_FIELD_VISIBILITY: {
      const { metaFieldId, visible } = action.payload;

      return {
        ...state,
        tasksColumns: state.tasksColumns.map((column) => {
          if (column.metaFieldId !== metaFieldId) {
            return column;
          }
          return {
            ...column,
            metaFieldVisible: visible,
          };
        }),
      };
    }

    case actionTypes.SET_TASKS_LIST:
      return {
        ...state,
        tasks: action.payload.tasks.sort(
          (a: { rank: string }, b: { rank: any }) => {
            return b.rank.localeCompare(a.rank);
          },
        ),
        tasksColumns: action.payload.tasksColumns,
        titleMetaFieldId: action.payload.titleMetaFieldId ?? null,
      };

    case actionTypes.SET_SELECTED_TASKS:
      return {
        ...state,
        selectedTasks: action.payload.selectedTasks,
      };

    case GET_TASKS_LIST:
      return {
        ...state,
        tasksLoading: true,
        tasks: mockTasks.tasks,
        tasksColumns: mockTasks.tasksColumns,
        selectedTasks: [],
      };

    case GET_TASKS_LIST_SUCCESS: {
      const tasks = [...action.payload.data.content.tasks].sort(
        (a: { rank: string }, b: { rank: any }) => {
          return b.rank.localeCompare(a.rank);
        },
      );

      return {
        ...state,
        tasks,
        tasksColumns: action.payload.data.content.columns,
        titleMetaFieldId: action.payload.data.content.titleMetaFieldId ?? null,
        tasksLoading: false,
        selectedTasks: [],
      };
    }

    case UPDATE_TASKS_LIST_SUCCESS:
      return {
        ...state,
        tasks: action.payload.data.content.tasks.sort(
          (a: { rank: string }, b: { rank: any }) => {
            return b.rank.localeCompare(a.rank);
          },
        ),
        tasksColumns: action.payload.data.content.columns,
        titleMetaFieldId: action.payload.data.content.titleMetaFieldId ?? null,
      };

    case actionTypes.SET_TASKS_LOADING: {
      const isLoading = action.payload.tasksLoading;
      if (isLoading) {
        if (state.tasks.length > 0) {
          return {
            ...state,
            tasksLoading: true,
          };
        }
        return {
          ...state,
          tasks: mockTasks.tasks,
          tasksColumns: mockTasks.tasksColumns,
          tasksLoading: true,
        };
      }

      if (
        state.tasksColumns.findIndex((taskColumn) => taskColumn.isMocked) !== -1
      ) {
        return {
          ...state,
          tasksLoading: false,
          tasks: [],
          tasksColumns: [],
        };
      }

      return {
        ...state,
        tasksLoading: false,
      };
    }

    case actionTypes.OPEN_ADD_TASK_MODAL:
      return {
        ...state,
        showAddTaskModal: true,
      };

    case actionTypes.CLOSE_ADD_TASK_MODAL:
      return {
        ...state,
        showAddTaskModal: false,
      };

    case actionTypes.OPEN_ADD_CREATORS_MODAL:
      return {
        ...state,
        showAddCreatorsModal: true,
      };

    case actionTypes.CLOSE_ADD_CREATORS_MODAL:
      return {
        ...state,
        showAddCreatorsModal: false,
      };

    case actionTypes.OPEN_ADD_PUBLICATION_MODAL:
      return {
        ...state,
        showAddPublicationModal: true,
      };

    case actionTypes.CLOSE_ADD_PUBLICATION_MODAL:
      return {
        ...state,
        showAddPublicationModal: false,
      };

    case actionTypes.SET_GALLERY_SOURCES:
      return {
        ...state,
        gallerySources: action.payload.gallerySources,
      };

    case actionTypes.GET_SINGLE_TASK_FOR_LIST_SUCCESS: {
      const taskForList = action.payload.data.content[0];

      const updatedTasks = state.tasks.map((item) => {
        if (item.taskId === taskForList.taskId) {
          return taskForList;
        }

        return item;
      });

      return {
        ...state,
        tasks: updatedTasks,
        tasks_backup: updatedTasks,
      };
    }

    case actionTypes.GET_SINGLE_WORKSPACE_TASK_FOR_LIST_SUCCESS: {
      const { taskType }: { taskType?: TaskType } =
        action.meta.previousAction.payload;

      const taskForList = action.payload.data.content[0];

      if (taskType && taskForList.taskType !== taskType) {
        return state;
      }

      const updateOrAddTask = (tasks: ITask[]) => {
        const taskIndex = tasks.findIndex(
          (item) => item.taskId === taskForList.taskId,
        );

        if (taskIndex !== -1) {
          return tasks.map((item, index) =>
            index === taskIndex ? taskForList : item,
          );
        }

        return [...tasks, taskForList];
      };

      const updatedWorkspaceTasks = updateOrAddTask(state.workspaceTasks);
      return {
        ...state,
        workspaceTasks: updatedWorkspaceTasks,
        workspaceTasks_backup: updatedWorkspaceTasks,
      };
    }

    case actionTypes.OPEN_STOP_SHARING_WITH_CLIENT_MODAL:
      return {
        ...state,
        showStopSharingWithClientModal: action.payload.taskId,
      };

    case actionTypes.OPEN_STOP_SHARING_GROUP_WITH_CLIENT_MODAL:
      return {
        ...state,
        showStopSharingGroupWithClientModal: Boolean(action.payload.taskIds),
      };

    case actionTypes.HIDE_STOP_SHARING_WITH_CLIENT_MODAL:
      return {
        ...state,
        showStopSharingWithClientModal: "",
        showStopSharingGroupWithClientModal: false,
      };

    case actionTypes.OPEN_SHARE_MULTIPLE_COLUMNS_MODAL:
      return {
        ...state,
        showShareMultipleColumnsModal: action.payload.taskId,
      };

    case actionTypes.OPEN_SHARE_GROUP_MULTIPLE_COLUMNS_MODAL:
      return {
        ...state,
        showShareGroupMultipleColumnsModal: action.payload.taskIds ?? false,
      };

    case actionTypes.HIDE_SHARE_MULTIPLE_COLUMNS_MODAL:
      return {
        ...state,
        showShareMultipleColumnsModal: false,
        showShareGroupMultipleColumnsModal: false,
      };

    case actionTypes.CLEAR_TASK_LIST:
      return {
        ...state,
        tasks: [],
        tasksColumns: [],
        titleMetaFieldId: null,
        tasksLoading: false,
      };

    case actionTypes.DELETE_SELECTED_TASKS:
      if (state.tasks.length > 0) {
        return {
          ...state,
          tasksLoading: true,
          selectedTasks: [],
        };
      }
      return {
        ...state,
        tasks: mockTasks.tasks,
        tasksColumns: mockTasks.tasksColumns,
        tasksLoading: true,
        selectedTasks: [],
      };

    case actionTypes.DELETE_SELECTED_TASKS_SUCCESS: {
      const { selectedTasks }: { selectedTasks: ITask[] } =
        action.meta.previousAction.payload;
      const filteredTasks = state.tasks.filter(
        (task) =>
          selectedTasks.findIndex(
            (selectedTask) => selectedTask.taskId === task.taskId,
          ) === -1,
      );

      return {
        ...state,
        tasks: filteredTasks,
        tasksLoading: false,
      };
    }

    case actionTypes.DELETE_SELECTED_TASKS_FAIL:
      if (
        state.tasksColumns.findIndex((taskColumn) => taskColumn.isMocked) !== -1
      ) {
        return {
          ...state,
          tasks: [],
          tasksColumns: [],
          tasksLoading: false,
        };
      }

      return {
        ...state,
        tasksLoading: false,
      };

    case actionTypes.SET_TASK_FIELD_ADDED_OR_UPDATED:
      return {
        ...state,
        taskFieldAddedOrUpdated: action.payload.taskFieldAddedOrUpdated,
      };

    case actionTypes.LOCAL_SET_COLUMNS_SHARING: {
      const { columnIds, value } = action.payload;
      return {
        ...state,
        tasksColumns: state.tasksColumns.map((column) => {
          if (columnIds.includes(column.metaFieldId)) {
            return {
              ...column,
              metaFieldShared: value,
            };
          }

          return column;
        }),
      };
    }

    case actionTypes.LOCAL_SET_COLUMNS_GROUP_SHARING: {
      const { columnIds } = action.payload;
      return {
        ...state,
        tasksColumns: state.tasksColumns.map((column) => {
          if (columnIds.includes(column.metaFieldId)) {
            return {
              ...column,
              metaFieldShared: true,
            };
          }

          return {
            ...column,
            metaFieldShared: false,
          };
        }),
      };
    }

    case actionTypes.LOCAL_SET_TASKS_SHARING: {
      const { taskIds, shared } = action.payload;
      return {
        ...state,
        tasks: state.tasks.map((task: any) => {
          if (taskIds.includes(task.taskId)) {
            return {
              ...task,
              shared,
            };
          }

          return task;
        }),
      };
    }

    case actionTypes.CLEAR_TASK_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: [],
      };

    case actionTypes.SET_TABLE_INPUT_FOCUSED:
      return {
        ...state,
        tableInputFocused: action.payload.tableInputFocused,
      };

    case actionTypes.SET_LAST_SELECTED_ROW_ID:
      return {
        ...state,
        lastSelectedRowID: action.payload.lastSelectedRowID,
      };
    case actionTypes.GET_WORKSPACE_TASKS:
      return {
        ...state,
        areWorkspaceTasksLoading: true,
      };
    case actionTypes.GET_WORKSPACE_TASKS_SUCCESS: {
      const { tasks, columns, totalRow } = action.payload.data.content;
      return {
        ...state,
        areWorkspaceTasksLoading: false,
        workspaceTasks: tasks,
        workspaceTasks_backup: tasks,
        workspaceTasksColumns: columns,
        workspaceTasksTotalRow: totalRow,
      };
    }
    case actionTypes.GET_WORKSPACE_TASKS_FAIL:
      return {
        ...state,
        areWorkspaceTasksLoading: false,
      };
    case actionTypes.CLEAR_WORKSPACE_TASKS:
      return {
        ...state,
        workspaceTasks: [],
        workspaceTasks_backup: [],
        workspaceTasksColumns: [],
        workspaceTasksTotalRow: {},
      };
    case actionTypes.GET_PROJECT_TASKS_AUTOCOMPLETE_SUCCESS: {
      const { wsProjectUuid }: { wsProjectUuid: string } =
        action.meta.previousAction.payload;

      return {
        ...state,
        projectTasksAutocomplete: {
          ...state.projectTasksAutocomplete,
          [wsProjectUuid]: action.payload.data.content.tasks,
        },
      };
    }

    default:
      return state;
  }
}

export default taskReducer;
