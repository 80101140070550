import { TaskType } from "src/types";
import { IMember } from "src/app/project/ProjectTypes";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import {
  ADD_PROJECT_META_FIELD,
  ADD_PROJECT_META_FIELD_FAIL,
  ADD_PROJECT_META_FIELD_SUCCESS,
  CLEAN_GET_PROJECT_FAILED,
  CLEAR_GLOBAL_META_FIELD_LIST,
  CLEAR_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
  CLEAR_PROJECTS_LIST,
  CLEAR_PROJECT_BASIC_DATA,
  CLEAR_TASKS_COUNTERS,
  CREATE_GLOBAL_PROJECT_META_FIELD_SUCCESS,
  DELETE_FIELD_SUCCESS,
  DELETE_PROJECT_META_FIELD,
  DELETE_PROJECT_META_FIELD_SUCCESS,
  DELETE_TASK_SUCCESS,
  GET_FILTERED_PROJECTS_LIST,
  GET_FILTERED_PROJECTS_LIST_SUCCESS,
  GET_GLOBAL_PROJECT_META_FIELD_LIST,
  GET_GLOBAL_PROJECT_META_FIELD_LIST_SUCCESS,
  GET_GLOBAL_TASK_META_FIELD_LIST,
  GET_GLOBAL_TASK_META_FIELD_LIST_SUCCESS,
  GET_MEMBERS_LIST,
  GET_MEMBERS_LIST_FAIL,
  GET_MEMBERS_LIST_SUCCESS,
  GET_PROJECTS_AUTOCOMPLETE_LIST,
  GET_PROJECTS_AUTOCOMPLETE_LIST_FAIL,
  GET_PROJECTS_AUTOCOMPLETE_LIST_SUCCESS,
  GET_PROJECT,
  GET_PROJECTS_LIST,
  GET_PROJECTS_LIST_FAIL,
  GET_PROJECTS_LIST_SUCCESS,
  GET_PROJECT_FAIL,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_TEMPLATES,
  GET_PROJECT_TEMPLATES_FAIL,
  GET_PROJECT_TEMPLATES_SUCCESS,
  GET_TASKS_COUNTERS_SUCCESS,
  RENAME_PROJECT_ON_LIST,
  SET_ACTIVE_PROJECT_ID,
  SET_EDITED_CELL_ID,
  SET_FILTERED_PROJECT_LIST,
  SET_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
  SET_MEMBERS_OPTIONS,
  SET_PROJECTS_LIST_OFFSET,
  SET_PROJECT_COLUMNS,
  SET_PROJECT_FIELD_ADDED_OR_UPDATED,
  SET_PROJECT_INITIAL_COLUMN_UUIDS,
  SET_PROJECT_LIST,
  SET_PROJECT_LOADING,
  SET_PROJECT_TEMPLATES,
  SET_SHOW_TASK,
  SET_TASK_TYPE,
  UPDATE_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS,
  UPDATE_PROJECT_META_FIELD_NAME,
  UPDATE_PROJECT_META_FIELD_NAME_SUCCESS,
  UPDATE_PROJECT_META_FIELD_ON_LIST,
  UPDATE_PROJECT_META_FIELD_RANK,
  UPDATE_PROJECT_META_FIELD_VISIBILITY,
  UPDATE_PROJECT_META_VALUE,
  UPDATE_PROJECT_SINGLE_SELECT_FIELD,
  UPDATE_PROJECT_SUCCESS,
  CLEAR_PROJECT_ERROR_MESSAGES,
  UPDATE_PROJECT_META_VALUE_FAIL,
  UPDATE_PROJECT_META_FIELD_ON_LIST_FAIL,
  GET_PROJECT_DETAILS_SUCCESS,
  CLEAR_PROJECT_DETAILS,
  REMOVE_PROJECT,
  SET_CREATOR_MESSAGE_PANE_OPEN,
  SET_CREATE_SINGLE_SPLIT_PAYMENTS_MODAL,
  SET_PROJECT_TABLE_VIEWS,
} from "./projectTypes";
import {
  IProject,
  TasksCounters,
  ProjectBasicData,
  ProjectTemplate,
  IProjectDetails,
  ProjectMetaData,
} from "./types";
import { parseErrorMessages } from "../../utils/methods";
import { WsTableView } from "../dictionary/types";

export interface ProjectColumn {
  data: any;
  description: string;
  existsInProject: boolean;
  name: string;
  origin: string;
  rank: string;
  taskType: null;
  type: MetaFieldType;
  uuid: string;
  valueRequired: boolean;
  valueSource: string | null;
  wsWorkspaceUuid: string;
}

export interface ProjectReducerState {
  projectBasicData: ProjectBasicData | null;
  projectDetails: IProjectDetails | null;
  projectDetails_backup: IProjectDetails | null;
  projectsList: IProject[];
  projectTemplates: ProjectTemplate[];
  projectTemplatesLoading: boolean;
  projectsColumns: ProjectColumn[];
  filteredProjectsList: IProject[];
  filteredProjectsList_backup: IProject[];
  projectColumnsVisibleByDefaultUuids: string[];
  projectsListLoading: boolean;
  projectsListLimit: number;
  projectsListOffset: number;
  projectMetaFields: ProjectMetaData[];
  projectMetaFields_backup: ProjectMetaData[];
  showTask: boolean;
  taskType: TaskType;
  membersList: IMember[];
  activeMembersList: IMember[];
  membersListLoading: boolean;
  errorMessages: any;
  successMessage: string | null;
  projectLoading: boolean;
  getProjectFailed: boolean;
  tasksCounters: TasksCounters | null;
  editedCellId: string;
  projectFieldAddedOrUpdated: boolean;
  globalMetaFieldsList: [];
  isGlobalMetaFieldsListLoading: boolean;
  globalMetaFieldProjectWizardFields: Metadata[];
  isCreatorMessagePaneOpen: boolean;
  isCreateSingleSplitPaymentsModalOpen: boolean;
  wsProjectAutocompleteList: IProject[];
  wsTableViews: WsTableView[];
}

const initialState: ProjectReducerState = {
  projectBasicData: null,
  projectDetails: null,
  projectDetails_backup: null,
  projectsList: [],
  projectTemplates: [],
  projectTemplatesLoading: true,
  projectsColumns: [],
  filteredProjectsList: [],
  filteredProjectsList_backup: [],
  projectColumnsVisibleByDefaultUuids: [],
  projectsListLoading: true,
  projectsListLimit: 40,
  projectsListOffset: 0,
  projectMetaFields: [],
  projectMetaFields_backup: [],
  showTask: false,
  taskType: TaskType.Creator,
  membersList: [],
  activeMembersList: [],
  membersListLoading: false,
  errorMessages: [],
  successMessage: null,
  projectLoading: false,
  getProjectFailed: false,
  tasksCounters: null,
  editedCellId: "",
  projectFieldAddedOrUpdated: false,
  globalMetaFieldsList: [],
  isGlobalMetaFieldsListLoading: false,
  globalMetaFieldProjectWizardFields: [],
  isCreatorMessagePaneOpen: false,
  isCreateSingleSplitPaymentsModalOpen: false,
  wsProjectAutocompleteList: [],
  wsTableViews: [],
};

const successMessage = (state: any, message: string) => {
  return {
    ...state,
    successMessage: message,
  };
};

function projectReducer(state = initialState, action: any) {
  switch (action.type) {
    case DELETE_TASK_SUCCESS:
      return successMessage(state, "Task has been deleted");
    case DELETE_FIELD_SUCCESS:
      return successMessage(state, "Column has been deleted");
    case DELETE_PROJECT_META_FIELD_SUCCESS:
      return successMessage(state, "Column has been deleted");
    case GET_PROJECT:
      return {
        ...state,
        projectLoading: !action.payload.disableLoader,
      };
    case GET_PROJECT_FAIL:
      return {
        ...state,
        projectLoading: false,
        getProjectFailed: true,
      };
    case CLEAN_GET_PROJECT_FAILED:
      return {
        ...state,
        getProjectFailed: false,
      };

    case CLEAR_PROJECT_BASIC_DATA:
      return {
        ...state,
        projectBasicData: null,
      };
    case SET_PROJECT_LIST:
      return {
        ...state,
        projectsList: action.payload.projectList || [],
        projectLoading: false,
      };
    case SET_PROJECT_TEMPLATES:
      return {
        ...state,
        projectTemplates: action.payload.wsProjectTemplates || [],
        projectTemplatesLoading: false,
      };

    case SET_FILTERED_PROJECT_LIST:
      return {
        ...state,
        filteredProjectsList: action.payload.filteredProjectsList || [],
        projectLoading: false,
      };

    case SET_PROJECT_COLUMNS:
      return {
        ...state,
        projectsColumns: action.payload.projectColumns || [],
      };

    case SET_PROJECT_INITIAL_COLUMN_UUIDS:
      return {
        ...state,
        projectColumnsVisibleByDefaultUuids:
          action.payload.visibleColumnUuids || [],
      };

    case GET_PROJECT_SUCCESS:
      const projectData = action.payload.data.content;
      return {
        ...state,
        projectBasicData: {
          ...projectData,
          metadata: projectData.metadata,
        },
        projectMetaFields: projectData.metadata,
        projectLoading: false,
      };

    case UPDATE_PROJECT_SUCCESS:
      const updateProjectData = action.payload.data.content;
      return {
        ...state,
        projectBasicData: {
          ...updateProjectData,
          metadata: updateProjectData.metadata,
        },
        projectMetaFields: updateProjectData.metadata,
      };

    case GET_TASKS_COUNTERS_SUCCESS: {
      const tasksCounters = action.payload.data.content;
      return {
        ...state,
        tasksCounters,
      };
    }

    case CLEAR_TASKS_COUNTERS: {
      return {
        ...state,
        tasksCounters: null,
      };
    }

    case ADD_PROJECT_META_FIELD: {
      const { uuid, name, type, rank, taskType } = action.payload;

      const newProjectField = {
        uuid,
        name,
        type,
        isVisible: true,
        rank,
        taskType,
        isRequired: false,
        value: null,
      };

      const newProjectMetaFields = [
        ...state.projectMetaFields,
        newProjectField,
      ];

      return {
        ...state,
        projectBasicData: {
          ...state.projectBasicData,
          metadata: [...newProjectMetaFields],
        },
        projectMetaFields: [...newProjectMetaFields],
        projectMetaFields_backup: state.projectMetaFields,
      };
    }

    case ADD_PROJECT_META_FIELD_SUCCESS:
      return {
        ...state,
        projectFieldAddedOrUpdated: true,
      };

    case ADD_PROJECT_META_FIELD_FAIL:
      return {
        ...state,
        projectBasicData: {
          ...state.projectBasicData,
          metadata: [...state.projectMetaFields_backup],
        },
        projectMetaFields: [...state.projectMetaFields_backup],
        projectMetaFields_backup: [],
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };

    case UPDATE_PROJECT_META_FIELD_NAME: {
      const { uuid, name } = action.payload;

      const newMetadata = state.projectMetaFields.map((column) => {
        if (column.uuid === uuid) {
          return {
            ...column,
            name,
          };
        }

        return column;
      });

      return {
        ...state,
        projectBasicData: {
          ...state.projectBasicData,
          metadata: newMetadata,
        },
        projectMetaFields: newMetadata,
      };
    }

    case UPDATE_PROJECT_META_FIELD_NAME_SUCCESS:
      return {
        ...state,
        projectFieldAddedOrUpdated: true,
      };

    case DELETE_PROJECT_META_FIELD: {
      const { uuid } = action.payload;

      return {
        ...state,
        projectMetaFields: state.projectMetaFields.filter(
          (field: ProjectMetaData) => field.uuid !== uuid,
        ),
      };
    }

    case UPDATE_PROJECT_SINGLE_SELECT_FIELD: {
      const { metaFieldId, metaFieldNameUpdated, singleSelectOptions } =
        action.payload;

      if (!state.projectBasicData) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        projectBasicData: {
          ...state.projectBasicData,
          metadata: state.projectBasicData.metadata.map((field) => {
            if (field.uuid === metaFieldId) {
              return {
                ...field,
                name: metaFieldNameUpdated,
                options: singleSelectOptions,
              };
            }
            return field;
          }),
        },
        projectMetaFields: state.projectMetaFields.map((column) => {
          if (column.uuid === metaFieldId) {
            return {
              ...column,
              name: metaFieldNameUpdated,
              options: singleSelectOptions,
            };
          }
          return column;
        }),
      };
    }

    case UPDATE_PROJECT_META_FIELD_RANK: {
      const { metaFieldUuid, metaFieldNewRank } = action.payload;

      return {
        ...state,
        projectMetaFields: state.projectMetaFields.map((column) => {
          if (column.uuid !== metaFieldUuid) {
            return column;
          }
          return {
            ...column,
            rank: metaFieldNewRank,
          };
        }),
      };
    }

    case GET_MEMBERS_LIST: {
      return {
        ...state,
        membersListLoading: true,
      };
    }

    case GET_MEMBERS_LIST_FAIL: {
      return {
        ...state,
        membersListLoading: false,
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };
    }

    case GET_MEMBERS_LIST_SUCCESS: {
      return {
        ...state,
        membersList: action.payload.data.content.members,
        activeMembersList:
          action.payload.data.content.members?.filter(
            (member: IMember) => member?.status !== "removed",
          ) ?? [],
        membersListLoading: false,
      };
    }

    case GET_PROJECTS_AUTOCOMPLETE_LIST_FAIL: {
      return {
        ...state,
        wsProjectAutocompleteList: [],
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };
    }

    case GET_PROJECTS_AUTOCOMPLETE_LIST_SUCCESS: {
      return {
        ...state,
        wsProjectAutocompleteList: action.payload.data.content.projects,
      };
    }

    case SET_MEMBERS_OPTIONS: {
      return {
        ...state,
        membersOptions: action.payload.options,
      };
    }

    case UPDATE_PROJECT_META_VALUE: {
      const { metaValue, metaFieldUuid } = action.payload;

      if (!state.projectBasicData) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        projectMetaFields_backup: state.projectBasicData.metadata,
        projectBasicData: {
          ...state.projectBasicData,
          metadata: state.projectBasicData.metadata.map((field) => {
            if (metaFieldUuid === field.uuid) {
              return {
                ...field,
                value:
                  field.type === "date"
                    ? {
                        date: metaValue,
                      }
                    : metaValue,
              };
            }
            return field;
          }),
        },
        projectDetails: {
          ...state.projectDetails,
          metadata: state.projectDetails?.metadata?.map((field) => {
            if (metaFieldUuid === field.uuid) {
              return {
                ...field,
                value:
                  field.type === "date"
                    ? {
                        date: metaValue,
                      }
                    : metaValue,
              };
            }
            return field;
          }),
        },
        projectDetails_backup: state.projectDetails,
      };
    }

    case UPDATE_PROJECT_META_VALUE_FAIL: {
      return {
        ...state,
        projectBasicData: {
          ...state.projectBasicData,
          metadata: [...state.projectMetaFields_backup],
        },
        projectMetaFields_backup: [],
        projectDetails: state.projectDetails_backup,
        projectDetails_backup: null,
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };
    }

    case RENAME_PROJECT_ON_LIST: {
      const { metaValue, projectId, metaFieldUuid } = action.payload;

      return {
        ...state,
        projectsList: state.projectsList.map((project) => {
          if (project.projectId === projectId) {
            return {
              ...project,
              projectName: metaValue,
              metadata: project.metadata.map((field) => {
                if (metaFieldUuid === field.uuid) {
                  return {
                    ...field,
                    value:
                      field.type === "date"
                        ? {
                            date: metaValue,
                          }
                        : metaValue,
                  };
                }
                return field;
              }),
            };
          }
          return project;
        }),
      };
    }

    case UPDATE_PROJECT_META_FIELD_ON_LIST:
      const { uuids, metaValue, metaFieldUuid } = action.payload;
      return {
        ...state,
        filteredProjectsList: state.filteredProjectsList.map((project) => {
          if (uuids.includes(project.projectId)) {
            return {
              ...project,
              metadata: project.metadata.map((field) => {
                if (metaFieldUuid === field.uuid) {
                  return {
                    ...field,
                    value:
                      field.type === "date"
                        ? {
                            date: metaValue,
                          }
                        : metaValue,
                  };
                }
                return field;
              }),
            };
          }
          return project;
        }),
        filteredProjectsList_backup: state.filteredProjectsList,
      };

    case UPDATE_PROJECT_META_FIELD_ON_LIST_FAIL: {
      return {
        ...state,
        filteredProjectsList: state.filteredProjectsList_backup,
        filteredProjectsList_backup: [],
        errorMessages: parseErrorMessages(
          action?.error?.response?.data?.errors,
        ),
      };
    }

    case UPDATE_PROJECT_META_FIELD_VISIBILITY: {
      const { uuid, isVisible } = action.payload;

      return {
        ...state,
        projectMetaFields: state.projectMetaFields.map((column) => {
          if (column.uuid === uuid) {
            return {
              ...column,
              isVisible,
            };
          }

          return column;
        }),
      };
    }

    case GET_PROJECTS_LIST:
      return {
        ...state,
        projectLoading: true,
      };

    case GET_PROJECT_TEMPLATES:
      return {
        ...state,
        projectTemplatesLoading: true,
      };

    case GET_FILTERED_PROJECTS_LIST:
      return {
        ...state,
        projectLoading: true,
      };

    case CLEAR_PROJECTS_LIST:
      return {
        ...state,
        projectsList: [],
        // projectsColumns: [],
        projectsListOffset: 0,
        projectLoading: true,
      };

    case GET_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        projectsList: action.payload.data.content.projects || [],
        projectsColumns: action.payload.data.content.columns || [],
        projectColumnsVisibleByDefaultUuids:
          action.payload.data.content.visibleByDefaultUuids || [],
        wsTableViews: action.payload.data.content.wsTableViews || [],
        projectLoading: false,
      };

    case GET_PROJECT_TEMPLATES_SUCCESS:
      return {
        ...state,
        projectTemplates: action.payload.data.content.wsProjectTemplates || [],
        projectTemplatesLoading: false,
      };

    case GET_FILTERED_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        filteredProjectsList: action.payload.data.content.projects || [],
        projectsColumns: action.payload.data.content.columns || [],
        wsTableViews: action.payload.data.content.wsTableViews || [],
        projectLoading: false,
      };

    case GET_PROJECTS_LIST_FAIL:
      return {
        ...state,
        projectLoading: false,
      };

    case GET_PROJECT_TEMPLATES_FAIL:
      return {
        ...state,
        projectTemplatesLoading: false,
      };

    case SET_PROJECTS_LIST_OFFSET:
      return {
        ...state,
        projectsListOffset: action.payload.offset,
      };

    case SET_SHOW_TASK:
      return {
        ...state,
        showTask: action.payload.showTask,
      };

    case SET_ACTIVE_PROJECT_ID:
      return {
        ...state,
        activeProjectId: action.payload.activeProjectId,
      };

    case SET_TASK_TYPE:
      return {
        ...state,
        taskType: action.payload.taskType,
      };

    case REMOVE_PROJECT: {
      const { uuid } = action.payload;
      return {
        ...state,
        filteredProjectsList: state.filteredProjectsList.filter(
          (project) => project.projectId !== uuid,
        ),
      };
    }

    case SET_PROJECT_LOADING:
      return {
        ...state,
        projectLoading: action.payload.projectLoading,
      };

    case SET_PROJECT_FIELD_ADDED_OR_UPDATED:
      return {
        ...state,
        projectFieldAddedOrUpdated: action.payload.projectFieldAddedOrUpdated,
      };

    case SET_EDITED_CELL_ID:
      return {
        ...state,
        editedCellId: action.payload.editedCellId,
      };

    case GET_GLOBAL_PROJECT_META_FIELD_LIST:
      return {
        ...state,
        isGlobalMetaFieldsListLoading: true,
      };

    case GET_GLOBAL_PROJECT_META_FIELD_LIST_SUCCESS:
      return {
        ...state,
        isGlobalMetaFieldsListLoading: false,
        globalMetaFieldsList: action.payload.data.content,
      };

    case GET_GLOBAL_TASK_META_FIELD_LIST:
      return {
        ...state,
        isGlobalMetaFieldsListLoading: true,
      };

    case GET_GLOBAL_TASK_META_FIELD_LIST_SUCCESS:
      return {
        ...state,
        isGlobalMetaFieldsListLoading: false,
        globalMetaFieldsList: action.payload.data.content,
      };

    case CREATE_GLOBAL_PROJECT_META_FIELD_SUCCESS:
      return {
        ...state,
        projectFieldAddedOrUpdated: true,
      };

    case CLEAR_GLOBAL_META_FIELD_LIST:
      return {
        ...state,
        globalMetaFieldsList: [],
      };

    case SET_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS: {
      const currentFields = [...state.globalMetaFieldProjectWizardFields];
      const updatedFields = action.payload.fields.map((field: Metadata) => {
        const foundCurrentField = currentFields.find(
          (currentField) => currentField.uuid === field.uuid,
        );
        return foundCurrentField ?? field;
      });
      return {
        ...state,
        globalMetaFieldProjectWizardFields: updatedFields,
      };
    }

    case CLEAR_PROJECT_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: [],
      };

    case UPDATE_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS: {
      const updatedFieldValues = [...state.globalMetaFieldProjectWizardFields];
      const foundIndex = updatedFieldValues.findIndex(
        (fieldValAndUuid) => fieldValAndUuid.uuid === action.payload.uuid,
      );

      updatedFieldValues[foundIndex] = {
        ...updatedFieldValues[foundIndex],
        value: action.payload.value,
      };

      return {
        ...state,
        globalMetaFieldProjectWizardFields: updatedFieldValues,
      };
    }

    case CLEAR_GLOBAL_PROJECT_META_FIELD_PROJECT_WIZARD_FIELDS:
      return {
        ...state,
        globalMetaFieldProjectWizardFields: [],
      };

    case GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetails: action.payload.data.content,
      };

    case CLEAR_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: null,
      };

    case SET_CREATOR_MESSAGE_PANE_OPEN:
      return {
        ...state,
        isCreatorMessagePaneOpen: action.payload.isOpen,
      };

    case SET_CREATE_SINGLE_SPLIT_PAYMENTS_MODAL:
      return {
        ...state,
        isCreateSingleSplitPaymentsModalOpen: action.payload.isOpen,
      };

    case SET_PROJECT_TABLE_VIEWS:
      return {
        ...state,
        wsTableViews: action.payload.wsTableViews,
      };

    default:
      return state;
  }
}

export default projectReducer;
