import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  clearReportState,
  getGeneratorData,
  getMembersList,
  setReportLoading,
} from "../../redux";
import ReportTemplate from "./components/ReportTemplate/ReportTemplate";
import ReportToolbar from "./components/ReportToolbar/ReportToolbar";
import { ReportTopBar } from "./components/ReportTopBar/ReportTopBar";
import "./ReportGenerator.scss";
import { formatOptions } from "./utils/sizingData";
import { loadAllTemplateImages } from "../generator-data/functions/loadAllTemplateImages";

export function ReportGenerator() {
  const generatorRef = useRef();

  const [format, setFormat] = useState(formatOptions[0]);
  const [metricsData, setMetricsData] = useState([]);
  const [coverImage, setCoverImage] = useState({});
  const [pages, setPages] = useState([]);
  const [templateApplied, setTemplateApplied] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [downloadingAdditionalData, setDownloadingAdditionalData] =
    useState(true);

  const { projectId } = useParams();

  const dispatch = useDispatch();

  const {
    mainReducer: { activeWorkspaceUuid, notifications },
    reportReducer: {
      layout,
      gridElements,
      publicationsList,
      publicationsSummary,
      creatorsList,
      projectData,
      creatorInsights,
      audienceData,
      comments,
      sentimentValues,
      downloadingData,
    },
  } = useSelector((state) => state);

  const loadAllImages = () => {
    loadAllTemplateImages(".report-template", setImagesLoaded);
  };

  useEffect(() => {
    dispatch(getGeneratorData(projectId));
    getAdditionalData();

    return () => {
      dispatch(clearReportState());
    };
  }, []);

  const getAdditionalData = async () => {
    await dispatch(getMembersList(activeWorkspaceUuid));
    setDownloadingAdditionalData(false);
  };

  useEffect(() => {
    if (downloadingData || downloadingAdditionalData) return;

    const layoutLength =
      layout?.filter((item) => !item.i.includes("separator")).length - 1;

    if (gridElements.length === layoutLength && layoutLength > 3) {
      if (!imagesLoaded) {
        loadAllImages();
        // return;
      }

      setTimeout(() => {
        dispatch(setReportLoading(false));
      }, 750);
    }
  }, [gridElements, layout, downloadingData, downloadingAdditionalData]);

  return (
    <div
      ref={generatorRef}
      className="report-generator template-generator"
      style={{
        marginTop: notifications.length * 44,
        height: `calc(100vh - ${notifications.length * 44}px)`,
      }}
    >
      <ReportTopBar
        generatorRef={generatorRef}
        data={projectData}
        format={format}
        wsWorkspaceUuid={activeWorkspaceUuid}
      />

      <ReportToolbar
        showcaseRef={generatorRef}
        data={projectData}
        format={format}
        setFormat={setFormat}
        metricsData={metricsData}
        setMetricsData={setMetricsData}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
      />

      <div className="report-generator__template-wrapper">
        <ReportTemplate
          data={projectData}
          format={format.value}
          pages={pages}
          setPages={setPages}
          metricsData={metricsData}
          setMetricsData={setMetricsData}
          // progressValue={templatesToGenerate.length}
          // progressTarget={multipleLayouts.length}
          templateApplied={templateApplied}
          setTemplateApplied={setTemplateApplied}
          coverImage={coverImage?.value}
          creatorsList={creatorsList}
          publicationsList={publicationsList}
          publicationsSummary={publicationsSummary}
          creatorInsights={creatorInsights}
          audienceData={audienceData}
          comments={comments}
          sentimentValues={sentimentValues}
        />
      </div>
    </div>
  );
}
