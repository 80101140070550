import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import axios, { AxiosResponse } from "axios";

import { dropNotification, sendNotification } from "src/redux/main/mainActions";
import { API_URLS } from "src/utils/API_URLS";
import { AppConfig } from "../config/AppConfig";

const CHECK_INTERVAL = 30 * 1000; // 30 seconds
const MAX_FAILURES = 1;

function getOnlineStatus() {
  return typeof navigator !== "undefined" &&
    typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
}

export default function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(getOnlineStatus());
  const [consecutiveFailures, setConsecutiveFailures] = useState(0);

  const prevIsOnline = useRef(isOnline);

  const dispatch = useDispatch();

  const sendOnlineNotification = () => {
    dispatch(sendNotification({ type: "online", id: "online" }));
    dispatch(dropNotification("offline"));
  };

  const sendOfflineNotification = () => {
    dispatch(sendNotification({ type: "offline", id: "offline" }));
    dispatch(dropNotification("online"));
  };

  const goOnline = () => setIsOnline(true);

  const goOffline = () => setIsOnline(false);

  useEffect(() => {
    if (AppConfig.isLocalEnv()) {
      // disable health check in the local environment
      return;
    }

    const checkNetworkStatus = async () => {
      try {
        const response: AxiosResponse = await axios(API_URLS.healthCheck);

        if (response.status === 200) {
          setConsecutiveFailures(0);
          goOnline();
        }
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.message.includes("Network Error")
        ) {
          setConsecutiveFailures((prev) => prev + 1);
          if (consecutiveFailures >= MAX_FAILURES) {
            goOffline();
          }
        }
      }
    };

    const intervalId = setInterval(checkNetworkStatus, CHECK_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [consecutiveFailures]);

  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  useEffect(() => {
    if (!isOnline && prevIsOnline.current) {
      sendOfflineNotification();
    }

    if (isOnline && !prevIsOnline.current) {
      sendOnlineNotification();
    }

    prevIsOnline.current = isOnline;
  }, [isOnline]);

  return null;
}
