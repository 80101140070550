import moment from "moment";
import { ITask } from "src/redux/task/taskReducer";
import { IntlShape } from "react-intl";
import { getFullDate } from "../../utils/methods";
import { IMember } from "../project/ProjectTypes";
import { getDateLabel } from "./getDateLabel";
import { MetaFieldType } from "./getMetaFieldTypeOptions";

export const getColumnTextLabel = (
  columnType: MetaFieldType,
  colData: any,
  values: any,
  membersList: Array<IMember>,
  tasksAutocomplete: ITask[],
  intl: IntlShape,
) => {
  switch (columnType) {
    case MetaFieldType.Member:
      const memberData = membersList.find(
        (member: IMember) => member.id === colData.value,
      );
      return memberData?.name;

    case MetaFieldType.Date:
      const formattedValue = getDateLabel(
        "en-EN",
        moment(colData.value),
        false,
        intl,
      );

      return typeof formattedValue?.date === "string"
        ? formattedValue?.date
        : getFullDate(formattedValue?.date.toDate());

    case MetaFieldType.Creator:
    case MetaFieldType.Content:
    case MetaFieldType.PublicationRelated: {
      const foundTask = tasksAutocomplete.find(
        (task) => task.taskId === colData.value,
      );

      return foundTask?.title;
    }

    case MetaFieldType.SingleSelect:
    case MetaFieldType.MultiSelect:
    case MetaFieldType.AggregatedSelect:
      return colData.name;

    default:
      return values.find((value: string) => {
        if (value === colData.value) {
          return colData.name;
        }
      });
  }
};
