import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./DictionaryElementFilterSelect.scss";

import { useSelector } from "react-redux";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";

import { translateMessage } from "src/app/methods/translateMessage";
import { getDictionaryIcon } from "src/app/methods/getDictionaryTypes";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function DictionaryElementFilterSelect({ col, sendFilter, filters }) {
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);

  const intl = useIntl();
  const {
    dictionaryReducer: { dictionaryAutoCompletes },
    mainReducer: { identity },
  } = useSelector((state) => state);

  const { wsDictionaryUuid } = col.data;

  const list =
    dictionaryAutoCompletes[`dictionaryType_${wsDictionaryUuid}`] || [];

  const filterOption = (candidate, input) => {
    return (
      candidate.data.__isNew__ ||
      candidate.data.name.toUpperCase().includes(input.toUpperCase())
    );
  };

  useEffect(() => {
    const emptyOption = {
      value: null,
      label: (
        <span className="image-select-option dictionary-element-option">
          {getDictionaryIcon(identity, wsDictionaryUuid)}
          {translateMessage({
            intl,
            id: "ws_blanks",
            defaultMessage: "Blanks",
          })}
        </span>
      ),
      name: "Blanks",
    };

    const listOptions =
      list.map((item) => ({
        value: item.uuid,
        label: (
          <span className="image-select-option dictionary-element-option">
            {getDictionaryIcon(identity, wsDictionaryUuid)}
            {item.title}
          </span>
        ),
        name: item.title,
      })) || [];

    const newOptions = [emptyOption, ...listOptions];
    setOptions(newOptions);
    setValue(
      filters[col.uuid]?.value.map((v) =>
        newOptions.find((o) => o.value === v),
      ) || [],
    );
  }, [list]);

  return (
    <CustomSelect
      className="projects-table-filters__member-select ws-react-select ws-react-select--with-tiles"
      value={value}
      onChange={(nv) => {
        setValue(nv);
        sendFilter(
          col.uuid,
          nv.map((v) => v.value),
        );
      }}
      placeholder={translateMessage({
        intl,
        id: "ws_all",
        defaultMessage: "All",
      })}
      options={options}
      isMulti
      withTiles
      openMenuOnFocus={false}
      isSearchable
      filterOption={filterOption}
      data-qa={col.name}
    />
  );
}
