import {
  CSSProperties,
  DispatchWithoutAction,
  Dispatch,
  SetStateAction,
} from "react";

import {
  GridCell,
  ImageWindowLoader,
  Item,
  Rectangle,
  Theme,
} from "@glideapps/glide-data-grid";
import { WrappedComponentProps } from "react-intl";

import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import {
  Column,
  ColumnSettings,
  MetaDataValue,
  Row,
} from "src/redux/dictionary/types";
import { SortDirection } from "src/types";
import { tableDataType } from "../Table/Table";

export type GlideDataGridProps = {
  columnsData: Column[];
  rowsData: Row[];
  isLoading: boolean;
  context: GlideDataGridContext;
  handleRowNameClick?: (objectUuid: string) => void;
  filtersAndSortingOptions?: GlideDataGridFiltersAndSortingOptions;
  filtersAndSortingOptionsDispatch?: DispatchWithoutAction;
  showSearch: boolean;
  setShowSearch: Dispatch<SetStateAction<boolean>>;
  openGlideElement: (uuid: string) => string;
  totalRowsCount: number;
} & WrappedComponentProps<"intl">;

export type GlideDataGridContext =
  | tableDataType.Dictionary
  | tableDataType.CreatorDatabase;

export enum ExtraGlideDataGridMetaFieldTypesEnum {
  Title = "title",
  Action = "action",
  Empty = "empty",
}

type HoveredCellSettingsType =
  | MetaFieldType
  | ExtraGlideDataGridMetaFieldTypesEnum.Title
  | ExtraGlideDataGridMetaFieldTypesEnum.Action
  | null;

export type HoveredCellSettings = {
  value: MetaDataValue | null;
  type: HoveredCellSettingsType;
  style: CSSProperties;
  cell: Item;
  bounds: Rectangle;
  readOnly: boolean;
};

export type HoveredHeaderSettings = {
  value: string | null;
  style: CSSProperties;
  bounds: Rectangle;
  uuid: string;
};

export type TooltipSettings = {
  value: string | null;
  type: MetaFieldType | "header" | null;
  bounds: Rectangle;
};

export type DropdownType =
  | MetaFieldType.Date
  | MetaFieldType.SingleSelect
  | MetaFieldType.MultiSelect
  | MetaFieldType.AggregatedSelect
  | MetaFieldType.Member
  | MetaFieldType.DictionaryElement
  | MetaFieldType.File
  | MetaFieldType.Project
  | ExtraGlideDataGridMetaFieldTypesEnum.Action
  | null;

export type DropdownSettings = {
  value: MetaDataValue | null;
  options?: ColumnSettings;
  type: DropdownType;
  cell: Item;
  bounds: Rectangle;
  readOnly: boolean;
};

export type GlideDataGridContextData = DictionaryContextData;

export type GlideDataGridFiltersAndSortingOptions = {
  filters: any; // this can be either object or array
  sortBy: string | null;
  sort: SortDirection.Ascending | SortDirection.Descending;
};

type DictionaryContextData = {
  type: tableDataType.Dictionary;
  wsDictionaryElementName: string;
};

export type DrawCellArgs = {
  ctx: CanvasRenderingContext2D;
  cell: GridCell;
  theme: Theme;
  rect: Rectangle;
  col: number;
  row: number;
  hoverAmount: number;
  hoverX: number | undefined;
  hoverY: number | undefined;
  highlighted: boolean;
  imageLoader: ImageWindowLoader;
};

export type StaticCellSettings = {
  numberOfFiles: number;
  style: CSSProperties;
};
