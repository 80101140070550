import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import "./SingleSelectFilterOptions.scss";

import { translateMessage } from "src/app/methods/translateMessage";
import { useSelector } from "react-redux";

import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import SimpleExpander from "src/app/components/SimpleExpander/SimpleExpander";
import { uuidv7 as uuid } from "uuidv7";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

export function SingleSelectFilterOptions({
  col,
  sendFilter,
  displayNotSetOption,
  filters,
}) {
  const intl = useIntl();

  const { selectDataSetList } = useSelector(
    (state) => state.selectDataSetReducer,
  );

  const feed = useMemo(() => {
    let options = col.data.singleSelectOptions;

    if (col.data.wsSelectDataSetUuid && selectDataSetList) {
      options =
        selectDataSetList[`dataSetType_${col.data.wsSelectDataSetUuid}`] || [];
    }

    return (
      options?.sort((a, b) => {
        const containsA = filters[col.uuid]?.value?.includes(a.value);
        const containsB = filters[col.uuid]?.value?.includes(b.value);
        if (containsA && containsB) return a.name.localeCompare(b.name);
        if (containsA) return -1;
        if (containsB) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      }) || []
    );
  }, [filters, col.data.singleSelectOptions, selectDataSetList]);

  const feed1 = feed.slice(0, 5);
  const feed2 = feed.slice(5, feed.length);

  const onChange = (e, option) => {
    if (e.target.checked) {
      sendFilter(col.uuid, [...(filters[col.uuid]?.value || []), option.value]);
    } else {
      sendFilter(col.uuid, [
        ...filters[col.uuid]?.value.filter((f) => f !== option.value),
      ]);
    }
  };

  const notSetUuid = uuid();

  return (
    <div className="single-select-filter-options">
      {displayNotSetOption && (
        <CustomCheckbox
          id={notSetUuid}
          name={notSetUuid}
          label={<IDHFormattedMessage id="ws_blanks" defaultMessage="Blanks" />}
          onChange={(e) =>
            onChange(e, {
              name: "Blanks",
              value: null,
              color: "#fff",
              backgroundColor: "#fff",
              rank: "aaaaaaaaaaaaaaa",
              outOfOrder: true,
            })
          }
          checked={!!filters[col.uuid]?.value.includes(null)}
          data-qa="Blanks"
        />
      )}
      {feed1.map((option) => (
        <CustomCheckbox
          key={option.value}
          id={option.value}
          name={option.name}
          label={option.name}
          onChange={(e) => onChange(e, option)}
          checked={!!filters[col.uuid]?.value.includes(option.value)}
          data-qa={option.name}
        />
      ))}
      {feed2.length > 0 && (
        <SimpleExpander
          contentClassName="single-select-filter-options__expander__content"
          expandText={`${translateMessage({
            intl,
            id: "ws_more",
            defaultMessage: "More",
          })} (${feed2.length})`}
        >
          {feed2.map((option) => (
            <CustomCheckbox
              key={option.value}
              id={option.value}
              name={option.name}
              label={option.name}
              onChange={(e) => onChange(e, option)}
              checked={!!filters[col.uuid]?.value.includes(option.value)}
              data-qa={option.name}
            />
          ))}
        </SimpleExpander>
      )}
    </div>
  );
}
