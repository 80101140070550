import React from "react";
import { useIntl } from "react-intl";

import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import SimpleExpander from "src/app/components/SimpleExpander/SimpleExpander";
import { translateMessage } from "src/app/methods/translateMessage";

interface FilterOption {
  name: string;
  value: string;
}

interface SelectedFilters {
  selections: Array<string> | null;
}

interface FilterOptionsProps {
  options: FilterOption[];
  selectedFilters: SelectedFilters;
  handleCheckboxChange: (key: string) => void;
}

export default function FilterOptions({
  options,
  selectedFilters,
  handleCheckboxChange,
}: FilterOptionsProps) {
  const intl = useIntl();

  const options1 = options.slice(0, 5);
  const options2 = options.slice(5, options.length);
  return (
    <div className="single-select-filter-options">
      {options1.map((option) => {
        return (
          <CustomCheckbox
            id={option.value}
            name={option.name}
            key={option.value}
            label={option.name}
            onChange={() => {
              handleCheckboxChange(option.value);
            }}
            checked={selectedFilters?.selections?.includes(option.value)}
            data-qa={option.name}
          />
        );
      })}
      {options2.length > 0 && (
        <SimpleExpander
          contentClassName="single-select-filter-options__expander__content"
          expandText={`${translateMessage({
            intl,
            id: "ws_more",
            defaultMessage: "More",
          })} (${options2.length})`}
        >
          {options2.map((option) => {
            return (
              <CustomCheckbox
                id={option.value}
                name={option.name}
                key={option.value}
                label={option.name}
                onChange={() => {
                  handleCheckboxChange(option.value);
                }}
                checked={selectedFilters?.selections?.includes(option.value)}
                data-qa={option.name}
              />
            );
          })}
        </SimpleExpander>
      )}
    </div>
  );
}
