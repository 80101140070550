import React from "react";
import "./EmptyDictionaryTab.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as EmptyDictionaryTabImage } from "src/images/empty-dictionary-tab.svg";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { Button } from "src/app/components/Button/Button";

interface Props {
  showDictionaryModal: () => void;
  dictionaryName: string;
}

export function EmptyDictionaryTab(props: Props) {
  const { showDictionaryModal, dictionaryName } = props;

  return (
    <AnimatedDiv className="empty-dictionary-tab">
      <EmptyDictionaryTabImage />
      <div className="empty-dictionary-tab__text">
        <IDHFormattedMessage
          id={`ws_no_${dictionaryName}_has_been_assigned_to_this_creator`}
          defaultMessage={`No ${dictionaryName} has been assigned to this creator.\n Add new or assign already added ${dictionaryName}.`}
        />
      </div>

      <Button variant="blue" size="small" onClick={showDictionaryModal}>
        <PlusIcon />
        <IDHFormattedMessage
          id={`ws_add_${dictionaryName}`}
          defaultMessage={`Add ${dictionaryName}`}
        />
      </Button>
    </AnimatedDiv>
  );
}
