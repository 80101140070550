import { valueExists } from "src/app/methods/valueExists";
import { SetWorkspaceTaskFiltersParams } from "./workspaceTaskFiltersActions";
import * as actionTypes from "./workspaceTaskFiltersTypes";

export interface WorkspaceTaskFiltersReducerState {
  filters: Record<string, { value: any }>;
}

const initialState: WorkspaceTaskFiltersReducerState = {
  filters: {},
};

function workspaceTaskFiltersReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.SET_WORKSPACE_TASK_FILTERS: {
      const { uuid, value }: SetWorkspaceTaskFiltersParams = action.payload;
      const doesValueExist = valueExists(value);

      let newFilters = { ...state.filters };

      if (doesValueExist) {
        newFilters = { ...state.filters, [uuid]: { value } };
      } else {
        delete newFilters[uuid];
      }

      return {
        ...state,
        filters: newFilters,
      };
    }
    case actionTypes.CLEAR_WORKSPACE_TASK_FILTERS:
      return {
        ...state,
        filters: {},
      };
    default:
      return state;
  }
}

export default workspaceTaskFiltersReducer;
