import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import CustomInput from "src/app/components/CustomInput/CustomInput";
import { searchObjectsByName } from "src/app/components/DictionarySortDropdown/DictionarySortDropdown";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { DropdownPopper } from "src/app/components/DropdownPopper/DropdownPopper";
import SortFieldsButton from "src/app/dropdowns/SortFieldsDropdown/SortFieldsButton";
import { translateMessage } from "src/app/methods/translateMessage";
import { Column } from "src/redux/dictionary/types";
import { ReactComponent as TickIcon } from "src/images/tick.svg";
import { setActiveSortingColumn } from "src/redux";

interface WorkspaceTasksSortDropdownProps {
  columns: Column[];
  sortBy: string | null;
}

function WorkspaceTasksSortDropdown({
  columns,
  sortBy,
}: WorkspaceTasksSortDropdownProps) {
  const dropdownRef = useRef<{ forceVisible: (state: boolean) => void }>();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [value, setValue] = useState("");

  let displayOptions: Column[] = [];
  if (!value) {
    displayOptions = columns;
  } else {
    displayOptions = searchObjectsByName(value, columns);
  }

  const foundActiveSortingColumn = displayOptions.find(
    (option) => option.uuid === sortBy,
  );

  return (
    // @ts-ignore
    <DropdownPopper
      overlay={
        <DropdownMenu isOpen className="sort-dictionary-dropdown__menu">
          <CustomInput
            type="text"
            placeholder={translateMessage({
              intl,
              id: "ws_enter_field_name",
              defaultMessage: "Enter field name",
            })}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="dropdown__menu-list">
            {displayOptions.map((f) => {
              const active = sortBy === f.uuid;
              return (
                <DropdownMenuItem
                  key={f.uuid}
                  onClick={() => {
                    const activeSortBy = active ? null : f.uuid;
                    dispatch(setActiveSortingColumn(activeSortBy));

                    if (dropdownRef.current) {
                      dropdownRef.current.forceVisible(false);
                    }
                  }}
                  data-qa={`sort-option-${f.name}`}
                >
                  {f.name}
                  {active && <TickIcon />}
                </DropdownMenuItem>
              );
            })}
          </div>
        </DropdownMenu>
      }
      ref={dropdownRef}
      placement="bottom-end"
    >
      <SortFieldsButton
        active={typeof sortBy === "string" && sortBy !== ""}
        activeSortingColumnName={foundActiveSortingColumn?.name}
      />
    </DropdownPopper>
  );
}

export default WorkspaceTasksSortDropdown;
