import React, { useMemo, useState } from "react";
import "./AssignDictionaryModal.scss";

import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalText, ModalTitle } from "src/app/components/Modal/Modal";
import { uuidv7 as uuid } from "uuidv7";
import { translateMessage } from "src/app/methods/translateMessage";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import {
  createDictionaryElements,
  setDictionaryElementMetaValue,
} from "src/redux";
import { SelectOption } from "src/app/modals/CreateNewProjectModal/types";
import { isStringUuid, sleep } from "src/utils/methods";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { DictionaryItem, DictionaryTabElement } from "../../DictionaryTab";

interface Props {
  onClose: () => void;
  dictionaryData: DictionaryItem;
  assignedDictionaryElements: DictionaryTabElement[];
  allDictionaryElements: DictionaryTabElement[];
  getLists: () => void;
  dictionaryName: string;
}

export function AssignDictionaryModal(props: Props) {
  const {
    onClose,
    dictionaryData,
    assignedDictionaryElements,
    allDictionaryElements,
    getLists,
    dictionaryName,
  } = props;

  const [value, setValue] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );
  const globalTaskDetails = useSelector(
    (state: RootState) => state.creatorDatabaseReducer.globalTaskDetails,
  );

  const dispatch = useDispatch();

  const options = useMemo(() => {
    const assignedDictionaryElementsIds = assignedDictionaryElements.map(
      (item) => item.uuid,
    );
    const filteredDictionaryElements = allDictionaryElements.filter(
      (item) => !assignedDictionaryElementsIds.includes(item.uuid),
    );
    return filteredDictionaryElements.map((item) => ({
      value: item.uuid,
      label: item.title,
    }));
  }, [assignedDictionaryElements, allDictionaryElements]);

  const intl = useIntl();

  const addNewElements = () => {
    const newElements = value.filter((item) => !isStringUuid(item.value));

    if (!newElements?.length) return;

    const elementsData = newElements.map((el) => {
      const elementUuid = uuid();

      return {
        name: el.label,
        uuid: elementUuid,
        defaultMetaFieldsValues: [
          {
            wsDictionaryMetaFieldType: MetaFieldType.GlobalCreator,
            value: [globalTaskDetails.uuid],
          },
        ],
      };
    });

    dispatch(
      createDictionaryElements(
        activeWorkspaceUuid,
        dictionaryData.wsDictionaryUuid,
        elementsData,
      ),
    );
  };

  const assignExistingElements = () => {
    const existingElementsIds = value
      .filter((item) => isStringUuid(item.value))
      .map((item) => item.value);

    if (!existingElementsIds?.length) return;

    dispatch(
      setDictionaryElementMetaValue(
        existingElementsIds,
        dictionaryData.wsDictionaryGlobalCreatorMetaFieldUuid,
        globalTaskDetails.uuid,
        true,
      ),
    );
  };

  const onConfirmClick = async () => {
    setLoading(true);
    await addNewElements();
    await assignExistingElements();
    await sleep(300);
    setLoading(false);
    getLists();
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      className="assign-dictionary-modal"
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      confirmButtonLoading={loading}
      confirmButtonDisabled={!value?.length}
      onConfirmClick={onConfirmClick}
      confirmButtonText={
        <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
      }
    >
      <ModalTitle>
        <IDHFormattedMessage
          id={`ws_add_${dictionaryName}`}
          defaultMessage={`Add ${dictionaryName}`}
        />
      </ModalTitle>

      <ModalText>
        <IDHFormattedMessage
          id={`ws_you_can_add_a_new_${dictionaryName}_or_assign`}
          defaultMessage={`You can add a new ${dictionaryName} or assign an existing one.`}
        />
      </ModalText>

      <CustomSelect
        value={value}
        onChange={setValue}
        options={options}
        isSearchable
        isMulti
        creatable
        placeholder={translateMessage({
          intl,
          id: `ws_enter_name_or_select_${dictionaryName}`,
          defaultMessage: `Enter name or select ${dictionaryName}`,
        })}
      />
    </Modal>
  );
}
