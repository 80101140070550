import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import "./ChooseActionDropdown.scss";

import classNames from "classnames";

import PublicationPlaceholder from "src/images/publication-placeholder-grey-sm.svg";
import DropdownPortal, {
  DropdownPortalMenu,
} from "src/app/components/DropdownPortal";
import { useScrollLock } from "src/app/methods/useScrollLock";
import { getImageSource } from "src/utils/methods";
import { ITask } from "src/redux/task/taskReducer";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

import ContentPlaceholder from "../../../images/content-placeholder.svg";
import CreatorPlaceholder from "../../../images/creator-placeholder.svg";
import PlaceholderImage from "../../../images/placeholder-image.svg";

export interface TaskOption {
  taskId: string;
  title: string;
  cover: string | null;
}

interface Props {
  children: React.ReactNode;
  value?: any;
  data: ITask[];
  chooseAction: Dispatch<SetStateAction<any>>;
  placeholder?: string;
  fieldType: string;
  toRight?: boolean;
  disabled?: boolean;
}

const ChooseActionDropdown: React.FC<Props> = (props) => {
  const {
    children,
    value,
    data,
    chooseAction,
    placeholder,
    fieldType,
    toRight,
    disabled,
  } = props;

  const { lockScroll, unlockScroll } = useScrollLock();

  const [options, setOptions] = useState<any>([]);
  const [action, setAction] = useState<any>({});
  const [forceOpen, setForceOpen] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(false);

  const ddRef = useRef(null);

  const getPlaceholder = () => {
    switch (fieldType) {
      case "creator":
        return CreatorPlaceholder;
      case "publicationRelated":
      case "publication":
        return PublicationPlaceholder;
      case "content":
        return ContentPlaceholder;
      default:
        return PlaceholderImage;
    }
  };

  useEffect(() => {
    setOptions(
      data.map((item) => {
        return {
          label: (
            <span
              key={item.taskId}
              className={classNames("action-field", "action-field-option", {
                "action-field--circle": fieldType === "creator",
              })}
            >
              <div
                className="action-field__placeholder"
                style={{ backgroundImage: `url(${getPlaceholder()})` }}
              >
                <div
                  style={{
                    backgroundImage: `url(${getImageSource(
                      item?.cover,
                      "tiny",
                    )})`,
                  }}
                  className="action-field__image"
                />
              </div>
              {item.title}
            </span>
          ),
          value: { id: item.taskId, title: item.title },
        };
      }),
    );
  }, [data]);

  useEffect(() => {
    const actionValue = options.find(
      (item: any) => item.value.id === value?.metaFieldValue,
    );

    if (actionValue) {
      setAction(actionValue);
    } else {
      setAction({});
    }
  }, [options, value]);

  const filterOption = (candidate: any, input: any) => {
    return (
      candidate.data.__isNew__ ||
      candidate.value.title.toUpperCase().includes(input.toUpperCase())
    );
  };

  const changeAction = (newValue: any) => {
    const newAction = data?.find((item) => item.taskId === newValue.value.id);

    const newActionObject = value?.metaFieldId
      ? {
          ...newAction,
          metaFieldId: value.metaFieldId,
          metaFieldValue: value.metaFieldValue,
          metaFieldName: value.metaFieldName,
        }
      : newAction;

    chooseAction(newActionObject);
    setForceOpen(false);
  };

  const menu = (
    <DropdownPortalMenu
      className="choose-person-dropdown-menu"
      onMouseLeave={(e: any) => e.stopPropagation()}
    >
      <CustomSelect
        value={action}
        onChange={(newValue: any) => {
          setAction(newValue);
          changeAction(newValue);
        }}
        isSearchable
        options={options}
        filterOption={filterOption}
        menuIsOpen
        forceOpen={forceOpen}
        forceFocus={isVisible}
        onMenuOpen={() => null}
        onMenuClose={() => unlockScroll()}
        openOnFocus={false}
      />
    </DropdownPortalMenu>
  );

  return (
    <DropdownPortal
      className="select-dropdown"
      toRight={toRight}
      overlay={menu}
      onVisibleChange={(v: boolean) => {
        setVisible(v);
        if (v) {
          lockScroll();
        }
      }}
      ddRef={ddRef}
      disabled={disabled}
    >
      <span>{children}</span>
    </DropdownPortal>
  );
};

export default ChooseActionDropdown;
