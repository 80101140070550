import React from "react";
import "./CoverBox.scss";

import CustomImage from "../../../../../components/CustomImage/CustomImage";
import { useGetMetaField } from "../../../../../methods/useGetMetaField";

import CreatorPlaceholder from "../../../../../../images/creator-placeholder.svg";
import PublicationPlaceholder from "../../../../../../images/publication-placeholder2.svg";

import SocialProfileIcons from "../../../../../components/IconsWithStatus/SocialProfileIcons/SocialProfileIcons";
import { getImageSource } from "../../../../../../utils/methods";

export function CoverBox(props) {
  const { data, creatorsList, format } = props;

  const src = getImageSource(data?.cover, "big");

  const creatorId = useGetMetaField("creator", data?.metadata)?.value;

  const creator = creatorsList?.find((item) => item.taskId === creatorId);

  return (
    <div
      className="cover-box"
      style={{ top: `-${format.rowHeight / 2 - 3}px` }}
    >
      <div className="cover-box__wrapper">
        {src && (
          <CustomImage
            className="cover-box__wrapper-image"
            src={src}
            fallbackImageSrc={PublicationPlaceholder}
          />
        )}
        {creator && (
          <div className="cover-box__creator">
            <CustomImage
              className="cover-box__creator-avatar"
              src={getImageSource(creator?.cover, "tiny")}
              fallbackImageSrc={CreatorPlaceholder}
            />
            {creator.title}
            <SocialProfileIcons data={creator.socialProfiles} />
          </div>
        )}
      </div>
    </div>
  );
}
