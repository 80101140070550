import React, {
  MouseEvent,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from "react";
import "./FileField.scss";

import { useDispatch, useSelector } from "react-redux";

import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { RootState } from "src/redux/reducers";
import classNames from "classnames";
import Attachment from "../../Task/Attachments/Attachment";
import { showToast } from "../../methods/showToast";

import { MetaFileData } from "../MultipleFileField/MultipleFileField";

import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";

import { ReactComponent as CrossIcon } from "../../../images/cross.svg";

import { FilesDropdownPortal } from "./FilesDropdown";
import { getExtensionFromName, openUrlInNewTab } from "../../../utils/methods";
import { tableDataType } from "../../components/Table/Table";
import { deleteProjectFile, uploadProjectFile } from "./projectFunctions";
import { deleteTaskFile, uploadTaskFile } from "./taskFunctions";
import { AttachmentPlacement, TaskType } from "../../../types";
import { getFileFieldIcon } from "./utils";

interface Props {
  files: MetaFileData[];
  uuid: string;
  objectId: string; // objectId is projectId for dataType Project
  dataType: tableDataType;
  readOnly?: boolean;
  projectId?: string;
}

export const FileField: React.FC<Props> = (props) => {
  const { files, uuid, objectId, readOnly, dataType, projectId } = props;

  const fieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [filesData, setFilesData] = useState<MetaFileData[]>([]);
  const [loading, setLoading] = useState(false);
  const [fieldWidth, setFieldWidth] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<{
    assetId: string;
    assetName: string;
  }>({ assetId: "", assetName: "" });

  const dispatch = useDispatch();

  const {
    mainReducer: { activeWorkspaceUuid },
    projectReducer: { taskType },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    setFilesData(files);
  }, [files]);

  const refreshFieldWidth = () => {
    if (fieldRef?.current) {
      setFieldWidth(fieldRef.current?.offsetWidth);
    }
  };

  useEffect(() => {
    refreshFieldWidth();
  }, [filesData, fieldRef.current]);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);

      switch (dataType) {
        case tableDataType.Project:
          await uploadProjectFile(
            e,
            dispatch,
            uuid,
            objectId,
            activeWorkspaceUuid,
            setFilesData,
          );
          break;

        case tableDataType.Task:
          await uploadTaskFile(
            e,
            dispatch,
            uuid,
            activeWorkspaceUuid,
            setFilesData,
            projectId!,
            objectId,
          );
          break;
        default:
          break;
      }
    } catch (error) {
      showToast("error", "Error", "Something went wrong.");
    } finally {
      setLoading(false);
      refreshFieldWidth();
      if (inputRef?.current) {
        inputRef.current.value = "";
      }
    }
  };

  const deleteFile = async (assetId: string) => {
    switch (dataType) {
      case tableDataType.Project:
        deleteProjectFile(
          assetId,
          uuid,
          dispatch,
          objectId,
          setFilesData,
          files,
        );
        break;

      case tableDataType.Task:
        deleteTaskFile(
          assetId,
          uuid,
          dispatch,
          projectId!,
          setFilesData,
          files,
          objectId,
          taskType,
        );
        break;
      default:
        showToast("error", "Error", "Could not delete file.");
        break;
    }
  };

  //
  // DOM
  //

  if (!filesData?.length) {
    return readOnly ? (
      <span>-</span>
    ) : (
      <div className="ws-table__empty-field ws-table__empty-field--file">
        <PlusIcon />
        <input ref={inputRef} type="file" multiple onChange={uploadFile} />
      </div>
    );
  }

  const fileExtension = getExtensionFromName(filesData[0].name);

  const getPreviewFileType = (data: any) => {
    switch (fileExtension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "mp4":
        return (
          <Attachment
            data={data}
            place={AttachmentPlacement.WsTable}
            taskType={TaskType.Creator}
          />
        );
      default:
        return getFileFieldIcon(fileExtension);
    }
  };
  const onFileClick = (event: MouseEvent) => {
    switch (fileExtension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "mp4":
        event.stopPropagation();
        break;
      default:
        return openUrlInNewTab(filesData[0].publicPreviewUrl);
    }
  };

  return (
    <div
      ref={fieldRef}
      className={classNames("file-field", {
        "file-field--one-element": filesData.length === 1,
      })}
    >
      {filesData.length === 1 ? (
        <div className="file-field__value-wrapper">
          <div
            className="file-field__preview-wrapper"
            onClick={(e) => onFileClick(e)}
          >
            {getPreviewFileType(filesData[0])}
          </div>
          <div className="file-field__value">
            <a
              href={filesData[0].publicDownloadUrl}
              download
              onClick={(e) =>
                !filesData[0].publicDownloadUrl && e.preventDefault()
              }
              className="file-field__value-title"
            >
              {filesData[0].name}
            </a>
            {!readOnly && !loading && (
              <div
                className="file-field__buttons"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="file-field__buttons-upload">
                  <PlusIcon />
                  <input
                    ref={inputRef}
                    type="file"
                    multiple
                    onChange={uploadFile}
                  />
                </span>
                <span
                  className="file-field__buttons-delete"
                  onClick={() => {
                    setShowConfirmationModal(true);
                    setFileToDelete({
                      assetId: String(filesData[0].assetId),
                      assetName: filesData[0].name,
                    });
                  }}
                >
                  <CrossIcon />
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="file-field__value">
          <FilesDropdownPortal
            filesData={filesData}
            deleteFile={deleteFile}
            uploadFile={uploadFile}
            readOnly={readOnly}
          />
          {!readOnly && !loading && (
            <div
              className="file-field__buttons"
              style={{ left: `${fieldWidth}px` }}
            >
              <span className="file-field__buttons-upload">
                <PlusIcon />
                <input
                  ref={inputRef}
                  type="file"
                  multiple
                  onChange={uploadFile}
                />
              </span>
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className="file-field__loader">
          <span className="file-field__loader-progress" />
        </div>
      )}

      {showConfirmationModal && (
        <RemoveModal
          onClose={() => setShowConfirmationModal(false)}
          objectNames={[fileToDelete.assetName ?? ""]}
          removeFunction={() => {
            setShowConfirmationModal(false);
            deleteFile(fileToDelete.assetId);
          }}
        />
      )}
    </div>
  );
};
