import "./Table.scss";

export enum tableDataType {
  Project = "project",
  GlobalProject = "global-project",
  Task = "task",
  GlobalTask = "global-task",
  ProjectDetails = "project details",
  RecruitmentForm = "recruitment form",
  Dictionary = "dictionary",
  CreatorDatabase = "creatorDatabase",
  GlobalMetaFieldProjectWizardFields = "global-metafield-project-wizard-fields",
  TemplateFields = "templateFields",
  CreatorMessage = "creatorMessage",
}

export enum taskAvatarShape {
  Circle,
  Square,
}
