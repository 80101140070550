import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment/moment";
import axios, { AxiosResponse } from "axios";

import { DropdownPortalMenu } from "src/app/components/DropdownPortal";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { RootState } from "src/redux/reducers";
import { getDictionaryIcon } from "src/app/methods/getDictionaryTypes";
import { getSelectDataSetOptions } from "src/redux/select-data-set/selectDataSetActions";
import {
  DictionaryElementsFilter,
  Metadata,
} from "src/app/Task/Subtask/Subtask";
import { API_URLS } from "src/utils/API_URLS";
import { DictionaryList, Row } from "src/redux/dictionary/types";
import responseCodes from "src/utils/responseCodes";
import { SelectDictionaryOption } from "./DictionaryElementSelector";
import { skeletonOptions } from "./utils";
import { MetaFieldType } from "../../methods/getMetaFieldTypeOptions";
import { getDateLabel } from "../../methods/getDateLabel";
import {
  getFullDate,
  splitNumbers,
  splitPercents,
} from "../../../utils/methods";
import DictionaryElementTableLabel from "./DictionaryElementTableLabel";
import DictionaryElementLabel from "./DictionaryElementLabel";

interface DictionaryElementOption {
  value: string;
  label: React.JSX.Element;
  tableLabel: React.JSX.Element;
  name: string;
  subField: string;
}

interface DictionaryElementSelectorMenuProps {
  handleChange: (newValue: SelectDictionaryOption) => void;
  onMenuClose: () => void;
  isVisible: boolean;
  forceOpen: boolean;
  selectedOption: Partial<SelectDictionaryOption>;
  wsDictionaryUuid: string | undefined;
  wsDictionarySubFieldUuid: string | null | undefined;
  wsDictionaryElementsFilter: DictionaryElementsFilter | undefined;
  rowId?: string;
}

export default function DictionaryElementSelectorMenu({
  handleChange,
  onMenuClose,
  isVisible,
  forceOpen,
  selectedOption,
  wsDictionaryUuid,
  wsDictionarySubFieldUuid,
  wsDictionaryElementsFilter,
  rowId,
}: DictionaryElementSelectorMenuProps) {
  const [isGettingFilteredDictionaryList, setIsGettingFilteredDictionaryList] =
    useState(false);
  const [options, setOptions] = useState<DictionaryElementOption[]>([]);

  const {
    selectDataSetReducer: { selectDataSetList, isSelectDataSetRequestLoading },
    dictionaryReducer: {
      dictionaryAutoCompletes,
      dictionaryAutoCompletesColumns,
      dictionaryAutocompleteLoading,
      isDictionaryAutocompleteRequestLoading,
    },
    mainReducer: { identity },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);

  const { tasks } = useSelector((state: RootState) => state.taskReducer);

  const dispatch = useDispatch();

  const intl = useIntl();

  const isLoading =
    dictionaryAutocompleteLoading ||
    isDictionaryAutocompleteRequestLoading ||
    isSelectDataSetRequestLoading ||
    isGettingFilteredDictionaryList;

  const getSubMetaFieldValue = (metaFieldItem: any): string | null => {
    const value = metaFieldItem.metadata?.find(
      (item: any) => item.uuid === wsDictionarySubFieldUuid,
    )?.value
      ? metaFieldItem.metadata?.find(
          (item: any) => item.uuid === wsDictionarySubFieldUuid,
        )?.value
      : null;

    const metaField =
      dictionaryAutoCompletesColumns[
        `dictionaryType_${wsDictionaryUuid}`
      ]?.find((item) => item.uuid === wsDictionarySubFieldUuid) ?? null;

    if (metaField?.type === MetaFieldType.DictionaryElement) {
      const subMetaFieldDictionaryUuid = metaField?.data?.wsDictionaryUuid;

      if (typeof subMetaFieldDictionaryUuid !== "string") return value;

      const dictionaryAutoCompletesDictionary =
        dictionaryAutoCompletes[
          `dictionaryType_${subMetaFieldDictionaryUuid}`
        ] ?? [];

      if (dictionaryAutoCompletesDictionary.length > 0) {
        const supportingFieldDictionaryItem =
          dictionaryAutoCompletesDictionary.find(
            (dictionaryItem) => dictionaryItem.uuid === value,
          );

        if (!supportingFieldDictionaryItem) return value;

        return supportingFieldDictionaryItem.title;
      }

      return value;
    }

    if (metaField?.type === MetaFieldType.SingleSelect) {
      if (metaField.data?.wsSelectDataSetUuid) {
        const foundDataset =
          selectDataSetList[
            `dataSetType_${metaField.data.wsSelectDataSetUuid}`
          ];

        if (!foundDataset) return value;

        return (
          foundDataset.find((datasetItem) => datasetItem.value === value)
            ?.name ?? null
        );
      }
      return (
        metaField?.data?.singleSelectOptions?.find(
          (item: any) => item.value === value,
        )?.name ?? null
      );
    }
    if (metaField?.type === MetaFieldType.MultiSelect) {
      const valuesArr = metaField?.data?.singleSelectOptions?.filter(
        (item: any) => value?.includes(item.value),
      );

      if (valuesArr?.length) {
        return valuesArr.map((item: any) => item.name).join(", ");
      }

      return null;
    }
    if (metaField?.type === MetaFieldType.Member) {
      const foundMember = membersList?.find((member) => member.id === value);
      return foundMember?.name ?? null;
    }
    if (metaField?.type === MetaFieldType.Date) {
      if (value && value?.date) {
        const formattedValue = getDateLabel(
          "en-EN",
          moment(value.date),
          false,
          intl,
        );

        return typeof formattedValue?.date === "string"
          ? formattedValue?.date
          : getFullDate(formattedValue?.date.toDate());
      }

      return null;
    }
    if (metaField?.type === MetaFieldType.Percent) {
      return value ? `${splitPercents(value)}%` : null;
    }
    if (metaField?.type === MetaFieldType.Number) {
      return value ? `${splitNumbers(value)}` : null;
    }
    if (metaField?.type === MetaFieldType.Currency) {
      return value && metaField?.data?.currencyCode
        ? `${metaField?.data?.currencyCode} ${splitNumbers(value)}`
        : null;
    }

    return value;
  };

  const filterOption = (option: any, input: string) => {
    const searchWords = input?.trim()
      ? input
          .trim()
          .split(" ")
          ?.map((substring: string) => substring.toUpperCase())
      : [];

    if (searchWords && searchWords.length > 0) {
      const concatString = `${option?.data?.name} ${option?.data?.subField ?? ""}`;
      const filteredName = searchWords
        ?.filter((word) => word !== "")
        .every((word) => concatString.trim().toUpperCase().includes(word));

      return option.data.__isNew__ || filteredName;
    }

    return true;
  };

  const generateOption = (listItem: Row) => {
    const icon = getDictionaryIcon(identity, wsDictionaryUuid);
    return {
      value: listItem.uuid,
      label: (
        <DictionaryElementLabel
          listItem={listItem}
          wsDictionarySubFieldUuid={wsDictionarySubFieldUuid}
          subMetaFieldValue={getSubMetaFieldValue(listItem)}
          icon={icon}
        />
      ),
      tableLabel: (
        <DictionaryElementTableLabel title={listItem.title} icon={icon} />
      ),
      name: listItem.title,
      subField: getSubMetaFieldValue(listItem) ?? "",
    };
  };

  const getMissingDataSet = (dataSetTypeUuid: string) => {
    if (!selectDataSetList[`dataSetType_${dataSetTypeUuid}`]) {
      dispatch(getSelectDataSetOptions(dataSetTypeUuid));
    }
  };

  useEffect(() => {
    if (wsDictionaryElementsFilter) return;

    if (isLoading) return;

    const list =
      dictionaryAutoCompletes[`dictionaryType_${wsDictionaryUuid}`] || [];

    if (!list.length) return;

    const metaField = dictionaryAutoCompletesColumns[
      `dictionaryType_${wsDictionaryUuid}`
    ]?.find((item) => item.uuid === wsDictionarySubFieldUuid);

    if (metaField && typeof metaField.data.wsSelectDataSetUuid === "string") {
      getMissingDataSet(metaField.data.wsSelectDataSetUuid);
    }

    const generatedOptions = list.map(generateOption);
    setOptions(generatedOptions);
  }, [
    wsDictionaryElementsFilter,
    wsDictionaryUuid,
    wsDictionarySubFieldUuid,
    identity,
    dictionaryAutoCompletes,
    selectDataSetList,
    isLoading,
  ]);

  useEffect(() => {
    if (wsDictionaryElementsFilter && wsDictionaryUuid) {
      const foundTask = tasks.find((task) => task.taskId === rowId);
      const foundField = foundTask?.metadata.find(
        (metaField) =>
          metaField.uuid === wsDictionaryElementsFilter.wsTaskMetaFieldUuid,
      );

      if (foundTask && foundField) {
        const getFilteredDictionaryList = async (
          dictionaryElementsFilter: DictionaryElementsFilter,
          dictionaryUuid: string,
          field: Metadata,
        ) => {
          setIsGettingFilteredDictionaryList(true);
          try {
            const url = API_URLS.getDictionaryList.replace(
              ":dictionaryUuid:",
              dictionaryUuid,
            );
            const response: AxiosResponse<{ content: DictionaryList }> =
              await axios.get(url, {
                params: {
                  filters: {
                    [dictionaryElementsFilter.wsDictionaryMetaFieldUuid]: {
                      value: [field.value],
                    },
                  },
                },
              });
            if (response.status !== responseCodes["200_OK"]) {
              throw new Error(
                `Request failed with status code ${response.status}`,
              );
            } else {
              const generatedOptions =
                response.data.content.list.map(generateOption);
              setOptions(generatedOptions);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsGettingFilteredDictionaryList(false);
          }
        };

        getFilteredDictionaryList(
          wsDictionaryElementsFilter,
          wsDictionaryUuid,
          foundField,
        );
      }
    }
  }, [wsDictionaryElementsFilter, wsDictionaryUuid, rowId, tasks]);

  return (
    <DropdownPortalMenu
      className="dictionary-element-selector-menu"
      onMouseLeave={(e) => e.stopPropagation()}
    >
      <CustomSelect
        options={isLoading ? skeletonOptions : options}
        value={selectedOption}
        onChange={handleChange}
        isSearchable
        menuIsOpen
        forceOpen={forceOpen}
        forceFocus={isVisible}
        filterOption={filterOption}
        onMenuClose={onMenuClose}
        openOnFocus={false}
      />
    </DropdownPortalMenu>
  );
}
