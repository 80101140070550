import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { TableVirtuoso } from "react-virtuoso";
import { arrayMove } from "@dnd-kit/sortable";
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useSelector, useDispatch } from "react-redux";

import {
  setActiveSortingColumn,
  setSelectedTasks,
  updateListTaskRank,
  updateTaskMetaFieldRank,
} from "src/redux";
import { RootState } from "src/redux/reducers";
import { generateRankString } from "src/utils/rankStrings";
import NoResultsScreen from "src/app/components/NoResultsScreen/NoResultsScreen";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import { clearWorkspaceTaskFilters } from "src/redux/workspaceTaskFilters/workspaceTaskFiltersActions";
import noFilteredResultsImg from "src/images/empty-folder-group.svg";
import {
  VirtuosoTable,
  VirtuosoTableRow,
  VirtuosoTableHead,
  VirtuosoTableBody,
  TableVirtuosoContext,
} from "./components/virtuosoComponents";
import { TableContext } from "../../TableField/types";
import { TableTaskData } from "./types";

interface WorkspaceTasksTableProps {
  columns: any;
  tableContext: TableContext;
}

export default function WorkspaceTasksTable({
  columns,
  tableContext,
}: WorkspaceTasksTableProps) {
  const [data, setData] = useState<TableTaskData[]>([]);
  const [draggableColumns, setDraggableColumns] = useState(
    columns.filter((column: any) => column.columnFromApi),
  );
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isDragging, setIsDragging] = useState(false);

  const dispatch = useDispatch();

  const { filters } = useSelector(
    (state: RootState) => state.workspaceTaskFiltersReducer,
  );

  const activeSortingColumn = useSelector(
    (state: RootState) => state.taskFiltersReducer.activeSortingColumn,
  );

  const selectedTasks = useSelector(
    (state: RootState) => state.taskReducer.selectedTasks,
  );

  const tableInputFocused = useSelector(
    (state: RootState) => state.taskReducer.tableInputFocused,
  );

  const {
    taskReducer: {
      workspaceTasks,
      workspaceTasksColumns,
      areWorkspaceTasksLoading,
    },
  } = useSelector((state: RootState) => state);

  const table = useReactTable({
    data,
    columns,
    debugTable: true,
    state: {
      rowSelection,
      sorting,
      columnPinning: {
        left: [
          "drag-handle",
          "select",
          "creator",
          "content",
          "publication",
          "payment",
          "action",
        ],
        right: [" action"],
      },
    },
    enableColumnResizing: true,
    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getRowId: (row: { id: string }) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const moveRow = (oldIndex: number, newIndex: number) => {
    setData((prevData) => {
      return arrayMove(prevData, oldIndex, newIndex);
    });
  };

  const updateRowRank = (oldIndex: number, newIndex: number) => {
    if (activeSortingColumn || oldIndex === newIndex) return;

    const sourceFieldId = rows[oldIndex]?.id;

    let newRank = "";
    if (newIndex > oldIndex) {
      newRank = generateRankString(
        // @ts-ignore
        rows[newIndex + 1]?.original.rank || "",
        // @ts-ignore
        rows[newIndex]?.original.rank,
      );
    } else {
      newRank = generateRankString(
        // @ts-ignore
        rows[newIndex]?.original.rank,
        // @ts-ignore
        rows[newIndex - 1]?.original.rank || "",
      );
    }

    dispatch(updateListTaskRank(sourceFieldId, newRank));
  };

  const updateMetaFieldRank = async (fieldId: string, newRank: string) => {
    await dispatch(updateTaskMetaFieldRank(fieldId, newRank, tableContext));
    //   dispatch(updateTasksList(projectId, taskType));
  };

  const handleColumnDragEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) {
      setIsDragging(false);
      return;
    }
    const sourceFieldId = draggableColumns[oldIndex]?.columnFromApi.metaFieldId;
    const targetFieldId = draggableColumns[newIndex]?.columnFromApi.metaFieldId;
    const fieldIndex = workspaceTasksColumns.findIndex(
      (column) => column.uuid === targetFieldId,
    );
    let newRank = "";
    if (newIndex < oldIndex) {
      newRank = generateRankString(
        workspaceTasksColumns[fieldIndex - 1]?.rank || "",
        workspaceTasksColumns[fieldIndex]?.rank,
      );
      // move bottom
    } else {
      newRank = generateRankString(
        workspaceTasksColumns[fieldIndex]?.rank,
        workspaceTasksColumns[fieldIndex + 1]?.rank || "",
      );
    }

    updateMetaFieldRank(sourceFieldId, newRank);
    setIsDragging(false);
  };

  const handleRowDragEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      moveRow(oldIndex, newIndex);
      updateRowRank(oldIndex, newIndex);
    }
  };

  useEffect(() => {
    const selectedRowsIds = Object.keys(rowSelection);

    const newSelectedRows = rows
      .filter((row) => selectedRowsIds.includes(row.id))
      .map((row) => row.original);
    dispatch(setSelectedTasks(newSelectedRows));
  }, [rows, rowSelection]);

  useEffect(() => {
    if (isDragging || tableInputFocused) return;

    setData(
      workspaceTasks.map((el) => ({
        id: el.taskId, // id === taskId (react-table needs id)
        ...el,
        publication: null,
        publicationCopy: el.publication ?? null, // This is copied because cell.render() method tries render publication this object
      })),
    );
  }, [workspaceTasks, isDragging, tableInputFocused]);

  useEffect(() => {
    if (!selectedTasks.length && Object.keys(rowSelection).length) {
      setRowSelection({});
    }
  }, [selectedTasks]);

  useEffect(() => {
    return () => {
      dispatch(setActiveSortingColumn(null));
    };
  }, []);

  useEffect(() => {
    if (columns.length) {
      setDraggableColumns(
        columns.filter((column: any) => column.columnFromApi),
      );
    } else {
      setDraggableColumns([]);
    }
  }, [columns]);

  if (
    !areWorkspaceTasksLoading &&
    !rows.length &&
    Object.keys(filters).length !== 0
  ) {
    return (
      <div className="no-results-screen-wrapper">
        <NoResultsScreen
          title={
            <IDHFormattedMessage
              id="ws_no_results_found"
              defaultMessage="No results found"
            />
          }
          subtitle={
            <IDHFormattedMessage
              id="ws_remove_filters"
              defaultMessage="No results match the filter criteria. Remove filter or clear all filters to show results."
            />
          }
          imgUrl={noFilteredResultsImg}
          bottomContent={
            <Button
              size="large"
              variant="blue"
              onClick={() => dispatch(clearWorkspaceTaskFilters())}
            >
              <IDHFormattedMessage
                id="ws_clear_filters"
                defaultMessage="Clear filters"
              />
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div
      className={classNames("tasks-table", "tasks-table--sticky", {
        "tasks-table--dragging": false,
      })}
    >
      <TableVirtuoso
        data={rows}
        context={
          {
            table,
            handleRowDragEnd,
            handleColumnDragEnd,
            setIsDragging,
            tasksLoading: areWorkspaceTasksLoading,
            tableContext,
          } satisfies TableVirtuosoContext
        }
        totalCount={rows.length}
        customScrollParent={
          (document.querySelector(".workspace-tasks-table") as HTMLElement) ||
          undefined
        }
        components={{
          Table: VirtuosoTable,
          // @ts-ignore
          TableHead: VirtuosoTableHead,
          // @ts-ignore
          TableBody: VirtuosoTableBody,
          TableRow: VirtuosoTableRow,
        }}
        fixedHeaderContent={() => <></>}
      />
    </div>
  );
}
