import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ProjectsTableFilters.scss";
import { ReactComponent as FilterIcon } from "src/images/filter.svg";

import { uuidv7 as uuid } from "uuidv7";
import { setProjectFilter, setProjectFilters } from "src/redux";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  PaneTopbar,
  PaneTopbarLeft,
  PaneTopbarToolbar,
} from "src/app/components/Pane/PaneTopbar/PaneTopbar";
import SingleFilter from "./components/SingleFilter/SingleFilter";

function ProjectsTableFilters({ setFiltersVisible, columns }) {
  const {
    projectFiltersReducer: { filters, hiddenColumns },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [renderKey, setRenderKey] = useState(uuid());

  const clearFilters = () => {
    dispatch(setProjectFilters([]));
    setRenderKey(uuid());
  };

  const sendFilter = (fieldUuid, value) => {
    dispatch(setProjectFilter(fieldUuid, value));
  };

  return (
    <>
      <div className="projects-table-filters" key={renderKey}>
        <PaneTopbar>
          <PaneTopbarLeft>
            <FilterIcon className="filters-header__icon" />
            <span className="filters-header__title">
              <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
            </span>
          </PaneTopbarLeft>
          <PaneTopbarToolbar hidePane={() => setFiltersVisible(false)} />
        </PaneTopbar>
        <div className="pane__container filters">
          {columns.length === 0 && (
            <div className="filter-box-empty">
              <IDHFormattedMessage
                id="ws_no_data_to_display"
                defaultMessage="No data to display"
              />
            </div>
          )}
          <div className="projects-table-filters__fields-wrap">
            <SingleFilter
              col={{
                name: "Campaign name",
                type: "text",
                uuid: "campaignNameOrUuid",
              }}
              sendFilter={sendFilter}
              defaultValue={filters?.campaignNameOrUuid?.value}
              key={`${renderKey}-campaignNameOrUuid`}
              filters={filters}
            />
            {columns
              .filter((c) => !hiddenColumns.includes(c.uuid))
              ?.map((c) => (
                <SingleFilter
                  col={c}
                  sendFilter={sendFilter}
                  defaultValue={filters[c.uuid]?.value}
                  key={`${renderKey}-${c.uuid}`}
                  filters={filters}
                />
              ))}
          </div>
        </div>
      </div>
      <Button
        variant="light"
        size="medium"
        className="project-filters-button"
        onClick={clearFilters}
        data-qa="clear-filters-button"
      >
        <IDHFormattedMessage
          id="ws_clear_filters"
          defaultMessage="Clear filters"
        />
      </Button>
    </>
  );
}

export default ProjectsTableFilters;
