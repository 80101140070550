import React, { useState } from "react";
import { useIntl } from "react-intl";
import "./MemberSelect.scss";

import { useSelector } from "react-redux";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";

import { translateMessage } from "src/app/methods/translateMessage";
import AvatarPlaceholder from "src/images/avatar-purple.svg";

export function MemberSelect({ col, sendFilter, filters }) {
  const intl = useIntl();
  const {
    projectReducer: { activeMembersList },
  } = useSelector((state) => state);

  const filterOption = (candidate, input) => {
    return (
      candidate.data.__isNew__ ||
      candidate.value.name.toUpperCase().includes(input.toUpperCase())
    );
  };

  const [options] = useState(
    activeMembersList.map((member) => ({
      value: { email: member.email, name: member.name, id: member.id },
      label: (
        <span className="activity-filters__option">
          <img
            className="activity-filters__option-image"
            src={member?.avatarUrl || AvatarPlaceholder}
            alt="avatar"
          />
          <span className="activity-filters__option-text">{member.name}</span>
        </span>
      ),
    })) || [],
  );

  const [value, setValue] = useState(
    filters[col.uuid]?.value.map((v) =>
      options.find((o) => o.value.id === v),
    ) || [],
  );

  return (
    <CustomSelect
      className="projects-table-filters__member-select ws-react-select ws-react-select--with-tiles"
      value={value}
      onChange={(nv) => {
        setValue(nv);
        sendFilter(
          col.uuid,
          nv.map((v) => v.value.id),
        );
      }}
      placeholder={translateMessage({
        intl,
        id: "ws_all",
        defaultMessage: "All",
      })}
      options={options}
      isMulti
      withTiles
      openMenuOnFocus={false}
      isSearchable
      filterOption={filterOption}
      data-qa={col.name}
    />
  );
}
