import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { RootState } from "src/redux/reducers";
import { useIntl } from "react-intl";
import CampaignPlaceholder from "../../../../../../../../../images/project-placeholder.svg";
import { translateMessage } from "../../../../../../../../methods/translateMessage";
import CustomSelect from "../../../../../../../../components/CustomSelect/CustomSelect";
import { IProject } from "../../../../../../../../../redux/project/types";

interface Props {
  col: Metadata;
  sendFilter: (colUuid: string, value: any) => void;
  filters: Record<
    string,
    {
      value: any;
    }
  >;
}

interface ProjectAutocompleteOption {
  value: {
    name: string;
    id: string | number;
  };
  label: JSX.Element;
}

const renderProjectOption = (project: IProject): JSX.Element => (
  <span className="project-selector__option" key={project.projectId}>
    <img
      loading="lazy"
      className="project-selector__option-image project-selector__option-image--square"
      src={project?.cover?.thumbnails?.tiny || CampaignPlaceholder}
      alt="avatar"
    />
    <span className="project-selector__option-text">{project.projectName}</span>
  </span>
);

function ProjectSelectorFilter(props: Props) {
  const { col, sendFilter, filters } = props;

  const [selectedOption, setSelectedOption] = useState<
    ProjectAutocompleteOption[] | null
  >(null);

  const wsProjectAutocompleteList = useSelector(
    (state: RootState) => state.projectReducer.wsProjectAutocompleteList,
  );

  const intl = useIntl();

  useEffect(() => {
    if (filters[col.uuid]?.value && wsProjectAutocompleteList.length > 0) {
      const defaultSelected = filters[col.uuid].value
        .map((id: string) => {
          const matchingProject = wsProjectAutocompleteList.find(
            (project) => project.projectId === id,
          );
          return matchingProject
            ? {
                value: {
                  name: matchingProject.projectName,
                  id: matchingProject.projectId,
                },
                label: renderProjectOption(matchingProject),
              }
            : null;
        })
        .filter(Boolean) as ProjectAutocompleteOption[];

      setSelectedOption(defaultSelected);
    }
  }, [filters, col.uuid, wsProjectAutocompleteList]);

  const projectAutocompleteSelectOptions = (): ProjectAutocompleteOption[] => {
    return wsProjectAutocompleteList
      .filter(
        (project) =>
          !selectedOption?.some(
            (selected) => selected.value.id === project.projectId,
          ),
      )
      .map((project) => ({
        value: { name: project.projectName, id: project.projectId },
        label: renderProjectOption(project),
      }));
  };

  const onChange = (newValue: ProjectAutocompleteOption[]) => {
    setSelectedOption(newValue);

    const updatedFilterValues = newValue.map(
      (o: ProjectAutocompleteOption) => o.value.id,
    );

    sendFilter(col.uuid, updatedFilterValues);
  };

  const filterOption = (candidate: any, input: any) => {
    return (
      candidate.data.__isNew__ ||
      candidate.value.name.toUpperCase().includes(input.toUpperCase())
    );
  };

  return (
    <CustomSelect
      value={selectedOption}
      onChange={(newValue: ProjectAutocompleteOption[]) => onChange(newValue)}
      placeholder={translateMessage({
        intl,
        id: "ws_all",
        defaultMessage: "All",
      })}
      options={projectAutocompleteSelectOptions()}
      disabled={false}
      isMulti
      withTiles
      openMenuOnFocus={false}
      isSearchable
      filterOption={filterOption}
      data-qa={col.name}
    />
  );
}

export default ProjectSelectorFilter;
