import React, { useEffect, useState } from "react";
import "./ShowcaseToolbar.scss";

import axios from "axios";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MultiCoverSelector } from "src/app/generator-data/components/MultiCoverSelector/MultiCoverSelector";
import { API_URLS } from "../../../../utils/API_URLS";
import {
  layoutsAreEqual,
  scrollbarVisible,
  showErrorToast,
} from "../../../../utils/methods";
import { Button } from "../../../components/Button/Button";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { SkeletonText } from "../../../components/Skeleton/Skeleton";
import { formatOptions } from "../../data/sizingData";

import { ReactComponent as PointerIcon } from "../../../../images/pointer.svg";
import {
  setMultipleLayouts,
  setNewGridElements,
  setNewHiddenGridElements,
  setNewLayout,
  setNewSavedTemplate,
  setSelectedTemplate,
  setStartingTemplates,
} from "../../../../redux";
import SaveAsModal from "../../modals/SaveAsModal/SaveAsModal";
import RemoveTemplateModal from "../../modals/RemoveTemplateModal/RemoveTemplateModal";
import { showToast } from "../../../methods/showToast";
import ToolbarList from "../../../generator-data/components/ToolbarList/ToolbarList";
import { TemplateSelector } from "../../../generator-data/components/TemplateSelector/TemplateSelector";
import { getShowcaseSettings } from "../../utils";
import { CreatorSelector } from "../CreatorSelector/CreatorSelector";
import { SummaryPlacementSelector } from "../ShowcaseTemplate/tabs/ShowcaseCoverTab/components/SummaryPlacementSelector/SummaryPlacementSelector";

export function ShowcaseToolbar({
  showcaseRef,
  format,
  setFormat,
  metricsData,
}) {
  const [templateOptions, setTemplateOptions] = useState([]);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [showRemoveTemplateModal, setShowRemoveTemplateModal] = useState(false);
  const [templateToRemoveUuid, setTemplateToRemoveUuid] = useState("");
  // buttons
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showSaveAsButton, setShowSaveAsButton] = useState(true);
  const [showApplyForAllButton, setShowApplyForAllButton] = useState(true);

  const dispatch = useDispatch();

  const generating = useSelector((state) => state.showcaseReducer.generating);

  const gridElements = useSelector(
    (state) => state.showcaseReducer.gridElements,
  );

  const hiddenGridElements = useSelector(
    (state) => state.showcaseReducer.hiddenGridElements,
  );

  const printing = useSelector((state) => state.showcaseReducer.printing);

  const layout = useSelector((state) => state.showcaseReducer.layout);

  const selectedTemplate = useSelector(
    (state) => state.showcaseReducer.selectedTemplate,
  );

  const startingTemplates = useSelector(
    (state) => state.showcaseReducer.startingTemplates,
  );

  const multipleLayouts = useSelector(
    (state) => state.showcaseReducer.multipleLayouts,
  );

  const showcaseTransition = useSelector(
    (state) => state.showcaseReducer.showcaseTransition,
  );

  const { workspaceId, creatorId, tab } = getShowcaseSettings();

  const toolbarListProps = {
    layout,
    gridElements,
    hiddenGridElements,
    setLayout: setNewLayout,
    setGridElements: setNewGridElements,
    setHiddenGridElements: setNewHiddenGridElements,
  };

  const loading = generating || printing;

  useEffect(() => {
    if (!templateOptions.length) return;

    if (
      templateOptions.find(
        (item) => item.value.uuid === selectedTemplate.value.uuid,
      )
    )
      return;

    const defaultTemplate = templateOptions.filter(
      (item) => item.value.type === "default",
    )[0];

    dispatch(setSelectedTemplate(defaultTemplate));
  }, [templateOptions]);

  const getTemplates = async () => {
    const url = API_URLS.getReportTemplates.replace(
      ":workspaceUuid:",
      workspaceId,
    );

    try {
      const {
        data: { content },
      } = await axios.get(url, { params: { type: "creator" } });

      const newTemplateOptions = content.map((item) => {
        return {
          label: item.name,
          value: {
            uuid: item.uuid,
            type: item.wsMemberUuid ? "custom" : "default",
            layout: JSON.parse(item.layout),
            autogenerateMetadata: item.data.autogenerateMetadata,
          },
        };
      });

      setTemplateOptions(newTemplateOptions);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  useEffect(() => {
    if (!workspaceId) return;
    getTemplates();
  }, [workspaceId]);

  useEffect(() => {
    const templateSelections =
      JSON.parse(window.localStorage.getItem("templateSelections")) || [];

    const newTemplateSelections = templateSelections.map((item) => {
      if (item.creatorId === creatorId) {
        return {
          creatorId,
          template: selectedTemplate,
        };
      }
      return item;
    });

    window.localStorage.setItem(
      "templateSelections",
      JSON.stringify(newTemplateSelections),
    );
  }, [selectedTemplate]);

  useEffect(() => {
    if (!templateOptions) return;

    const templateSelections =
      JSON.parse(window.localStorage.getItem("templateSelections")) || [];

    const filteredSelections = templateSelections.filter((item) =>
      multipleLayouts.find((i) => i.creatorId === item.creatorId),
    );

    multipleLayouts.forEach((item) => {
      if (!filteredSelections.find((i) => i.creatorId === item.creatorId)) {
        filteredSelections.push({
          creatorId: item.creatorId,
          template: templateOptions.filter(
            (item) => item.value.type === "default",
          )[0],
        });
      }
    });

    window.localStorage.setItem(
      "templateSelections",
      JSON.stringify(filteredSelections),
    );
  }, [multipleLayouts.length, templateOptions]);

  const handleClickApplyForAll = async () => {
    const newMultipleLayouts = await multipleLayouts.map((item) => {
      return {
        ...item,
        layout,
      };
    });

    const templateSelections =
      JSON.parse(window.localStorage.getItem("templateSelections")) || [];

    const newTemplateSelections = templateSelections.map((item) => ({
      ...item,
      template: selectedTemplate,
    }));

    window.localStorage.setItem(
      "templateSelections",
      JSON.stringify(newTemplateSelections),
    );

    dispatch(setMultipleLayouts(newMultipleLayouts));
    showToast(
      "info",
      <IDHFormattedMessage id="ws_info" defaultMessage="Info" />,
      <IDHFormattedMessage
        id="ws_template_has_been_applied_for_all_creators"
        defaultMessage="Template has been applied for all creators"
      />,
    );
  };

  const handleClickSave = async () => {
    try {
      await axios.put(API_URLS.updateReportTemplate, {
        uuid: selectedTemplate.value.uuid,
        layout: JSON.stringify(layout),
      });

      const newStartingTemplates = startingTemplates.map((item) => ({
        ...item,
        layout,
      }));

      dispatch(setStartingTemplates(newStartingTemplates));
      dispatch(
        setSelectedTemplate({
          ...selectedTemplate,
          value: {
            ...selectedTemplate.value,
            layout,
          },
        }),
      );

      showToast(
        "success",
        "Success",
        "Template has been successfully updated!",
      );
      getTemplates();
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  // save button
  useEffect(() => {
    if (printing) {
      if (showSaveButton) setShowSaveButton(false);
      return;
    }

    const sortedTemplate = selectedTemplate?.value?.layout
      ?.filter((item) => !item.i.includes("separator"))
      .sort((a, b) => a.y - b.y);
    const sortedLayout = layout
      ?.filter((item) => !item.i.includes("separator"))
      .sort((a, b) => a.y - b.y);

    if (!sortedTemplate?.length || !sortedLayout?.length) return;

    if (
      selectedTemplate.value.type !== "default" &&
      !layoutsAreEqual(sortedTemplate, sortedLayout)
    ) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [layout, selectedTemplate.value.layout, printing, showSaveButton]);

  useEffect(() => {
    const templateSelections =
      JSON.parse(window.localStorage.getItem("templateSelections")) || [];

    const template = templateSelections.find(
      (item) => item.creatorId === creatorId,
    )?.template;

    if (template) {
      dispatch(setSelectedTemplate(template));
    }
  }, [creatorId]);

  const handleTemplateClick = (template) => {
    if (template.value.type === "custom") {
      dispatch(setNewSavedTemplate(template.value.layout));
    } else {
      dispatch(setNewSavedTemplate([]));
    }

    const newMultipleLayouts = multipleLayouts.map((item) => {
      if (item.creatorId === creatorId) {
        return {
          creatorId,
          layout: template.value.layout,
        };
      }
      return item;
    });

    document.querySelector(".creator-showcase").scroll(0, 0);
    dispatch(setMultipleLayouts(newMultipleLayouts));
    dispatch(setSelectedTemplate(template));
  };

  const showButtonSection =
    showSaveButton || showSaveAsButton || showApplyForAllButton;

  const showBottomButtons = showSaveAsButton || showSaveButton;

  const renderContentForCover = () => {
    return (
      <span className="showcase-toolbar__header">
        <IDHFormattedMessage
          id="ws_cover_settings"
          defaultMessage="Cover settings"
        />

        <div className="showcase-toolbar__row showcase-toolbar__row--vertical">
          <IDHFormattedMessage
            id="ws_cover_image"
            defaultMessage="Cover image"
          />
          <MultiCoverSelector disabled={loading} />
        </div>

        <div className="showcase-toolbar__row showcase-toolbar__row--vertical">
          <IDHFormattedMessage id="ws_summary" defaultMessage="Summary" />
          <SummaryPlacementSelector disabled={loading} />
        </div>
      </span>
    );
  };

  const renderContentForCreators = () => {
    return (
      <>
        <div className="showcase-toolbar__metrics">
          <div className="showcase-toolbar__row showcase-toolbar__row--vertical showcase-toolbar__row--no-padding">
            <IDHFormattedMessage id="ws_creator" defaultMessage="Creator" />
            <CreatorSelector disabled={loading} />
          </div>

          <div className="showcase-toolbar__row showcase-toolbar__row--vertical">
            <IDHFormattedMessage id="ws_template" defaultMessage="Template" />
            <TemplateSelector
              templateOptions={templateOptions}
              selectedTemplate={selectedTemplate}
              setTemplateToRemoveUuid={setTemplateToRemoveUuid}
              setShowRemoveTemplateModal={setShowRemoveTemplateModal}
              clickTemplate={handleTemplateClick}
              disabled={loading}
            />
          </div>

          {loading ? (
            <SkeletonText width={80} height={20} />
          ) : (
            <span className="showcase-toolbar__header">
              <IDHFormattedMessage
                id="ws_attributes"
                defaultMessage="Attributes"
              />
            </span>
          )}

          {loading ? (
            <div className="toolbar-list">
              <SkeletonText width="100%" height={20} />
            </div>
          ) : (
            <ToolbarList
              provider="metadata"
              format={format.value}
              open
              toolbarListProps={toolbarListProps}
            />
          )}
        </div>

        <div className="showcase-toolbar__metrics">
          {loading ? (
            <SkeletonText width={80} height={20} />
          ) : (
            <span className="showcase-toolbar__header">
              <IDHFormattedMessage
                id="ws_social_data"
                defaultMessage="Social data"
              />
            </span>
          )}

          {loading ? (
            <>
              <div className="toolbar-list">
                <SkeletonText width="100%" height={20} />
              </div>
              <div className="toolbar-list">
                <SkeletonText width="100%" height={20} />
              </div>
            </>
          ) : (
            <>
              {metricsData.map((metric) => (
                <ToolbarList
                  provider={metric.label}
                  format={format.value}
                  toolbarListProps={toolbarListProps}
                />
              ))}
            </>
          )}
        </div>

        {showButtonSection && (
          <div className="showcase-toolbar__buttons">
            {showApplyForAllButton && (
              <Button
                variant="green"
                size="large"
                onClick={handleClickApplyForAll}
                disabled={loading}
              >
                <PointerIcon />
                <IDHFormattedMessage
                  id="ws_apply_changes_for_all_creators"
                  defaultMessage="Apply changes for all Creators"
                />
              </Button>
            )}

            {showBottomButtons && (
              <div className="showcase-toolbar__buttons-bottom">
                {showSaveButton && (
                  <Button
                    variant="grey"
                    size="large"
                    onClick={handleClickSave}
                    disabled={loading}
                  >
                    <IDHFormattedMessage
                      id="ws_save_template"
                      defaultMessage="Save template"
                    />
                  </Button>
                )}

                {showSaveAsButton && (
                  <Button
                    variant="white"
                    size="large"
                    onClick={() => setShowSaveAsModal(true)}
                    disabled={loading}
                  >
                    <IDHFormattedMessage
                      id="ws_save_template_as"
                      defaultMessage="Save template as"
                    />
                    ...
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const renderContent = () => {
    switch (tab) {
      case "cover":
        return renderContentForCover();

      case "creators":
        return renderContentForCreators();
    }
  };

  return (
    <div
      className={classNames("showcase-toolbar", {
        "showcase-toolbar--scrollbar-space": scrollbarVisible(
          showcaseRef?.current,
        ),
        "showcase-toolbar--lists-hidden": showcaseTransition,
      })}
    >
      <div className="showcase-toolbar__content">{renderContent()}</div>

      {showSaveAsModal && (
        <SaveAsModal
          onClose={() => setShowSaveAsModal(false)}
          getTemplates={getTemplates}
          layout={layout}
          dataType="creator"
          setSelectedTemplate={(templateData) =>
            dispatch(setSelectedTemplate(templateData))
          }
        />
      )}

      {showRemoveTemplateModal && (
        <RemoveTemplateModal
          onClose={() => setShowRemoveTemplateModal(false)}
          templateUuid={templateToRemoveUuid}
          getTemplates={getTemplates}
        />
      )}
    </div>
  );
}
