import React, { useEffect, useRef, useState } from "react";
import "./TableField.scss";

import classNames from "classnames";

import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { AccessLinkMetaField } from "src/app/metafields/AccessLinkMetaField/AccessLinkMetaField";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { TooltipedEllipsis } from "src/app/components/TooltipedEllipsis/TooltipedEllipsis";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { DictionaryElementSelector } from "src/app/dropdowns/DictionaryElementSelector/DictionaryElementSelector";
import RatingSelector from "src/app/components/RatingSelector/RatingSelector";
import { RootState } from "src/redux/reducers";
import AggregatedSelect from "src/app/components/AggregatedSelect/AggregatedSelect";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import { ReactComponent as RemoveIcon } from "../../../images/close-alt.svg";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";

import CustomDatePicker, {
  DatePickerWrapper,
} from "../../components/CustomDatePicker/CustomDatePicker";
import { IMember } from "../ProjectTypes";
import ChoosePersonDropdown from "../../dropdowns/ChoosePersonDropdown/ChoosePersonDropdown";

import AvatarPlaceholder from "../../../images/avatar-purple.svg";
import {
  changeTaskMetaFieldValue,
  setEditedCellId,
  setLocalSyncToggle,
  updateProjectMetaValue,
} from "../../../redux";
import { DateObject } from "../Project";
import useOnClickOutside from "../../methods/useOnClickOutside";
import { FieldSynchronizationWarningModal } from "../../modals/FieldSynchronizationWarningModal/FieldSynchronizationWarningModal";
import { tableDataType } from "../../components/Table/Table";
import TaskTableField from "./components/TaskTableField/TaskTableField";
import { ProjectTableField } from "./components/ProjectTableField/ProjectTableField";
import { ProjectFieldPreviewSwitch } from "./components/ProjectFieldPreviewSwitch";
import { ProjectFieldEditSwitch } from "./components/ProjectFieldEditSwitch";
import { ProgressField } from "./meta-types/ProgressField/ProgressField";
import { Metadata } from "../../Task/Subtask/Subtask";
import { FileField } from "../../metafields/FileField/FileField";
import { TextBoxWrapper } from "../../components/TextBoxWrapper/TextBoxWrapper";
import SelectDropdown from "../../dropdowns/SelectDropdown/SelectDropdown";
import ProjectSelector from "../../components/ProjectSelector/ProjectSelector";
import { IProject } from "../../../redux/project/types";

interface Props {
  fieldValue: string;
  fieldData: Metadata;
  onValueChange: (
    newValue: string | DateObject | boolean | null | number,
  ) => void;
  objectId: string;
  uuid: string;
  dataType: tableDataType;
  wsProjectAutocompleteList?: IProject[];
}

export const TableField: React.FC<Props> = (props) => {
  const {
    fieldValue,
    fieldData,
    onValueChange,
    objectId,
    uuid,
    dataType,
    wsProjectAutocompleteList,
  } = props;

  const [value, setValue] = useState<any>(fieldValue);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [member, setMember] = useState<any>({});
  const [showEditMark, setShowEditMark] = useState(fieldData.manuallyEdited);
  const [disableFieldClick, setDisableFieldClick] = useState(
    fieldData.shouldTriggerEditWarning,
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const {
    projectReducer: { membersList, editedCellId },
  } = useSelector((state: RootState) => state);

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<any>();
  const fieldContentRef = useRef<any>();

  const dispatch = useDispatch();

  const fieldType = fieldData.type;
  const isReadOnly =
    dataType === tableDataType.Project &&
    fieldData.valueSource &&
    fieldType !== MetaFieldType.AccessLink &&
    fieldType !== MetaFieldType.Project &&
    true;

  const isFieldEdited = editedCellId.length && editedCellId !== uuid;

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus();
      setShowEditMark(false);
    } else {
      addEditMark();
    }
  }, [editMode]);

  useEffect(() => {
    if (member && fieldValue !== member?.id) {
      onValueChange(member.id);
    }
  }, [member]);

  useEffect(() => {
    if (showEditMark !== fieldData.manuallyEdited) {
      setShowEditMark(fieldData.manuallyEdited);
    }
  }, [fieldData.manuallyEdited]);

  useEffect(() => {
    if (disableFieldClick !== fieldData.shouldTriggerEditWarning) {
      setDisableFieldClick(fieldData.shouldTriggerEditWarning);
    }
  }, [fieldData.shouldTriggerEditWarning]);

  useEffect(() => {
    if (fieldType === "member") {
      const memberCopy = membersList?.find(
        (member: IMember) => member.id === fieldValue,
      );

      setMember(memberCopy);
    }
  }, [fieldValue, membersList]);

  const addEditMarkAfterUpdate = () => {
    if (
      (fieldData.manuallyEdited && !fieldData.shouldTriggerEditWarning) ||
      (!fieldData.manuallyEdited && fieldData.shouldTriggerEditWarning)
    ) {
      setShowEditMark(true);
      setEditMode(false);
    }
  };

  const addEditMark = () => {
    if (fieldData.manuallyEdited) {
      setShowEditMark(true);
      setEditMode(false);
    }
  };

  const emptyFieldClick = () => {
    setValue("");
    setEditMode(true);
    inputRef.current?.focus();
  };

  const clearField = async () => {
    dispatch(setLocalSyncToggle(true));
    await setValue(undefined);
    setValue(null);
    setEditMode(false);
    clearFieldValue();
  };

  const clearMember = () => {
    setMember(null);
    clearFieldValue();
  };

  const clearFieldValue = () => {
    if (objectId && uuid) {
      switch (dataType) {
        case tableDataType.Task:
          dispatch(changeTaskMetaFieldValue(objectId, uuid, null));
          break;
        case tableDataType.Project:
          dispatch(updateProjectMetaValue(objectId, uuid, null));
          break;
        default:
          console.error("Unknown data type.");
          break;
      }
    }
  };

  const textboxOnBlur = (newValue: string) => {
    if (!newValue.length) {
      clearField();
    } else if (fieldValue !== newValue) {
      onValueChange(newValue);
      addEditMarkAfterUpdate();
    }
  };

  const inputOnBlur = () => {
    if (!value?.length) {
      clearField();
    } else {
      onValueChange(value);
      addEditMarkAfterUpdate();
    }
    setEditMode(false);
  };

  const onDateChange = (newDate: any) => {
    onValueChange(newDate);
  };

  const table = document.getElementById("table-scroll") as HTMLElement;

  useEffect(() => {
    dispatch(setEditedCellId(""));
    table.onscroll = null;
  }, [table, table.scrollLeft]);

  const handleMouseEnter = () => {
    if (!isFieldEdited) {
      setIsMouseOver(true);
    }
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const onFieldClick = () => {
    setIsFocused(true);

    if (disableFieldClick && !showWarningModal) {
      setShowWarningModal(true);
    }
  };

  const confirmWarning = () => {
    setDisableFieldClick(false);
    setShowWarningModal(false);
    fieldContentRef.current.click();
  };

  const renderBasicTypes = () => {
    switch (fieldType) {
      case MetaFieldType.Member:
        return member ? (
          <>
            <ChoosePersonDropdown
              value={member}
              placeholder={member?.name}
              choosePerson={setMember}
              uuid={uuid || ""}
              dataType={dataType}
              wsTeamUuids={fieldData.data?.wsTeamUuids}
            >
              <div className="assignee-field">
                <img src={member.avatarUrl || AvatarPlaceholder} alt="avatar" />
                <span>
                  <TooltipedEllipsis
                    className="assignee-field__username"
                    maxWidth={112}
                    content={member.name}
                  />
                </span>
              </div>
            </ChoosePersonDropdown>
            {!disableFieldClick && (
              <div onClick={clearMember} className="ws-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        ) : (
          <ChoosePersonDropdown
            value={member}
            placeholder={member?.name}
            choosePerson={setMember}
            uuid={uuid || ""}
            dataType={dataType}
            wsTeamUuids={fieldData.data?.wsTeamUuids}
          >
            <div className="ws-table__empty-field ws-table__empty-field--circle">
              <PlusIcon />
            </div>
          </ChoosePersonDropdown>
        );

      case MetaFieldType.SingleSelect:
        return (
          <SelectDropdown
            uuid={uuid || ""}
            dataType={dataType}
            fieldData={fieldData}
            fieldValue={fieldValue}
            onValueChange={onValueChange}
            selectOptionType="single"
            wsSelectDataSetUuid={fieldData.data?.wsSelectDataSetUuid}
            emptyFieldPlaceholder={
              <div className="ws-table__empty-field">
                <PlusIcon />
              </div>
            }
            removeValueButton={
              <div onClick={clearField} className="ws-table__field-close">
                <RemoveIcon />
              </div>
            }
          />
        );

      case MetaFieldType.MultiSelect:
        return (
          <SelectDropdown
            uuid={uuid || ""}
            dataType={dataType}
            fieldData={fieldData}
            fieldValue={fieldValue}
            onValueChange={onValueChange}
            selectOptionType="multi"
            wsSelectDataSetUuid={fieldData.data?.wsSelectDataSetUuid}
            emptyFieldPlaceholder={
              <div className="ws-table__empty-field">
                <PlusIcon />
              </div>
            }
            removeValueButton={
              fieldValue &&
              !disableFieldClick && (
                <div onClick={clearField} className="ws-table__field-close">
                  <RemoveIcon />
                </div>
              )
            }
          />
        );

      case MetaFieldType.AggregatedSelect:
        return (
          <>
            <AggregatedSelect
              uuid={uuid || ""}
              dataType={dataType}
              optionsData={fieldData?.data?.singleSelectOptions}
              fieldValue={fieldValue}
              updateValue={onValueChange}
              emptyFieldPlaceholder={
                <div className="ws-table__empty-field">
                  <PlusIcon />
                </div>
              }
            />
            {fieldValue && !disableFieldClick && (
              <div onClick={clearField} className="ws-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        );

      case MetaFieldType.ProgressBar:
        return <ProgressField value={value || ""} options={fieldData.data} />;

      case MetaFieldType.Date:
        return (
          <>
            <DatePickerWrapper value={value}>
              <CustomDatePicker
                initialDate={value}
                dateChangeHandler={onDateChange}
              />
            </DatePickerWrapper>
            {fieldValue && (
              <div onClick={clearField} className="ws-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        );

      case MetaFieldType.File:
        return (
          <FileField
            files={value}
            uuid={uuid}
            objectId={objectId}
            dataType={dataType}
          />
        );
      case MetaFieldType.AccessLink:
        return (
          <AccessLinkMetaField
            accessLinkValue={value}
            uuid={uuid}
            objectId={objectId}
            dataType={dataType}
          />
        );
      case MetaFieldType.Text:
        return value || value === "" ? (
          <>
            <TextBoxWrapper
              ref={inputRef}
              value={value || ""}
              onChange={setValue}
              onBlur={textboxOnBlur}
            />
            <div onClick={clearField} className="ws-table__field-close">
              <RemoveIcon />
            </div>
          </>
        ) : (
          <div className="ws-table__empty-field" onClick={emptyFieldClick}>
            <PlusIcon />
          </div>
        );

      case MetaFieldType.BoolVal:
        return (
          <CustomCheckbox
            id={`${uuid}-${objectId}`}
            name={uuid}
            checked={value}
            onChange={(e) => {
              onValueChange(e.target.checked);
            }}
            blue
          />
        );
      case MetaFieldType.DictionaryElement:
        return (
          <>
            <DictionaryElementSelector
              value={fieldData.value}
              wsDictionaryUuid={fieldData.data?.wsDictionaryUuid}
              wsDictionarySubFieldUuid={
                fieldData.data?.wsDictionarySubFieldUuid
              }
              onValueChange={onValueChange}
              emptyFieldClick={emptyFieldClick}
            />
            {fieldValue && (
              <div onClick={clearField} className="tasks-table__field-close">
                <RemoveIcon />
              </div>
            )}
          </>
        );
      case MetaFieldType.Rating:
        return (
          <RatingSelector
            value={parseInt(value || "0")}
            onValueChange={onValueChange}
          />
        );
      case MetaFieldType.Project:
        return (
          <ProjectSelector
            value={value}
            wsProjectAutocompleteList={wsProjectAutocompleteList ?? []}
            wsWorkspaceUuid={activeWorkspaceUuid}
          />
        );

      default:
        return (
          <>
            {value || value === "" ? (
              <>
                {editMode ? (
                  <ProjectFieldEditSwitch
                    fieldType={fieldType}
                    initialDate={value}
                    dateChangeHandler={onDateChange}
                    value={value}
                    setValue={setValue}
                    onBlur={inputOnBlur}
                    inputRef={inputRef}
                    prefix={fieldData?.data?.currencyCode || ""}
                  />
                ) : (
                  <ProjectFieldPreviewSwitch
                    data={fieldData}
                    fieldType={fieldType}
                    setEditMode={setEditMode}
                    value={value}
                    prefix={fieldData?.data?.currencyCode || ""}
                    dataType={tableDataType.Project}
                  />
                )}

                <div
                  onClick={clearField}
                  className={classNames("ws-table__field-close", {
                    "ws-table__field-close--hidden":
                      disableFieldClick || editMode,
                  })}
                >
                  <RemoveIcon />
                </div>
              </>
            ) : (
              <div className="ws-table__empty-field" onClick={emptyFieldClick}>
                <PlusIcon />
              </div>
            )}
          </>
        );
    }
  };

  const renderFieldType = () => {
    switch (dataType) {
      case tableDataType.Task:
        return (
          <TaskTableField
            fieldValue={fieldValue}
            fieldType={fieldType}
            renderBasicTypes={renderBasicTypes}
            onValueChange={onValueChange}
            shouldTriggerEditWarning={disableFieldClick}
            clearFieldValue={clearFieldValue}
            projectId={objectId}
          />
        );

      case tableDataType.Project:
        return (
          <ProjectTableField
            fieldType={fieldType}
            renderBasicTypes={renderBasicTypes}
          />
        );
    }
  };

  useOnClickOutside(fieldRef, () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  });

  return (
    <div
      ref={fieldRef}
      className={classNames(
        "ws-table__field",
        `ws-table__field--${fieldType}`,
        {
          "ws-table__field--read-only": isReadOnly,
          "ws-table__field--not-empty": value,
          "ws-table__field--hovered": isFocused,
        },
      )}
      onClick={onFieldClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span
        ref={fieldContentRef}
        className={classNames("ws-table__field-content", {
          "ws-table__field-content--disabled": disableFieldClick,
        })}
      >
        {renderFieldType()}
        {showEditMark && fieldValue && (
          <div className="ws-table__field-content-edit-icon">
            <EditIcon />
            <IDHFormattedMessage id="ws_edited" defaultMessage="Edited" />
          </div>
        )}
      </span>

      {showWarningModal && (
        <FieldSynchronizationWarningModal
          onClose={() => setShowWarningModal(false)}
          onAccept={confirmWarning}
        />
      )}
    </div>
  );
};
