import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import classNames from "classnames";
import { TableVirtuoso } from "react-virtuoso";
import { TasksColumn } from "src/redux/task/taskReducer";
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import { arrayMove } from "@dnd-kit/sortable";
import { RootState } from "src/redux/reducers";
import {
  setActiveSortingColumn,
  setLocalSyncToggle,
  setSelectedFilters,
  setSelectedTasks,
  setSortingDirection,
  updateListTaskRank,
  updateTaskMetaFieldRank,
  updateTasksList,
} from "../../../../redux";
import { loadLocalProjectSettings } from "./tasksTableSorting/localStorageManagement/loadLocalProjectSettings";
import { saveLocalProjectSettings } from "./tasksTableSorting/localStorageManagement/saveLocalProjectSettings";
import { ifRowMatchesFilters } from "./tasksTableSorting/tableSortingFunctions/filterTableData";
import { synchronizeLocalFilters } from "./tasksTableSorting/tableSortingFunctions/synchronizeLocalFilters";
import { generateRankString } from "../../../../utils/rankStrings";
import {
  VirtuosoTable,
  VirtuosoTableBody,
  VirtuosoTableHead,
  VirtuosoTableRow,
  TableVirtuosoContext,
} from "./components/virtuosoComponents";
import { TableTaskData } from "./types";

import "./TasksTable.scss";

interface TasksTableProps {
  columns: any;
}

export default function TasksTable(props: TasksTableProps) {
  const { columns } = props;

  const [data, setData] = useState<TableTaskData[]>([]);
  const [draggableColumns, setDraggableColumns] = useState(
    columns.filter((column: any) => column.columnFromApi),
  );
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isDragging, setIsDragging] = useState(false);

  const dispatch = useDispatch();

  const params = useParams<{ projectId: string }>();

  const { projectId } = params;

  const identity = useSelector(
    (state: RootState) => state.mainReducer.identity,
  );

  const tasks = useSelector((state: RootState) => state.taskReducer.tasks);

  const tasksLoading = useSelector(
    (state: RootState) => state.taskReducer.tasksLoading,
  );

  const tasksColumns: TasksColumn[] = useSelector(
    (state: RootState) => state.taskReducer.tasksColumns,
  );

  const taskType = useSelector(
    (state: RootState) => state.projectReducer.taskType,
  );

  const activeSortingColumn = useSelector(
    (state: RootState) => state.taskFiltersReducer.activeSortingColumn,
  );

  const sortingDirection = useSelector(
    (state: RootState) => state.taskFiltersReducer.sortingDirection,
  );

  const selectedFilters = useSelector(
    (state: RootState) => state.taskFiltersReducer.selectedFilters,
  );

  const localSyncToggle = useSelector(
    (state: RootState) => state.taskFiltersReducer.localSyncToggle,
  );

  const tableInputFocused = useSelector(
    (state: RootState) => state.taskReducer.tableInputFocused,
  );

  const selectedTasks = useSelector(
    (state: RootState) => state.taskReducer.selectedTasks,
  );

  useEffect(() => {
    if (isDragging || tableInputFocused) return;

    setData(
      tasks
        .filter((task) => {
          return ifRowMatchesFilters(task, selectedFilters, identity.id);
        })
        .map((el) => ({
          id: el.taskId, // id === taskId (react-table needs id)
          ...el,
          publication: null,
          publicationCopy: el.publication ?? null, // This is copied because cell.render() method tries render publication this object
        }))
        .sort((a, b) => b.rank?.localeCompare(a.rank)),
    );
  }, [tasks, selectedFilters, identity.id, isDragging, tableInputFocused]);

  useEffect(() => {
    return () => {
      dispatch(setActiveSortingColumn(null));
      dispatch(setSelectedFilters({ selections: null }));
    };
  }, []); // reducer cleanup

  useEffect(() => {
    if (activeSortingColumn !== null || selectedFilters.selections !== null)
      saveLocalProjectSettings(
        projectId,
        activeSortingColumn,
        taskType,
        sortingDirection,
        selectedFilters,
      );
  }, [activeSortingColumn, sortingDirection, selectedFilters]); // saves sort data to local storage

  useEffect(() => {
    dispatch(setActiveSortingColumn(null));
    if (projectId) {
      loadLocalProjectSettings(projectId, data, taskType, dispatch);
    }
  }, [taskType]); // clears data after TaskType change and sets data to display

  useEffect(() => {
    if (localSyncToggle) {
      synchronizeLocalFilters(
        data,
        projectId,
        taskType,
        setSelectedFilters,
        setLocalSyncToggle,
        dispatch,
      );
    }
  }, [localSyncToggle]);

  useEffect(() => {
    if (columns.length) {
      setDraggableColumns(
        columns.filter((column: any) => column.columnFromApi),
      );
    } else {
      setDraggableColumns([]);
    }
  }, [columns]);

  const table = useReactTable({
    data,
    columns,
    debugTable: true,
    state: {
      rowSelection,
      sorting,
      columnPinning: {
        left: [
          "drag-handle",
          "select",
          "creator",
          "content",
          "publication",
          "payment",
          "action",
        ],
        right: [" action"],
      },
    },
    enableColumnResizing: true,
    enableSorting: true,
    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getRowId: (row: { id: string }) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  useEffect(() => {
    const selectedRowsIds = Object.keys(rowSelection);

    const newSelectedRows = rows
      .filter((row) => selectedRowsIds.includes(row.id))
      .map((row) => row.original);
    dispatch(setSelectedTasks(newSelectedRows));
  }, [rows, rowSelection]);

  useEffect(() => {
    if (!selectedTasks.length && Object.keys(rowSelection).length) {
      setRowSelection({});
    }
  }, [selectedTasks]);

  useEffect(() => {
    if (activeSortingColumn === "") {
      setSorting([]);
      dispatch(setSortingDirection("sortingDescending"));
    } else {
      const columnHeaders = table.getHeaderGroups()[0].headers;
      const activeSortingColumnInstance = columnHeaders.find(
        (col) => col.id === activeSortingColumn,
      );
      const ascending = sortingDirection === "sortingAscending";
      if (activeSortingColumnInstance) {
        // @ts-ignore
        activeSortingColumnInstance.column.getToggleSortingHandler();
      }

      // @ts-ignore
      setSorting([{ id: activeSortingColumn, desc: !ascending }]);
    }
  }, [activeSortingColumn, sortingDirection]); // connect react-table sorting to reducer state

  const moveRow = (oldIndex: number, newIndex: number) => {
    setData((data) => {
      return arrayMove(data, oldIndex, newIndex);
    });
  };

  const updateRowRank = (oldIndex: number, newIndex: number) => {
    if (activeSortingColumn || oldIndex === newIndex) return;

    const sourceFieldId = rows[oldIndex]?.id;

    let newRank = "";
    if (newIndex > oldIndex) {
      newRank = generateRankString(
        // @ts-ignore
        rows[newIndex + 1]?.original.rank || "",
        // @ts-ignore
        rows[newIndex]?.original.rank,
      );
    } else {
      newRank = generateRankString(
        // @ts-ignore
        rows[newIndex]?.original.rank,
        // @ts-ignore
        rows[newIndex - 1]?.original.rank || "",
      );
    }

    dispatch(updateListTaskRank(sourceFieldId, newRank));
  };

  const updateMetaFieldRank = async (fieldId: string, newRank: string) => {
    await dispatch(updateTaskMetaFieldRank(fieldId, newRank));
    dispatch(updateTasksList(projectId, taskType));
  };

  // Drag N Drop

  const handleColumnDragEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) {
      setIsDragging(false);
      return;
    }
    const sourceFieldId = draggableColumns[oldIndex]?.columnFromApi.metaFieldId;
    const targetFieldId = draggableColumns[newIndex]?.columnFromApi.metaFieldId;
    const fieldIndex = tasksColumns.findIndex(
      (x) => x.metaFieldId === targetFieldId,
    );
    let newRank = "";
    if (newIndex < oldIndex) {
      newRank = generateRankString(
        tasksColumns[fieldIndex - 1]?.metaFieldRank || "",
        tasksColumns[fieldIndex]?.metaFieldRank,
      );
      // move bottom
    } else {
      newRank = generateRankString(
        tasksColumns[fieldIndex]?.metaFieldRank,
        tasksColumns[fieldIndex + 1]?.metaFieldRank || "",
      );
    }

    updateMetaFieldRank(sourceFieldId, newRank);
    setIsDragging(false);
  };

  const handleRowDragEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      moveRow(oldIndex, newIndex);
      updateRowRank(oldIndex, newIndex);
    }
  };

  return (
    <div
      className={classNames("tasks-table", "tasks-table--sticky", {
        "tasks-table--dragging": isDragging,
      })}
    >
      <TableVirtuoso
        data={rows}
        context={
          {
            table,
            handleRowDragEnd,
            handleColumnDragEnd,
            setIsDragging,
            tasksLoading,
          } satisfies TableVirtuosoContext
        }
        totalCount={rows.length}
        customScrollParent={
          (document.querySelector(".projects") as HTMLElement) || undefined
        }
        components={{
          Table: VirtuosoTable,
          // @ts-ignore
          TableHead: VirtuosoTableHead,
          // @ts-ignore
          TableBody: VirtuosoTableBody,
          TableRow: VirtuosoTableRow,
        }}
        fixedHeaderContent={() => <></>}
      />
    </div>
  );
}
