import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { RootState } from "src/redux/reducers";
import { flexRender } from "@tanstack/react-table";
import { SortableHandle } from "react-sortable-hoc";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import { ReactComponent as ClientOrangeIcon } from "../../../../images/client-orange.svg";
import { ReactComponent as GridIcon } from "../../../../images/grid-grey.svg";
import { SkeletonText } from "../../../components/Skeleton/Skeleton";
import { TaskTableHeaderOptionsDropdown } from "../../../dropdowns/TaskTableHeaderOptionsDropdown/TaskTableHeaderOptionsDropdown";
import ShareWithClientModal from "../../../modals/ShareWithClientModal/ShareWithClientModal";
import StopSharingWithClientModal from "../../../modals/StopSharingWithClientModal/StopSharingWithClientModal";
import { ColumnHeaderName } from "./ColumnHeaderName";
import { SortingTasksArrow } from "./tasksTableSorting/SortingTasksArrow/SortingTasksArrow";
import getCommonPinningStyles from "./functions";

export const SKELETON_BLACKLIST = ["select", "drag-handle"];

const COLUMN_ID_BLACKLIST = [
  "fullWidthTable",
  " action",
  "rightPadding",
  "select",
  "drag-handle",
];
interface HeaderCellInterface {
  header: any;
  columnIndex: number;
  tasksLoading: boolean;
  draggable?: boolean;
}

function HeaderCell({
  header,
  columnIndex,
  tasksLoading,
  draggable,
}: HeaderCellInterface) {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [showShareWithClientModal, setShowShareWihClientModal] =
    useState(false);
  const [showStopSharingWithClientModal, setShowStopSharingWithClientModal] =
    useState(false);

  const { column } = header;

  const activeSortingColumn = useSelector(
    (state: RootState) => state.taskFiltersReducer.activeSortingColumn,
  );

  const permissions = useSelector(
    (state: RootState) => state.projectReducer.projectBasicData?.permissions,
  );
  const clientMode = useSelector(
    (state: RootState) => state.projectReducer.projectBasicData?.clientMode,
  );

  const { columnFromApi } = column?.columnDef;

  const showShareResourcesTooltip =
    permissions?.project.includes("share_project_resources_preview") &&
    clientMode &&
    columnFromApi &&
    columnFromApi?.metaFieldType !== MetaFieldType.DictionaryElement;

  const openShareWithClientModal = () => {
    permissions?.project.includes("share_project_resources") &&
      setShowShareWihClientModal(true);
  };

  const openStopSharingWithClientModal = () => {
    permissions?.project.includes("share_project_resources") &&
      setShowStopSharingWithClientModal(true);
  };

  const renderContent = () => {
    if (column.columnDef.id === "select") {
      return flexRender(header.column.columnDef.header, header.getContext());
    }

    return (
      <ColumnHeaderName
        column={column}
        showShareResourcesTooltip={showShareResourcesTooltip}
        showNameTooltip={columnIndex > 1}
      >
        {column.columnDef.header}
      </ColumnHeaderName>
    );
  };

  const DragHandle = SortableHandle(() => (
    <div className="tasks-table__th-drag-and-drop-wrapper">
      <GridIcon className="tasks-table__th-drag-and-drop" />
    </div>
  ));

  return (
    <th
      className="tasks-table__th"
      style={{
        ...getCommonPinningStyles(header.column, draggable),
      }}
    >
      {tasksLoading ? (
        <>
          {!SKELETON_BLACKLIST.includes(column.id) && (
            <>
              <span style={{ visibility: "hidden" }} />
              <SkeletonText width={60} height={15} />
            </>
          )}
        </>
      ) : (
        <div
          className={classNames("tasks-table__header-wrapper", {
            "tasks-table__header-wrapper--active": isDropdownOpened,
          })}
          data-column-name={
            header?.column?.columnDef?.columnFromApi?.metaFieldName
          }
          data-column-key={
            header?.column?.columnDef?.columnFromApi?.metaFieldKey
          }
        >
          {draggable && <DragHandle />}
          {activeSortingColumn === header.id && <SortingTasksArrow />}

          {renderContent()}

          {!COLUMN_ID_BLACKLIST.includes(column.id) && (
            <>
              {showShareResourcesTooltip && (
                <TooltipPortal
                  className="tasks-table__header-wrapper-share-icon"
                  center
                  content={
                    columnFromApi.metaFieldShared ||
                    columnFromApi.metaFieldIsSystem ? (
                      <IDHFormattedMessage
                        id="ws_tooltip_click_to_stop_sharing"
                        defaultMessage="Click to stop sharing with Client"
                      />
                    ) : (
                      <IDHFormattedMessage
                        id="ws_tooltip_click_to_share"
                        defaultMessage="Click to share with Client"
                      />
                    )
                  }
                  contentHidden={
                    !permissions?.project.includes("share_project_resources")
                  }
                >
                  {columnFromApi.metaFieldShared ? (
                    <ClientOrangeIcon
                      className={classNames("tasks-table__column-shared", {
                        "tasks-table__column-shared--disabled":
                          !permissions?.project.includes(
                            "share_project_resources",
                          ),
                      })}
                      onClick={openStopSharingWithClientModal}
                    />
                  ) : (
                    <div
                      className={classNames("tasks-table__column-not-shared", {
                        "tasks-table__column-not-shared--disabled":
                          !permissions?.project.includes(
                            "share_project_resources",
                          ),
                      })}
                      onClick={openShareWithClientModal}
                    />
                  )}
                </TooltipPortal>
              )}
              <TaskTableHeaderOptionsDropdown
                uuid={header.id}
                columnFromApi={columnFromApi}
                setIsDropdownOpened={setIsDropdownOpened}
                openShareWithClientModal={openShareWithClientModal}
                openStopSharingWithClientModal={openStopSharingWithClientModal}
              />
            </>
          )}
        </div>
      )}

      {showShareWithClientModal && (
        <ShareWithClientModal
          onClose={() => setShowShareWihClientModal(false)}
          metaFieldId={header.id}
          taskType={columnFromApi.metaFieldTaskType}
        />
      )}

      {showStopSharingWithClientModal && (
        <StopSharingWithClientModal
          onClose={() => setShowStopSharingWithClientModal(false)}
          metaFieldId={header.id}
          taskType={columnFromApi.metaFieldTaskType}
        />
      )}
    </th>
  );
}

export { HeaderCell };
