import React, { useMemo, useRef } from "react";
import "./TableViewSelector.scss";
import { ReactComponent as EyeIcon } from "src/images/eye.svg";
import { ReactComponent as SelectedIcon } from "src/images/selected.svg";
import { getWorkspaceUuidFromCurrentUrl } from "src/utils/methods";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "../components/Dropdown/Dropdown";
import { Button } from "../components/Button/Button";
import { getTableViewOptions } from "./tableViewFunctions";
import { DropdownPopper } from "../components/DropdownPopper/DropdownPopper";

export default function TableViewSelector({
  wsTableViews,
  selectedWsTableView,
  setSelectedWsTableView,
  setFilters,
  setHiddenColumnIds,
  setSortBy,
  setSort,
  columnIds,
  menuPlacement = "bottom-start",
  localStorageKey,
}) {
  const dropdownRef = useRef(null);
  const workspaceId = getWorkspaceUuidFromCurrentUrl();

  const options = useMemo(
    () => getTableViewOptions(wsTableViews),
    [wsTableViews],
  );

  const localSelectedOption = useMemo(() => {
    const option = options.find(
      (item) => item?.value === selectedWsTableView?.value,
    );

    if (option) return option;

    return {
      label: "Custom",
      value: null,
    };
  }, [options, selectedWsTableView?.value]);

  const onChangeWsTableView = (newView) => {
    if (newView.value === null) {
      setSelectedWsTableView({
        label: "Custom",
        value: null,
      });
      const settingsData = JSON.parse(localStorage.getItem(localStorageKey));
      const settings = settingsData ? settingsData[workspaceId] : {};
      const { filters, sortBy, sort, hiddenColumns } = settings;
      setHiddenColumnIds(hiddenColumns || [], true);
      setFilters(filters || [], true);
      setSortBy(sortBy || null, true);
      setSort(sort || "DESC");
      return;
    }
    const sourceOfSelectedWsTableView = wsTableViews.find(
      (wsTableView) => wsTableView.uuid === newView.value,
    );
    if (!sourceOfSelectedWsTableView) {
      return;
    }
    if (
      sourceOfSelectedWsTableView &&
      sourceOfSelectedWsTableView.data &&
      sourceOfSelectedWsTableView.data.filters
    ) {
      const { filters } = sourceOfSelectedWsTableView.data;
      setFilters(filters || [], true);
    }
    const visibleColumnIds = sourceOfSelectedWsTableView.data.fields.map(
      (field) => field.uuid,
    );
    const hiddenColumnIds = columnIds.filter(
      (id) => !visibleColumnIds.includes(id),
    );
    setHiddenColumnIds(hiddenColumnIds || [], true);
    setSortBy(sourceOfSelectedWsTableView.data.sortBy || null, true);
    setSort(sourceOfSelectedWsTableView.data.sort || "DESC", true);
    setSelectedWsTableView({
      ...newView,
      data: {
        sort: sourceOfSelectedWsTableView.data.sort,
        sortBy: sourceOfSelectedWsTableView.data.sortBy,
        filters: sourceOfSelectedWsTableView.data.filters,
        hiddenColumns: hiddenColumnIds,
      },
    });
  };

  return (
    <DropdownPopper
      overlay={
        <DropdownMenu isOpen>
          {options.length ? (
            options.map((option) => (
              <DropdownMenuItem
                key={option.value}
                dark={option.value === localSelectedOption?.value}
                onClick={() => {
                  onChangeWsTableView(option);
                  dropdownRef.current.forceVisible(false);
                }}
              >
                {option.label}

                {option.value === localSelectedOption?.value && (
                  <SelectedIcon style={{ marginLeft: 8, marginRight: 0 }} />
                )}
              </DropdownMenuItem>
            ))
          ) : (
            <DropdownMenuItem>
              <IDHFormattedMessage
                id="ws_nothing_to_sort"
                defaultMessage="Nothing to sort"
              />
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      }
      ref={dropdownRef}
      placement={menuPlacement}
    >
      <Button size="small" textWithIcon variant="white">
        <EyeIcon />
        <IDHFormattedMessage id="ws_view" defaultMessage="View" />
        :&nbsp;
        {localSelectedOption?.label}
      </Button>
    </DropdownPopper>
  );
}
