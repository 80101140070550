import React, { ReactNode } from "react";
import classNames from "classnames";

interface Props {
  tabText: string | ReactNode | JSX.Element;
  count?: number | string;
  small?: boolean;
  medium?: boolean;
  big?: boolean;
  active: boolean;
  noClickable?: boolean;
  onClick?: () => void;
}

function Tab(props: Props) {
  const { tabText, count, small, medium, big, active, noClickable, onClick } =
    props;

  const getDataQa = () => {
    if (typeof tabText === "string") return tabText;
    if (React.isValidElement(tabText) && tabText?.props?.defaultMessage) {
      return tabText?.props?.defaultMessage;
    }
    return undefined;
  };

  return (
    <li
      className={classNames("tab", {
        "tab--active": active,
        // "tab--small": small,
        "tab--medium": medium, // right now we use only medium size in ws, small and big can be added later
        "tab--big": big,
        "tab--no-clickable": noClickable,
      })}
      onClick={onClick}
      data-qa={getDataQa()}
    >
      <button className="tab__button">
        <span className="tab__name">{tabText}</span>
        {(typeof count === "number" || typeof count === "string") && (
          <span className="tab__count">{count}</span>
        )}
      </button>
    </li>
  );
}

export default Tab;
