import React from "react";

import { ReactComponent as EditBlueIcon } from "src/images/edit-blue.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";

interface ExtensionListItemEditButtonProps {
  disabled?: boolean;
  onClick: () => void;
  extensionType: string;
}

export default function ExtensionListItemEditButton({
  disabled,
  onClick,
  extensionType,
}: ExtensionListItemEditButtonProps) {
  return (
    <button
      className="extensions__action-btn"
      onClick={onClick}
      disabled={disabled}
      data-qa={extensionType}
    >
      <EditBlueIcon />
      <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
    </button>
  );
}
