/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useRef, useState } from "react";

import _ from "lodash";
import { useParams } from "react-router";
import { uuidv7 } from "uuidv7";
import { useDispatch, useSelector } from "react-redux";
import {
  DragDropContext,
  Draggable,
  DragUpdate,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import classNames from "classnames";
import { usePopper } from "react-popper";

import { injectIntl, IntlShape, WrappedComponentProps } from "react-intl";
import { translateMessage } from "src/app/methods/translateMessage";
import { useScrollLock } from "src/app/methods/useScrollLock";
import { ReactComponent as TickIcon } from "src/images/tick-blue.svg";
import { DropdownMenuItem } from "../Dropdown/Dropdown";
import { ReactComponent as GridIcon } from "../../../images/grid.svg";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import EditOptionPane from "./components/EditOptionPane/EditOptionPane";
import { showToast } from "../../methods/showToast";
import { generateRankString } from "../../../utils/rankStrings";
import AnimatedDiv from "../AnimatedDiv/AnimatedDiv";
import useOnClickOutside from "../../methods/useOnClickOutside";
import {
  getProject,
  getProjectDetails,
  updateGlobalMetaFieldProjectWizardFields,
  updateProjectGlobalSelectField,
  updateProjectSingleSelectField,
  updateTaskGlobalSelectField,
  updateTaskSingleSelectField,
  updateTasksList,
} from "../../../redux";
import { tableDataType } from "../Table/Table";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import DropdownPortal from "../DropdownPortal";
import { getUnusedColor, shouldDisplayAction } from "./utils";
import NoResultsMessage from "../NoResultsMessage/NoResultsMessage";
import SelectLabel from "./components/SelectLabel/SelectLabel";
import { AccumulatedLabels } from "./components/AccumulatedLabels/AccumulatedLabels";
import { TooltipedEllipsis } from "../TooltipedEllipsis/TooltipedEllipsis";
import { MenuOverlay } from "./components/MenuOverlay/MenuOverlay";
import { Mode, Variant } from "./types";
import "./Select.scss";
import { getGlobalTaskDetails } from "src/redux/creator-database/creatorDatabaseActions";
import { useQuery } from "src/app/methods/useQuery";
import { isStringUuid } from "src/utils/methods";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { RootState } from "src/redux/reducers";
import { SelectOption } from "src/types";

interface SelectProps extends WrappedComponentProps<"intl"> {
  mode: Mode;
  optionsData: any;
  uuid: string;
  fieldValue: any;
  fieldName: string;
  updateValue?: any;
  emptyFieldPlaceholder?: React.ReactNode;
  toRight?: boolean;
  spaceLeft?: boolean;
  dataType?: tableDataType;
  intl: IntlShape;
  variant?: Variant;
  stackLabels?: boolean;
  readOnly?: boolean;
  qaContext?: string;
}

function Select({
  mode,
  optionsData,
  uuid,
  fieldValue,
  fieldName,
  updateValue,
  emptyFieldPlaceholder,
  spaceLeft,
  dataType,
  intl,
  variant = "default",
  stackLabels,
  readOnly,
  qaContext,
}: SelectProps) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [singleValue, setSingleValue] = useState<any>({});
  const [multiValue, setMultiValue] = useState<any>();
  const [optionToEdit, setOptionToEdit] = useState<
    SelectOption | undefined | null
  >(undefined);
  const [searchValue, setSearchValue] = useState("");
  const [showEditOptionPane, setShowEditOptionPane] = useState(false);
  const [placeholderProps, setPlaceholderProps] = useState<any>({});
  const [isOpen, setIsOpen] = useState<any>(false);
  const [showRemoveOptionModal, setShowRemoveOptionModal] = useState(false);

  const { lockScroll, unlockScroll } = useScrollLock();

  const dropdownRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const query = useQuery();
  const globalTaskId = query.get("displayGlobalTask") || "";

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const taskType = useSelector(
    (state: RootState) => state.projectReducer.taskType,
  );

  const params = useParams<{ projectId: string }>();

  const dispatch = useDispatch();

  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "left",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["right"],
        },
      },
    ],
  });

  const updateMultiValueIfNeeded = () => {
    if (mode !== "multi") return;
    const newValue = multiValue?.map((el: { value: string }) => el.value);
    const currentValue = fieldValue || [];
    if (!_.isEqual(currentValue?.sort(), newValue?.sort()) && updateValue) {
      updateValue(newValue);
    }
  };

  useOnClickOutside(dropdownRef, () => {
    unlockScroll();
    if (isOpen) {
      setIsOpen(false);
      setSearchValue("");
      setShowEditOptionPane(false);

      updateMultiValueIfNeeded();
    }
  });

  useEffect(() => {
    if (multiValue) {
      updateMultiValueIfNeeded();
    }
  }, [multiValue]);

  useEffect(() => {
    if (optionsData) {
      setOptions(optionsData);
    } else {
      setOptions([]);
    }
  }, [optionsData]);

  useEffect(() => {
    if (options.length) {
      if (mode === "single") {
        setSingleValue(
          options.find((item: { value: string }) => item.value === fieldValue),
        );
      } else {
        const newOptions =
          options.filter((item: { value: string }) =>
            fieldValue?.includes(item.value),
          ) || [];

        setMultiValue(newOptions);
      }
    }
  }, [fieldValue, options]);

  const onUpdateSingleSelect = async (
    newName: string,
    newOptions: SelectOption[],
  ) => {
    switch (dataType) {
      case tableDataType.GlobalTask:
        await dispatch(
          updateTaskGlobalSelectField(uuid, activeWorkspaceUuid, newOptions),
        );
        if (isStringUuid(globalTaskId)) {
          dispatch(getGlobalTaskDetails(globalTaskId));
        }
        break;
      case tableDataType.Task:
        await dispatch(updateTaskSingleSelectField(uuid, newName, newOptions));
        if (!_.isEqual(options.sort(), newOptions.sort())) {
          dispatch(updateTasksList(params.projectId, taskType));
        }

        break;
      case tableDataType.GlobalProject:
        await dispatch(
          updateProjectGlobalSelectField(uuid, activeWorkspaceUuid, newOptions),
        );
        break;
      case tableDataType.Project:
        await dispatch(
          updateProjectSingleSelectField(uuid, newName, newOptions),
        );
        dispatch(getProject(params.projectId, true));
        break;
      case tableDataType.ProjectDetails:
        await dispatch(
          updateProjectSingleSelectField(uuid, newName, newOptions),
        );
        dispatch(getProjectDetails(params.projectId));
        break;
      case tableDataType.GlobalMetaFieldProjectWizardFields:
        dispatch(updateGlobalMetaFieldProjectWizardFields(uuid, newOptions));
        break;
      default:
        console.error("Unknown data type");
        break;
    }
  };

  const createSelectOption = async (e: any) => {
    e?.stopPropagation();
    setShowEditOptionPane(false);
    try {
      const newRank = options?.length ? options[options.length - 1].rank : "a";

      const newOptions = [
        ...options,
        {
          name: searchValue,
          value: uuidv7(),
          color: getUnusedColor(options).color.replace("#", ""),
          backgroundColor: getUnusedColor(options).backgroundColor.replace(
            "#",
            "",
          ),
          rank: generateRankString(newRank, ""),
        },
      ];

      setSearchValue("");
      await updateMultiValueIfNeeded();
      await onUpdateSingleSelect(fieldName, newOptions);

      setOptions(newOptions);
    } catch (error) {
      showToast(
        "error",
        "Error",
        translateMessage({
          intl,
          id: "ws_could_not_create_option",
          defaultMessage: "Could not create a new option",
        }),
      );
    }
  };

  const handleDragUpdate = (update: DragUpdate) => {
    if (!update.destination) {
      return;
    }

    const queryAttr = "data-rbd-drag-handle-draggable-id";

    const { draggableId } = update;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as {
      clientHeight: number;
      clientWidth: number;
      parentNode: any;
    };

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft,
      ),
    });
  };

  const handleDragEnd = async (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const sourceField: any = options[source.index];
    const targetField: any = options[destination.index];
    const fieldIndex = options.findIndex(
      (field: SelectOption) => field.value === targetField.value,
    );

    let newRank = "";
    if (destination.index < source.index) {
      newRank = generateRankString(
        options[fieldIndex - 1]?.rank || "",
        options[fieldIndex].rank,
      );
      // move bottom
    } else {
      newRank = generateRankString(
        options[fieldIndex].rank,
        options[fieldIndex + 1]?.rank || "",
      );
    }

    const newOptions = options.map((option: SelectOption) => {
      if (sourceField.value === option.value) {
        return {
          ...option,
          rank: newRank,
        };
      }

      return option;
    });

    setPlaceholderProps({});

    setOptions(newOptions);

    await onUpdateSingleSelect(fieldName, newOptions);
  };

  const changeEditData = (e: any, item: any) => {
    e.stopPropagation();
    setOptionToEdit(item);
    setShowEditOptionPane(true);
  };

  const handleLabelClick = () => {
    lockScroll();
    setIsOpen(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  useEffect(() => {
    if (optionToEdit) {
      const foundOption = options.find(
        (item: SelectOption) => item.value === optionToEdit.value,
      );
      setOptionToEdit(foundOption);
    }
  }, [options]);

  useEffect(() => {
    if (searchValue.length) {
      const searchResults = options.filter((item: { name: string }) =>
        item.name.toUpperCase()?.includes(searchValue.toUpperCase()),
      );
      setFilteredOptions(searchResults);
    } else {
      setFilteredOptions(options);
    }
  }, [searchValue, options]);

  const getWidth = () => {
    if (!stackLabels) return "auto";
    if (multiValue?.length >= 2) return 200;
    return "auto";
  };

  const rejectMulti = (item: { value: string }) => {
    const newValue = multiValue?.filter((el: any) => el.value !== item.value);
    setMultiValue(newValue);
  };

  const selectMulti = (item: { value: string }) => {
    const newValue = [...multiValue, item];
    setMultiValue(newValue);
  };

  const setNewValue = (item: any) => {
    if (mode === "multi") {
      if (multiValue?.find((el: any) => el.value === item.value)) {
        rejectMulti(item);
      } else {
        selectMulti(item);
      }
    } else {
      setSingleValue(item);
      updateValue(item.value);
    }
  };

  const selectNewValue = (item: any) => {
    setNewValue(item);
    setShowEditOptionPane(false);

    if (mode === "multi") return;
    unlockScroll();
    setIsOpen(false);
    setSearchValue("");
  };

  const handleOptionClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: SelectOption,
  ) => {
    if (mode === "edit-global") {
      e.stopPropagation();
      setOptionToEdit(item);
      setShowEditOptionPane(true);
    } else {
      selectNewValue(item);
    }
  };

  const removeSelectOption = async () => {
    try {
      const newOptions = options.filter(
        (item: any) => item.value !== optionToEdit?.value,
      );

      await onUpdateSingleSelect(fieldName, newOptions);

      setOptions(newOptions);
      setShowEditOptionPane(false);
      setIsOpen(false);
      setOptionToEdit(undefined);
    } catch (error) {
      showToast("error", "Error", "Could not rename option.");
    }
  };

  const renderValue = () => {
    if (multiValue?.length > 1) {
      return (
        <AccumulatedLabels
          multiValue={multiValue}
          setIsOpen={setIsOpen}
          emptyFieldPlaceholder={emptyFieldPlaceholder}
          handleLabelClick={handleLabelClick}
          stackLabels={stackLabels}
          readOnly={readOnly}
        />
      );
    }
    if (multiValue?.length === 1) {
      return (
        <SelectLabel
          value={multiValue[0]}
          onClickHandler={handleLabelClick}
          emptyFieldPlaceholder={emptyFieldPlaceholder}
          readOnly={readOnly}
        />
      );
    }
    if (mode === "multi") {
      return (
        <SelectLabel
          value={{}}
          onClickHandler={handleLabelClick}
          emptyFieldPlaceholder={emptyFieldPlaceholder}
          readOnly={readOnly}
        />
      );
    }
    return (
      <SelectLabel
        value={singleValue}
        onClickHandler={handleLabelClick}
        emptyFieldPlaceholder={emptyFieldPlaceholder}
        readOnly={readOnly}
      />
    );
  };

  const SelectedLabels = useCallback(() => {
    return multiValue?.length > 1 ? (
      <div className="select__selected-labels">
        {multiValue?.map((item: any) => (
          <SelectLabel
            key={uuidv7()}
            value={item}
            onClickHandler={handleLabelClick}
            emptyFieldPlaceholder={emptyFieldPlaceholder}
            rejectMulti={rejectMulti}
          />
        ))}
      </div>
    ) : null;
  }, [multiValue]);

  const renderMenuContent = () => (
    <>
      <SelectedLabels />
      <input
        ref={inputRef}
        className="search-input"
        value={searchValue}
        onClick={(e) => {
          e.stopPropagation();
          setShowEditOptionPane(false);
        }}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            createSelectOption(e);
          }
        }}
        placeholder={
          shouldDisplayAction(variant, "add_new_option")
            ? translateMessage({
                intl,
                id: "ws_create_an_option",
                defaultMessage: "Create an option",
              })
            : translateMessage({
                intl,
                id: "ws_search_for_options",
                defaultMessage: "Search for options",
              })
        }
      />

      <div
        className="drag-n-drop-wrapper"
        onClick={(e) => {
          e.stopPropagation();
          setShowEditOptionPane(false);
        }}
      >
        <DragDropContext
          onDragUpdate={handleDragUpdate}
          onDragEnd={(result) => handleDragEnd(result)}
        >
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classNames("options-list", {
                  "options-list--dragging-over": snapshot.isDraggingOver,
                })}
                {...provided.droppableProps}
              >
                {filteredOptions
                  ?.sort((a: any, b: any) => {
                    return a.rank.localeCompare(b.rank);
                  })
                  ?.map((item: SelectOption, index: number) => {
                    const showTick = multiValue?.find(
                      (el: SelectOption) => el.value === item.value,
                    );
                    return (
                      <Draggable
                        key={item.value}
                        draggableId={item.value}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className={classNames("option", {
                              "option--dragging-over": snapshot.isDragging,
                            })}
                            {...provided.draggableProps}
                            style={
                              mode === "edit-global"
                                ? {
                                    ...provided.draggableProps.style,
                                    top: index * 40 + 129,
                                  }
                                : provided.draggableProps.style
                            }
                          >
                            <DropdownMenuItem
                              key={item.value}
                              toggleGlobalState
                              data-value={item.name}
                              onClick={(e) => handleOptionClick(e, item)}
                            >
                              <div className="dropdown__menu-item-left">
                                <div
                                  className={classNames(
                                    "dropdown__menu-item-left-drag",
                                    {
                                      "dropdown__menu-item-left-drag--disabled":
                                        searchValue.length,
                                      "dropdown__menu-item-left-drag--hidden":
                                        !shouldDisplayAction(
                                          variant,
                                          "drag_and_drop",
                                        ),
                                    },
                                  )}
                                  {...provided.dragHandleProps}
                                >
                                  <GridIcon />
                                </div>
                                <span
                                  className="select__label"
                                  style={{
                                    backgroundColor: `#${item.backgroundColor}`,
                                    color: `#${item.color}`,
                                    transition: `color 0.2s ease, background-color 0.2s ease`,
                                  }}
                                >
                                  <TooltipedEllipsis
                                    content={item.name}
                                    maxWidth={210}
                                  />
                                </span>
                                {showTick && <TickIcon />}
                              </div>
                              {shouldDisplayAction(variant, "edit") && (
                                <div
                                  className="dropdown__menu-item-right"
                                  onClick={(e) => changeEditData(e, item)}
                                >
                                  <EditIcon />
                                </div>
                              )}
                            </DropdownMenuItem>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
                {placeholderProps && snapshot.isDraggingOver && (
                  <AnimatedDiv
                    className="option__placeholder"
                    style={{
                      top: placeholderProps?.clientY,
                      left: placeholderProps?.clientX,
                      height: placeholderProps?.clientHeight,
                      width: placeholderProps?.clientWidth,
                    }}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {searchValue && shouldDisplayAction(variant, "add_new_option") && (
        <DropdownMenuItem onClick={createSelectOption}>
          <div className="dropdown__menu-item-left">
            <span className="dropdown__menu-item-left-create">
              <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
            </span>
            <span
              className="select__label select__label--to-create"
              style={{
                backgroundColor: getUnusedColor(options).backgroundColor,
                color: getUnusedColor(options).color,
              }}
            >
              {searchValue}
            </span>
          </div>
          <div className="dropdown__menu-item-right" />
        </DropdownMenuItem>
      )}

      {!shouldDisplayAction(variant, "add_new_option") &&
        searchValue &&
        !filteredOptions.length && (
          <DropdownMenuItem className="dropdown__menu-item-no-results">
            <NoResultsMessage />
          </DropdownMenuItem>
        )}
      {showEditOptionPane && (
        <div
          ref={setPopperElement}
          style={styles.popper as React.CSSProperties}
          {...attributes.popper}
        >
          <EditOptionPane
            mode={mode}
            data={optionToEdit}
            setData={setOptionToEdit}
            options={options}
            isOpen={showEditOptionPane}
            setIsOpen={setShowEditOptionPane}
            setOptions={setOptions}
            fieldName={fieldName}
            updateSingleSelect={onUpdateSingleSelect}
            setShowRemoveOptionModal={setShowRemoveOptionModal}
          />
        </div>
      )}
    </>
  );

  if (mode === "edit-global")
    return (
      <div
        className="select__menu select__menu--global-fields"
        onClick={() => setShowEditOptionPane(false)}
      >
        {renderMenuContent()}

        {showRemoveOptionModal && (
          <RemoveModal
            onClose={() => setShowRemoveOptionModal(false)}
            objectNames={[optionToEdit?.name || ""]}
            removeFunction={removeSelectOption}
          />
        )}
      </div>
    );

  return (
    <div ref={setReferenceElement}>
      <div
        ref={dropdownRef}
        id={uuid}
        className="select__wrapper"
        style={{ width: getWidth() }}
      >
        <DropdownPortal
          className="select__dropdown"
          overlay={
            <MenuOverlay
              showEditOptionPane={showEditOptionPane}
              handleClick={() => setShowEditOptionPane(false)}
              spaceLeft={spaceLeft}
              qaAttribute={fieldName}
              qaContext={qaContext}
            >
              {renderMenuContent()}
            </MenuOverlay>
          }
          visible={isOpen}
        >
          {renderValue()}
        </DropdownPortal>
      </div>
    </div>
  );
}

export default injectIntl(Select);
