import React, { useRef, useState, useEffect } from "react";
import { injectIntl } from "react-intl";

import { ReactComponent as TickIcon } from "src/images/tick.svg";
import { DropdownPopper } from "src/app/components/DropdownPopper/DropdownPopper";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { translateMessage } from "src/app/methods/translateMessage";
import SortFieldsButton from "src/app/dropdowns/SortFieldsDropdown/SortFieldsButton";
import { setDictionarySortBy } from "../DictionaryFilters/redux/dictionaryFiltersActions";
import CustomInput from "../CustomInput/CustomInput";

import "./DictionarySortDropdown.scss";

export const searchObjectsByName = (searchValue, objectsArray) => {
  const regex = new RegExp(searchValue, "i");
  return objectsArray.filter((obj) => regex.test(obj.name));
};

function DictionarySortDropdown({
  fields = [],
  dictionaryFilters,
  dispatchDictionaryFilters,
  intl,
}) {
  const { sortBy } = dictionaryFilters;
  const dropdownRef = useRef();
  const [value, setValue] = useState("");
  const [displayOptions, setDisplayOptions] = useState([]);

  const foundActiveSortingColumn = displayOptions.find(
    (option) => option.uuid === sortBy,
  );

  useEffect(() => {
    if (!value) {
      setDisplayOptions(fields);
    } else {
      const searchResults = searchObjectsByName(value, fields);
      setDisplayOptions(searchResults);
    }
  }, [value, fields]);

  return (
    <DropdownPopper
      overlay={
        <DropdownMenu isOpen className="sort-dictionary-dropdown__menu">
          <CustomInput
            type="text"
            placeholder={translateMessage({
              intl,
              id: "ws_enter_field_name",
              defaultMessage: "Enter field name",
            })}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="dropdown__menu-list">
            {displayOptions.map((f) => {
              const active = sortBy === f.uuid;
              return (
                <DropdownMenuItem
                  key={f.uuid}
                  onClick={() => {
                    dispatchDictionaryFilters(
                      setDictionarySortBy(active ? null : f.uuid),
                    );
                    dropdownRef.current.forceVisible(false);
                  }}
                  data-qa={f.name}
                >
                  {f.name}
                  {active && <TickIcon />}
                </DropdownMenuItem>
              );
            })}
          </div>
        </DropdownMenu>
      }
      ref={dropdownRef}
      placement="bottom-end"
    >
      <SortFieldsButton
        active={typeof sortBy === "string" && sortBy !== ""}
        activeSortingColumnName={foundActiveSortingColumn?.name}
      />
    </DropdownPopper>
  );
}

export default injectIntl(DictionarySortDropdown);
