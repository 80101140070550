import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./TaskBox.scss";

import { Draggable } from "react-beautiful-dnd";
import classNames from "classnames";

import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { convertTimezone } from "../../methods/convertTimezone";
import { TaskBoxBottom } from "./components/TaskBoxBottom";
import TaskBoxName from "./components/TaskBoxName";
import { TaskBoxContentImage } from "./components/TaskBoxContentImage";
import { Metadata } from "../../Task/Subtask/Subtask";
import { PublicationBox } from "../../ReportGenerator/components/ReportTemplate/components/PublicationBox/PublicationBox";

interface Props {
  data: any;
  index: number;
  boardColumns: Metadata[];
  disableImageLoad?: boolean;
  setDisableImageLoad?: Dispatch<SetStateAction<boolean>>;
}

const TaskBox: React.FC<Props> = (props) => {
  const { data, index, boardColumns, disableImageLoad, setDisableImageLoad } =
    props;

  const [dueDate, setDueDate] = useState<any>("");

  const history = useHistory();
  const params = useParams<{ projectId: string; taskId: string }>();

  const {
    mainReducer: { activeWorkspaceUuid },
    taskReducer: { projectTasksAutocomplete },
  } = useSelector((state: RootState) => state);

  const tasksAutocompleteList =
    projectTasksAutocomplete[params.projectId] ?? [];

  const taskId = data.projectId;

  useEffect(() => {
    const taskDueDate = data.metadata.find(
      (item: any) => item.name === "dueDate",
    );

    if (taskDueDate?.value?.date) {
      setDueDate(convertTimezone(taskDueDate.value.date));
    }
  }, [data]);

  const showTaskDetails = () => {
    history.push(
      `/workspace/${activeWorkspaceUuid}/projects/${params.projectId}/${taskId}`,
    );
  };

  return (
    <Draggable
      key={data.projectId}
      draggableId={`${data.projectId}/${data.rank}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className={classNames("task-box", {
            "task-box--content": data.taskType === TaskType.Content,
            "task-box--publication": data.taskType === TaskType.Publication,
            "task-box--dragging": snapshot.isDragging,
          })}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {data.taskType === TaskType.Publication ? (
            <PublicationBox
              data={data}
              creatorsList={tasksAutocompleteList.filter(
                (task) =>
                  task.wsProjectUuid === data.projectId &&
                  task.taskType === TaskType.Creator,
              )}
              board
              onClick={showTaskDetails}
            />
          ) : (
            <>
              <TaskBoxContentImage
                src={data?.cover?.publicUrl}
                showContent={data.taskType === TaskType.Content}
                actionTaskType={data.taskType}
                showTaskDetails={showTaskDetails}
                disableImageLoad={disableImageLoad}
                setDisableImageLoad={setDisableImageLoad}
              />
              <div
                className={classNames("task-box__main", {
                  // "task-box__main--completed": isComplete,
                  "task-box__main--creator": data.taskType === TaskType.Creator,
                  "task-box__main--content": data.taskType === TaskType.Content,
                  "task-box__main--action":
                    data.taskType === TaskType.Action ||
                    data.taskType === TaskType.Payment,
                  "task-box__main--publication":
                    data.taskType === TaskType.Publication,
                })}
              >
                <TaskBoxName
                  data={data}
                  boardColumns={boardColumns}
                  showTaskDetails={showTaskDetails}
                />
                <TaskBoxBottom data={data} dueDate={dueDate} />
              </div>
            </>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default TaskBox;
