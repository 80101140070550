import {
  SET_DICTIONARY_SORT,
  SET_DICTIONARY_SORT_BY,
  SET_DICTIONARY_FILTERS,
  SET_DICTIONARY_FILTER,
  RESET_DICTIONARY_FILTERS_REDUCER,
  TOGGLE_DICTIONARY_COLUMN_VISIBILITY,
  SET_DICTIONARY_INITIAL_COLUMN_RANKS,
  SET_DICTIONARY_COLUMN_RANK,
  RESET_HIDDEN_COLUMNS,
  SET_SELECTED_TABLE_VIEW,
  SET_HIDDEN_DICTIONARY_COLUMN_IDS,
} from "./dictionaryFiltersTypes";

export function setDictionarySortBy(sortBy, skipLSUpdate) {
  return {
    type: SET_DICTIONARY_SORT_BY,
    payload: { sortBy, skipLSUpdate },
  };
}

export function setDictionarySort(sort, skipLSUpdate) {
  return {
    type: SET_DICTIONARY_SORT,
    payload: { sort, skipLSUpdate },
  };
}

export function setDictionaryFilters(filters, skipLSUpdate) {
  return {
    type: SET_DICTIONARY_FILTERS,
    payload: { filters, skipLSUpdate },
  };
}

export function setDictionaryFilter(fieldUuid, value) {
  return {
    type: SET_DICTIONARY_FILTER,
    payload: {
      fieldUuid,
      value,
    },
  };
}

export function resetDictionaryFiltersReducer() {
  return {
    type: RESET_DICTIONARY_FILTERS_REDUCER,
    payload: {},
  };
}

export function toggleDictionaryColumnVisibility(uuid) {
  return {
    type: TOGGLE_DICTIONARY_COLUMN_VISIBILITY,
    payload: { uuid },
  };
}

export function setHiddenDictionaryColumnIds(ids, skipLSUpdate) {
  return {
    type: SET_HIDDEN_DICTIONARY_COLUMN_IDS,
    payload: { ids, skipLSUpdate },
  };
}

export function setDictionaryColumnRank(uuid, rank) {
  return {
    type: SET_DICTIONARY_COLUMN_RANK,
    payload: { uuid, rank },
  };
}

export function setDictionaryInitialColumnRanks(ranks) {
  return {
    type: SET_DICTIONARY_INITIAL_COLUMN_RANKS,
    payload: { ranks },
  };
}

export function resetHiddenColumns() {
  return {
    type: RESET_HIDDEN_COLUMNS,
    payload: {},
  };
}

export function setSelectedTableView(selectedTableView) {
  return {
    type: SET_SELECTED_TABLE_VIEW,
    payload: { selectedTableView },
  };
}
