import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { getSettingsGlobalFields } from "src/redux";
import { tableDataType } from "src/app/components/Table/Table";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import { showErrorToasts } from "../../../../../../../utils/methods";

interface Props {
  onClose: () => void;
  metaFieldUuid: string;
  metaFieldName: string;
  dataType: tableDataType;
}

const DeleteGlobalFieldModal: React.FC<Props> = (props) => {
  const { onClose, metaFieldUuid, metaFieldName, dataType } = props;

  const [loading, setLoading] = useState(false);

  const {
    mainReducer: { activeWorkspaceUuid: wsWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const confirmDeleting = () => {
    setLoading(true);

    const url =
      dataType === tableDataType.GlobalTask
        ? API_URLS.deleteTaskGlobalField
        : API_URLS.deleteProjectGlobalField;

    axios
      .post(url, {
        wsWorkspaceUuid,
        metaFieldUuid,
      })
      .then(() => {
        dispatch(getSettingsGlobalFields(wsWorkspaceUuid));
      })
      .catch((error) => {
        showErrorToasts(error.response.data.errors);
      })
      .finally(() => {
        onClose();
        setLoading(false);
      });
  };

  return (
    <RemoveModal
      onClose={onClose}
      objectNames={[metaFieldName ?? ""]}
      removeFunction={confirmDeleting}
      loading={loading}
    />
  );
};

export { DeleteGlobalFieldModal };
