import * as actionTypes from "./workspaceTaskFiltersTypes";

export interface SetWorkspaceTaskFiltersParams {
  uuid: string;
  value: any;
}

export function setWorkspaceTaskFilters({
  uuid,
  value,
}: SetWorkspaceTaskFiltersParams) {
  return {
    type: actionTypes.SET_WORKSPACE_TASK_FILTERS,
    payload: { uuid, value },
  };
}

export function clearWorkspaceTaskFilters() {
  return {
    type: actionTypes.CLEAR_WORKSPACE_TASK_FILTERS,
  };
}
