import React, { useEffect, useState } from "react";

import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";

export function SingleCheckboxFilter({
  col,
  sendFilter,
  setWaitEvent,
  filters,
}) {
  const [value, setValue] = useState(filters[col.uuid]?.value);

  useEffect(() => {
    value !== undefined &&
      value !== filters[col.uuid]?.value &&
      setWaitEvent({
        target: {
          value,
        },
      });
  }, [value]);

  return (
    <>
      <CustomCheckbox
        label="Checked"
        id={`filters-${col.uuid}-true`}
        checked={value === true}
        onChange={(e) => setValue(e.target.checked ? true : null)}
        data-qa={`${col.name}-checked`}
      />
      <CustomCheckbox
        label="Not checked"
        id={`filters-${col.uuid}-false`}
        checked={value === false}
        onChange={(e) => setValue(e.target.checked ? false : null)}
        data-qa={`${col.name}-unchecked`}
      />
    </>
  );
}
