import React from "react";
import { useSelector } from "react-redux";

import { Dropdown, DropdownMenu } from "../../components/Dropdown/Dropdown";
import { FieldSelect } from "../../components/FieldSelect/FieldSelect";
import SortFieldsButton from "./SortFieldsButton";

import "./SortFieldsDropdown.scss";

function SortFieldsDropdown({ fields, dropdownButton = undefined }) {
  const {
    taskFiltersReducer: { activeSortingColumn },
    projectReducer: { taskType },
  } = useSelector((state) => state);

  const selectionField = {
    id: taskType,
    name: taskType,
    title: taskType,
    isVisible: true,
    key: taskType,
  };

  const foundActiveSortingColumn = [selectionField, ...fields].find(
    (field) => field.id === activeSortingColumn,
  );

  return (
    <Dropdown className="sort-fields-dropdown" toRight>
      {(isOpen, setIsOpen) => (
        <>
          <span
            className="sort-fields-dropdown__button"
            onClick={() => {
              setIsOpen(true);
            }}
            data-qa="sort-button"
          >
            {dropdownButton || (
              <SortFieldsButton
                active={
                  typeof activeSortingColumn === "string" &&
                  activeSortingColumn !== ""
                }
                activeSortingColumnName={foundActiveSortingColumn?.title}
              />
            )}
          </span>
          <DropdownMenu isOpen={isOpen} onClick={() => setIsOpen(false)}>
            <FieldSelect field={selectionField} capitalize />
            {fields &&
              fields.map(
                (field) =>
                  field.isVisible && (
                    <FieldSelect key={field.id} field={field} />
                  ),
              )}
          </DropdownMenu>
        </>
      )}
    </Dropdown>
  );
}

export default SortFieldsDropdown;
