import React, { useContext, useEffect, useState } from "react";
import "./SingleFilter.scss";
import { useIntl } from "react-intl";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { translateMessage } from "src/app/methods/translateMessage";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { SingleSelectFilterOptions } from "../SingleSelectFilterOptions/SingleSelectFilterOptions";
import { SingleDatePickerRange } from "../SingleDatePickerRange/SingleDatePickerRange";
import { MemberSelect } from "../MemberSelect/MemberSelect";
import { SingleCheckboxFilter } from "../SingleCheckboxFilter/SingleCheckboxFilter";
import { SingleRangeBox } from "../SingleRangeBox/SingleRangeBox";
import { SinglePercentFilter } from "../SinglePercentFilter/SinglePercentFilter";
import { DictionaryElementFilterSelect } from "../DictionaryElementFilterSelect/DictionaryElementFilterSelect";
import { DictionaryFiltersContext } from "../../DictionaryFilters";
import SingleRatingFilter from "../SingleRatingFilter/SingleRatingFilter";

const SUPPORTED_METAFIELD_TYPES = [
  MetaFieldType.Text,
  MetaFieldType.BoolVal,
  MetaFieldType.Percent,
  MetaFieldType.Number,
  MetaFieldType.Currency,
  MetaFieldType.Date,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.Member,
  MetaFieldType.DictionaryElement,
  MetaFieldType.Rating,
];

function SingleFilter({ col, sendFilter, defaultValue }) {
  const intl = useIntl();

  const [waitEvent, setWaitEvent] = useState(null);

  const [value, setValue] = useState(defaultValue?.from || defaultValue);

  const {
    dictionaryFilters: { filters },
  } = useContext(DictionaryFiltersContext);

  useEffect(() => {
    let timer = 0;
    if (waitEvent && sendFilter) {
      timer = setTimeout(() => {
        sendFilter(col.uuid, waitEvent.target.value);
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [waitEvent]);

  const onInputChange = (event) => {
    setValue(event.target.value);
    event.persist();
    setWaitEvent(event);
  };

  const renderInput = () => {
    switch (col.type) {
      case MetaFieldType.Text:
        return (
          <CustomInput
            value={value}
            onChange={onInputChange}
            type="text"
            placeholder={translateMessage({
              intl,
              id: "ws_enter_value",
              defaultMessage: "Enter value",
            })}
            data-qa={col.name}
          />
        );
      case MetaFieldType.BoolVal:
        return (
          <SingleCheckboxFilter
            col={col}
            sendFilter={sendFilter}
            setWaitEvent={setWaitEvent}
          />
        );
      case MetaFieldType.Percent:
        return <SinglePercentFilter col={col} setWaitEvent={setWaitEvent} />;
      case MetaFieldType.Number:
      case MetaFieldType.Currency:
        return (
          <SingleRangeBox
            col={col}
            sendFilter={sendFilter}
            setWaitEvent={setWaitEvent}
          />
        );
      case MetaFieldType.Date:
        return <SingleDatePickerRange col={col} sendFilter={sendFilter} />;
      case MetaFieldType.MultiSelect:
      case MetaFieldType.SingleSelect:
        return (
          <SingleSelectFilterOptions
            col={col}
            sendFilter={sendFilter}
            displayNotSetOption={col.type === MetaFieldType.SingleSelect}
          />
        );

      case MetaFieldType.Member:
        return <MemberSelect col={col} sendFilter={sendFilter} />;

      case MetaFieldType.DictionaryElement:
        return (
          <DictionaryElementFilterSelect
            col={col}
            sendFilter={sendFilter}
            filters={filters}
          />
        );
      case MetaFieldType.Rating:
        return <SingleRatingFilter col={col} sendFilter={sendFilter} />;

      case MetaFieldType.Project:
        // TODO: support in glide table https://app.asana.com/0/1159791777517936/1208972781734357/f
        return null;

      default:
        return <div>{col.type}</div>;
    }
  };

  return SUPPORTED_METAFIELD_TYPES.includes(col.type) ? (
    <div className="single-filter">
      <CustomInputLabel>{col.name}</CustomInputLabel>
      {renderInput()}
    </div>
  ) : null;
}

export default SingleFilter;
